﻿angular.module('indexModule')
    .controller("IndexRapportageController",
        ['$mdDialog', '$scope', 'indextree', 'progressCircle',
function ($mdDialog, $scope, indextree, progressCircle) {
    const vm = this;
    let selectedBerekeningen = $scope.selectedBerekeningen;
    vm.isCalcNeeded = indextree.isCalcNeeded;
    $scope.pdfOptions = {
        includeVerklaringenSelectedProducts: true,
        includeOpmerkingen: true,
        includeBouwkundigeBibliotheek: true,
        onlyEnergieprestatie: false,
        onlyOverzicht: false,
        reportOfBuilding: true,
        reportPerUnit: false
    };
    let pdfOptions = $scope.pdfOptions;
    $scope.showReportOptions = false;
    $scope.rapportPerTypeName = "";

    var init = function () {
        var shouldShow = shouldShowReportOptions();
        $scope.showReportOptions = shouldShow;
        if (shouldShow) {
            $scope.rapportPerTypeName = getRapportPerTypeName();
        }
    }

    vm.createPdfRapportage = async function () {
        vm.closeDialog();
        if (!selectedBerekeningen || selectedBerekeningen.length == 0) {
            return;
        }
        if (selectedBerekeningen.some(bld => vm.isCalcNeeded(bld))) {
            indextree.warning('[W050]'); // de berekening heeft geen resultaten
        } else {
            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createRapportBerekening(selectedBerekeningen.map(bld => bld.BuildingId), $scope.pdfOptions);
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    }

    vm.clickDownloadOverzicht = function () {        
        clearInclusionOptions();
        if (pdfOptions.onlyOverzicht) {
            pdfOptions.onlyEnergieprestatie = false;
        }
    }

    vm.clickDownloadEnergieprestatie = function () {
        clearInclusionOptions();
        if (pdfOptions.onlyEnergieprestatie) {
            pdfOptions.onlyOverzicht = false;
        }
    }

    vm.clickIncludeOption = function () {
        pdfOptions.onlyEnergieprestatie = false;
        pdfOptions.onlyOverzicht = false;
    }

    vm.clickReportOfProject = function () {
        if (pdfOptions.reportOfBuilding) {
            pdfOptions.reportPerUnit = false;
        }
    }

    vm.clickReportPerUnit = function () {
        if (pdfOptions.reportPerUnit) {
            pdfOptions.reportOfBuilding = false;
        }
    }

    vm.showReportOptions = function () {
        return reportForUnitShouldBeHidden();
        //return this.showReportOptions;
    }

    vm.closeDialog = function () {
        $mdDialog.cancel();
    };

    function shouldShowReportOptions() {
        return reportForUnitShouldBeHidden();
        //return selectedBerekeningen.some(x => isApartmentUnit(x) || isUtilityUnit(x));
    }

    function reportForUnitShouldBeHidden() {
        return false;//I.v.m. bugs in de rapportage per unit is het voor release 3.3.2.2. nog niet gewenst om deze optie in de client te tonen.
    }

    function getRapportPerTypeName() {
        const typeNames = selectedBerekeningen          // alle geselecteerde berekeningen
            .map(building => getBuildingUnitType(building)) // ...daarvan het type gebouweenheid
            .filter(typeName => typeName);              // ...dat niet leeg is
        return [...new Set(typeNames)] // distinct         ...zonder duplicaten
            .sort()                                     //  ...alfabetisch gesorteerd
            .join(' / ');                               // ...samengevoegd met ' / ' ertussen
    }

    function getBuildingUnitType(building) {
        if (building.Results.RZFORM_CALCUNIT == "RZUNIT_PROJECT") {
            return "projectwoning";
        }

        if (isApartmentUnit(building)) {
            return "appartement"
        }

        if (isUtilityUnit(building)) {
            return "unit"
        }
    }

    function isUtilityUnit(building) {
        return (building.Results.GEB_TYPEGEB == "TGEB_UTILIT" && building.Results.RZFORM_CALCUNIT == "RZUNIT_GEBUNIT");
    }

    function isApartmentUnit(building) {
        return (building.Results.GEB_TYPEGEB == "TGEB_APPGEB" && building.Results.RZFORM_CALCUNIT == "RZUNIT_GEBAPP")
    }

    function clearInclusionOptions() {
        pdfOptions.includeVerklaringenSelectedProducts = false;
        pdfOptions.includeOpmerkingen = false;
        pdfOptions.includeBouwkundigeBibliotheek = false;
    }

    init();
}]);