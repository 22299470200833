﻿var indexModule = angular.module("indexModule", ["ngRoute", "ntaComponents", "progressModule", "ngAnimate", "ngMaterial", "ntaLogger", "ntaAppInfoModule"])
    .config(
        ['$routeProvider', '$locationProvider', '$mdThemingProvider', 'ntaAppInfoProvider',
function ($routeProvider,   $locationProvider,   $mdThemingProvider,   ntaAppInfoProvider) {
    'use strict';

    $routeProvider.when('/Folders/:id/', { redirectTo: params => `/Folders/${params.id}/Berekeningen` });
    $routeProvider.when('/Folders/:id/Berekeningen/', { templateUrl: '/src/app/project/berekeningen.html?v=' + encodeURIComponent(ntaAppInfoProvider.commit) });
    $routeProvider.when('/Zoeken/', { templateUrl: '/src/app/components/searchcomponent.html' });

    $locationProvider.html5Mode(true);

    $mdThemingProvider.theme('default')
        .primaryPalette('light-green')
        .accentPalette('orange');
}]);

indexModule
    .factory('indextree',
        ['ntaTree', '$http', '$q', '$filter', '$mdDialog', 'progressCircle', '$log', 'activeBuildingService', 'ntaAlert', 'HubConnection',
function (ntaTree,   $http,   $q,   $filter,   $mdDialog,   progressCircle,   $log,   activeBuildingService,   ntaAlert,   HubConnection) {
    'use strict';

    const rootFolder = {
        FolderId: -1,
        ParentId: null,
        Name: ' Hoofdmap',
        Subfolders: [],
        ProjectId: -1,
        Project: {
            ProjectId: -1,
            Berekeningen: [],
        }
    };

    const factory = {};
    factory.folders = [rootFolder].concat($filter('orderBy')(ntaTree.folders, "Name"));
    factory.foldersById = new Map();

    indexFolders();

    for (const folder of factory.folders) {
        folder.Project.Berekeningen = $filter('orderBy')(folder.Project.Berekeningen, "Name");
    };

    activeBuildingService.indextree = factory;

    factory.activeFolderId = ntaTree.activefolder;
    factory.activeBuilding = ntaTree.activebuilding;
    factory.isdev = ntaTree.isdev;
    factory.commit = ntaTree.commit;
    factory.licencetype = ntaTree.licencetype;
    factory.modules = ntaTree.modules;
    factory.warnings = ntaTree.warnings;
    factory.buildingTypes = ntaTree.buildingTypes;
    factory.isPrullenbakOpen = !!factory.foldersById.get(ntaTree.activefolder).DeleteDate;// Houdt bij of de prullenbak geopend is zodat controllers die de indextree gebruiken verschillend kunnen omgaan met het openen en sluiten van de prullenbak.

    // mappenhiërarchie indexeren
    function indexFolders() {
        factory.foldersById = new Map(factory.folders.map(f => [f.FolderId, f]));

        for (const folder of factory.folders) {
            folder.Subfolders = [];
        }

        for (const folder of factory.folders) {
            const parent = factory.foldersById.get(folder.ParentId);
            folder.ParentFolder = parent;
            if (parent) {
                parent.Subfolders.push(folder);
            }
        }

        for (const folder of factory.folders) {
            folder.Subfolders.sort(orderByName);
        }
    }

    function orderByName(a, b) {
        return String(a.Name).localeCompare(b.Name)
            || a.FolderId - b.FolderId;
    }

    factory.buildingInFolderCount = function (folderId) {
        const project = factory.foldersById.get(folderId).Project;
        const buildingCount = project.ProjectId !== -1
            ? project.Berekeningen.length || project.AantalBerekeningen
            : 0;
        return buildingCount;
    };

    factory.deleteFolder = async function (folderId) {
        const folder = factory.foldersById.get(folderId);
        const response = await deleteFolderOnServer(folderId)
        reparentFolder(folder, -2);
        markFolderAsDeleted(folder, response.data);
    };

    function reparentFolder(folder, newParentId, skipSorting = false) {
        // eerst verwijderen uit submappen van huidige ParentFolder (indien nodig)
        const oldParent = folder.ParentFolder || factory.foldersById.get(folder.ParentId);
        const index = oldParent?.Subfolders?.findIndex(f => f.FolderId === folder.FolderId);
        if (index > -1) {
            oldParent.Subfolders.splice(index, 1);
        }

        // dan nieuwe parent toekennen
        const newParent = factory.foldersById.get(newParentId);
        folder.ParentId = newParentId;
        folder.ParentFolder = newParent;

        if (newParent) {
            // en toevoegen aan submappen van nieuwe ParentFolder
            newParent.Subfolders.push(folder);
            if (!skipSorting) {
                newParent.Subfolders.sort(orderByName);
            }
        }
    }

    function markFolderAsDeleted(folder, data) {
        folder.DeleteDate = data.deleteDate;

        // loop alle projecten, subfolders en berekeningen ook na
        markProjectAsDeleted(folder.Project, data);

        for (const subfolder of folder.Subfolders) {
            markFolderAsDeleted(subfolder, data);
        }
    }

    function markProjectAsDeleted(project, data) {
        project.DeleteDate = data.deleteDate;
        const path = data.paths[project.ProjectId];
        for (const berekening of project.Berekeningen) {
            berekening.DeleteDate = data.deleteDate;
            berekening.DeletedBy = data.deletedBy;
            berekening.OriginalPath = path;
        }
    }

    factory.editFolder = function (folderId) {
        const folderdata = factory.foldersById.get(folderId);

        const promise = saveFolderOnServer(folderdata);

        orderFoldersBy("Name");

        return promise;
    };

    factory.copyFolder = async function (folderId, purpose) {
        try {
            const folder = factory.foldersById.get(folderId);
            const connection = new HubConnection('/hubs/copy');
            const results = await connection.run("CopyFolder", folderId, folder.ParentId, purpose);
            if (results) {
                for (const folder of results) {
                    factory.folders.push(folder);
                    factory.foldersById.set(folder.FolderId, folder);
                    folder.Subfolders = [];
                }
                for (const folder of results) {
                    const parent = factory.foldersById.get(folder.ParentId);
                    folder.ParentFolder = parent;
                    parent.Subfolders.push(folder);
                }
                for (const folder of results) {
                    reparentFolder(folder, folder.ParentId, true);
                }
                orderFoldersBy('Name');
            }
            return results[0].FolderId;
        } catch (err) {
            $log.error(err, 'while copying folder', folderId, ' for ', purpose);
            await ntaAlert.showError();
        }
    };

    factory.exportBuilding = async function (buildingIds, labels = false) {
        if (labels && ! await checkLabels(0, buildingIds)) {
            return false;
        }
        try {
            const connection = new HubConnection('/hubs/export', { 'showProgress': buildingIds.length > 1 ? true : false });
            const results = await connection.run("ExportBuilding", buildingIds, labels, location.href);
            if (results) {
                return await downloadFile(results[1], results[0]);
            }
        } catch (err) {
            $log.error(err, 'while attempting to export buildings', buildingIds, labels ? 'with' : 'without', 'labels');
            await ntaAlert.showError();
            return false;
        }
    };

    factory.exportFolder = async function (folderId, labels = false) {
        if (labels && ! await checkLabels(folderId)) {
            return false;
        }
        try {
            const connection = new HubConnection('/hubs/export');
            const results = await connection.run("ExportFolder", folderId, labels, location.href);
            if (results) {
                return await downloadFile(results[1], results[0]);
            }
        } catch (err) {
            $log.error(err, 'while attempting to export folder', folderId, labels ? 'with' : 'without', 'labels');
            await ntaAlert.showError();
            return false;
        }
    };

    async function checkLabels(folderId, buildingIds) {
        try {
            // Controleer of alle labels wel gedownload zijn
            const url = folderId === 0
                ? `/epc/CheckLabelsForBuildings?b=${buildingIds.join('&b=')}`
                : `/epc/CheckLabelsForFolder?folderId=${folderId}`;
            const response = await fetch(url);
            if (!response.ok) {
                ntaAlert.showError();
                return false;
            } else {
                const reply = await response.json();
                if (!reply.canContinue) {
                    if (folderId !== 0 || buildingIds.length > 1) {  //meerdere berekeningen
                        return factory.confirm('[W079]');
                    }
                    else {
                        factory.warning('[W078]');
                        return false;
                    }
                }
            }
            return true;
        } catch (err) {
            $log.error(err, 'while attempting to export folder', folderId, '/building(s)', buildingIds, labels ? 'with' : 'without', 'labels');
            ntaAlert.showError();
            return false;
        }
    };

    factory.downloadLabelBuilding = async function (buildingIds) {
        return await downloadFile(`epc/getlabelforbuildings?b=${buildingIds.join('&b=')}`);
    };

    factory.downloadLabelProject = async function (projectId) {
        return await downloadFile(`epc/getlabelforproject/${projectId}`);
    };

    const expectedSignature = new Uint8Array([80, 75, 3, 4]);

    factory.importFiles = async function (files, folderId, runId) {
        const formData = new FormData();
        formData.append("folderId", folderId);
        formData.append("runId", runId);
        const errors = [];
        let totalSize = 0;
        for (const file of files) {
            totalSize += file.size;
            let isValid = true;
            try {
                const signature = new Uint8Array(await file.slice(0, 4).arrayBuffer());
                isValid = signature.length === expectedSignature.length && signature.every((byte, index) => byte === expectedSignature[index]);
            } catch (err) {
                $log.warn(err);
            }
            if (isValid) {
                formData.append("files", file);
            } else {
                errors.push(`"${file.name}" is geen Uniec 3-exportbestand.`);
            }
        }
        if (totalSize >= 100 * 1000 * 1000) {
            errors.push("\nU probeert teveel tegelijk te uploaden. Uniec 3 kan momenteel niet meer dan 100 MB per keer uploaden.");
        }
        if (errors.length > 0) {
            throw errors.join("\n");
        } else {
            const response = await fetch('Projects/ImportFiles', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                return await response.json();
            } else {
                $log.error('Failed to upload files to folder', folderId, ':', response);
                throw response.statusText;
            }
        }
    };

    factory.importFile = async function (importData) {
        return await importFileOnServer(importData)
    };

    factory.createFolder = async function (parentId) {
        const response = await addFolderOnServer(parentId)
        const folder = response.data;
        if (folder) {
            folder.Subfolders = [];

            factory.folders.push(folder);
            factory.foldersById.set(folder.FolderId, folder);

            reparentFolder(folder, parentId);
        }
        return folder?.FolderId;
    };

    factory.createBerekening = async function (folderId, type, ntaVersion) {
        try {
            const response = await addBerekeningOnServer(folderId, type, ntaVersion)
            return response.data;
        } catch (err) {
            throw err && err.data || err;
        }
    };

    factory.copyBerekening = async function (folderId, buildingIds, purpose) {
        try {
            const connection = new HubConnection('/hubs/copy');
            const results = await connection.run("CopyBuilding", buildingIds, purpose);

            if (results) {
                const berekeningen = factory.foldersById.get(folderId).Project.Berekeningen;
                for (const result of results) {
                    const indexOfBerekening = berekeningen.findIndex(b => b.BuildingId === result.OrgBuildingId);
                    berekeningen.splice(indexOfBerekening + 1, 0, result);
                }
            }

        } catch (err) {
            $log.error(err, 'while copying gebouw', buildingIds);
            await ntaAlert.showError();
        }
    };

    factory.deleteBerekeningen = async function (folderId, berekeningen) {
        const project = factory.foldersById.get(folderId).Project;
        const prullenbak = factory.foldersById.get(-2);

        //eerst angular
        const buildingIds = new Set(berekeningen.map(b => b.BuildingId));
        project.Berekeningen = project.Berekeningen.filter(b => !buildingIds.has(b.BuildingId));
        project.AantalBerekeningen -= berekeningen.length;

        if (prullenbak) {
            prullenbak.Project.Berekeningen.push(...berekeningen);
            prullenbak.Project.AantalBerekeningen += berekeningen.length;
            prullenbak.Project.Berekeningen = $filter('orderBy')(prullenbak.Project.Berekeningen, "Name");
        }

        // dan op de server
        const response = await deleteBerekeningOnServer([...buildingIds]);
        if (response.data) {
            const berekeningenById = new Map(prullenbak.Project.Berekeningen.map(b => [b.BuildingId, b]));
            for (const bld of response.data) {
                const berekening = berekeningenById.get(bld.BuildingId);
                if (berekening) {
                    //data bijwerken
                    berekening.DeleteDate = bld.DeleteDate;
                    berekening.DeletedBy = bld.DeletedBy;
                    berekening.OriginalPath = bld.OriginalPath;
                }
            }
        }
    };

    factory.hasMeldingen = function (building) {
        return String(building.Results.GEB_HASMELD).toLowerCase() === 'true';
    };

    factory.isCalcNeeded = function (building) {
        // Als een berekening afgemeld is, dan is-ie per definitie al berekend
        return !building.Afgemeld && String(building.Results.GEB_CALCNEEDED).toLowerCase() !== "false";
    };

    factory.isMaatwerkadvies = function (building) {
        return String(building.Results.SETTINGS_MAATADVIES).toLowerCase() === 'true';
    };

    factory.exportBerekeningCSV = async function (buildingId) {
        const today = new Date().toISOString().substring(0, 10);
        const filename = `uniec3_berekening-${buildingId}_${today}.csv`;
        return await downloadFile(`Project/0/Berekening/${buildingId}/calc.csv`, filename, 'text/csv');
    };

    factory.createRapportFolder = async function (folder) {
        await createRapport(folder && folder.FolderId, []);
    };

    factory.createRapportBerekening = async function (buildingIds) {
        await createRapport(0, buildingIds);
    };

    async function createRapport(folderId, buildingIds) {
        try {
            const connection = new HubConnection('/hubs/pdf');
            const [filename, url] = await connection.run("CreatePDFRapport", folderId, buildingIds);

            const link = document.createElement("a");
            link.target = "_blank";
            link.style = "display: none";
            link.href = url;
            link.download = filename;
            link.click();

            return true;
        } catch (err) {
            $log.error(err, `in ${createRapport.name}(${folderId}, ${JSON.stringify(buildingIds)})`);
            let message = 'Probeer het opnieuw, of neem contact op met de helpdesk';
            if (typeof err === 'string') {
                const warning = err.startsWith('[') && factory.warnings.find(x => x.Id === err);
                if (warning) {
                    message = warning.Value;
                } else {
                    message = err;
                }
            }
            await $mdDialog.show($mdDialog.alert({
                title: "Rapportage aanmaken is mislukt",
                textContent: message,
                ok: "Sluiten"
            }));
            return false;
        }
    }

    factory.createMWARapportBerekening = async function (buildingIds) {
        console.log("indexModule: createMWARapportBerekening")
        await createMWARapport(0, buildingIds);
    };

    factory.createMWARapportFolder = async function (folder) {
        console.log("indexModule: createMWARapportFolder")
        await createMWARapport(folder && folder.FolderId, []);
    };
    async function createMWARapport(folderId, buildingIds) {
        try {
            const connection = new HubConnection('/hubs/excel');
            const [filename, url] = await connection.run("CreateExcelResults", folderId, buildingIds);

            const link = document.createElement("a");
            link.target = "_blank";
            link.style = "display: none";
            link.href = url;
            link.download = filename;
            link.click();

            return true;
        } catch (err) {
            $log.error(err, `in ${createRapport.name}(${folderId}, ${JSON.stringify(buildingIds)})`);
            let message = 'Probeer het opnieuw, of neem contact op met de helpdesk';
            if (typeof err === 'string') {
                const warning = err.startsWith('[') && factory.warnings.find(x => x.Id === err);
                if (warning) {
                    message = warning.Value;
                } else {
                    message = err;
                }
            }
            await $mdDialog.show($mdDialog.alert({
                title: "Rapportage aanmaken is mislukt",
                textContent: message,
                ok: "Sluiten"
            }));
            return false;
        }
    }

    factory.lockBerekening = async function (folderId, buildingIds, lock) {
        const buildings = factory.foldersById.get(folderId).Project
            .Berekeningen.filter(b => buildingIds.includes(b.BuildingId));
        const response = await $http.post("Projects/SaveBerekeningLocked?Locked=" + lock, JSON.stringify(buildingIds));
        if (response.data) {
            //in angular opslaan
            const buildingsById = new Map(buildings.map(b => [b.BuildingId, b]));
            for (const buildingId of response.data.buildingIds) {
                const building = buildingsById.get(buildingId);
                if (building) {
                    building.Locked = response.data.locked;
                }
            }
        }
    };

    factory.saveProjectNaam = async function (folderId, name) {
        try {
            const folder = factory.foldersById.get(folderId);
            const project = folder && folder.Project;
            if (!name || project.Name === name) {
                return;
            }

            progressCircle.setShowProgressValue(true);
            project.Name = name;

            const form = new FormData();
            form.set('folderId', folderId);
            form.set('name', name);
            const response = await fetch('Projects/SaveProjectName', { method: 'POST', body: form });
            if (!response.ok) {
                throw response;
            }
        } catch (err) {
            $log.error(`Oeps in saveProjectNaam:`, err);
            const alert = $mdDialog.alert()
                .title('Kan projectnaam niet opslaan')
                .textContent(`Het is niet gelukt om de naam "${name}" op te slaan.`)
                .ok('Sluiten');
            $mdDialog.show(alert);
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    };

    factory.saveBerekeningNaam = async function (folderId, bldId, name) {
        await saveBerekeningsNaamOnServer(bldId, name)
    };

    factory.orderBerekeningenBy = function (folderId, order) {
        const folder = factory.foldersById.get(folderId);
        const project = folder && folder.Project;
        if (project) {
            project.Berekeningen = $filter('orderBy')(project.Berekeningen, order);
        }
    };

    factory.moveFolder = async function (folderId, parentId) {
        const folder = factory.foldersById.get(folderId);
        const targetFolder = factory.foldersById.get(parentId);

        const wasDeleted = !!folder.DeleteDate;
        const isDeleting = parentId === -2 || !!(targetFolder && targetFolder.DeleteDate);

        if (isDeleting) {
            if (wasDeleted) {
                // Wordt verplaatst binnen de prullenbak. Dat mag niet, dus doe niks
                return false;

            } else {
                // Projectmap wordt verwijderd. Eerst vragen of dat de bedoeling is.
                const confirm = $mdDialog.confirm()
                    .title('Verwijderen projectmap')
                    .textContent('U staat op het punt om projectmap "' + folder.Name + '" te verwijderen. Weet u het zeker?')
                    .ariaLabel('Verwijderen')
                    .ok('Ja')
                    .cancel('Nee');
                try {
                    await $mdDialog.show(confirm);
                } catch (err) {
                    // niet de bedoeling dus
                    return false;
                }
            }
        }

        // Probeer nu de verplaatsing op de server uit te voeren. Als dat mislukt, treedt een exception op.
        try {
            await moveFolderOnServer(folderId, parentId);
        } catch (err) {
            const alert = $mdDialog.alert()
                .title('Mislukt')
                .ariaLabel('Mislukt')
                .htmlContent(`Het is niet gelukt om "${folder.Name}" map te verplaatsen.`)
                .ok('Sluiten');
            $mdDialog.show(alert);
            return false;
        }

        reparentFolder(folder, parentId);

        if (!wasDeleted && isDeleting) {
            // Zet delete data van de folder en alle inhoud (subfolders, projecten en berekeningen)
            markFolderAsDeleted(folder);

        } else if (wasDeleted && !isDeleting) {
            // herstel de folder en alle inhoud (subfolders en berekeningen)
            markFolderAsRestored(folder);
        }

        targetFolder.Open = true;

        return true;

    };

    function markFolderAsRestored(folder) {
        folder.DeleteDate = null;

        // loop alle projecten, subfolders en berekeningen ook na
        markProjectAsRestored(folder.Project);

        for (const subfolder of folder.Subfolders) {
            markFolderAsRestored(subfolder);
        }
    }

    function markProjectAsRestored(project) {
        project.DeleteDate = null;

        for (const berekening of project.Berekeningen) {
            berekening.DeleteDate = null;
            berekening.DeletedBy = null;
            berekening.OriginalPath = null;
        }
    }

    factory.moveBerekening = async function (buildingId, folderId, parentId) {
        if (folderId === parentId) { // De berekening is niet verplaatst
            return;
        }

        const sourceProject = factory.foldersById.get(folderId).Project;
        const targetProject = factory.foldersById.get(parentId).Project;
        const berekeningen = sourceProject.Berekeningen.filter(bld => !!bld.DeleteDate === !!sourceProject.DeleteDate) || [];

        const selectedBerekening = berekeningen.find(bld => bld.BuildingId === buildingId);
        const selectedBerekeningen = berekeningen.filter(bld => bld.selected);
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(selectedBerekening) };
        const selectedBerekeningenIds = selectedBerekeningen.map(x => x.BuildingId);

        try {
            await moveBerekeningOnServer(selectedBerekeningenIds, parentId);
        } catch (err) {
            const alert = $mdDialog.alert()
                .title('Mislukt')
                .ariaLabel('Mislukt')
                .htmlContent(`Het is niet gelukt om ${selectedBerekeningenIds.length} berekening(en) te verplaatsen.`)
                .ok('Sluiten');
            $mdDialog.show(alert);
            return false;
        }

        // Stel nieuwe ProjectId in van de berekening
        for (const berekening of selectedBerekeningen) {
            berekening.ProjectId = targetProject.ProjectId;
            if (berekening.DeleteDate && !targetProject.DeleteDate) {
                berekening.DeleteDate = null;
                berekening.DeletedBy = null;
                berekening.OriginalPath = null;
            }

            // Voeg toe aan targetProject
            if (targetProject.Berekeningen.length > 0 || targetProject.AantalBerekeningen === 0) {
                targetProject.Berekeningen.push(berekening);
            }
            targetProject.AantalBerekeningen++;

            // Verwijder uit oude lijst
            sourceProject.Berekeningen.splice(sourceProject.Berekeningen.indexOf(berekening), 1);
            sourceProject.AantalBerekeningen--;
        }
        targetProject.Berekeningen = $filter('orderBy')(targetProject.Berekeningen, "Name");
    };

    let _subsequentNetworkErrors = 0;

    factory.canStartRegistration = async function (projectId = null, buildingId = null) {
        try {
            const params = new URLSearchParams();
            if (projectId) params.set('projectId', projectId);
            if (buildingId) params.set('buildingId', buildingId);

            let response;
            try {
                response = await fetch(`Epc/CanStartRegistration?${params}`, { redirect: 'manual' });
                _subsequentNetworkErrors = 0;
            } catch (err) {
                throw new NetworkError(err);
            }
            if (response.redirected || response.status === 401) {
                await ntaAlert.showLogin();
                return false;
            }
            const data = await response.json();
            if (data.error) {
                throw new Error(data.error);

            } else if (Array.isArray(data.warnings) && data.warnings.length > 0) {
                progressCircle.setShowProgressValue(false);

                const warnings = new Set(data.warnings);
                for (const warningCode of warnings) {
                    await factory.warning(warningCode);
                }

                return false;
            }

            // Als alles goed is gegaan, dan kunnen we het afmeldformulier openen
            return true;

        } catch (err) {
            progressCircle.setShowProgressValue(false);
            $log.error('Oeps', (buildingId ? `Building ${buildingId}` : `Project ${projectId}`) + `: `, err, ' in ', factory.canStartRegistration.name);
            if (err instanceof NetworkError) {
                _subsequentNetworkErrors++;
                if (_subsequentNetworkErrors <= 3) {
                    return await factory.canStartRegistration(projectId, buildingId);
                } else {
                    await ntaAlert.showNetworkError();
                }
            } else {
                await $mdDialog.show($mdDialog.alert({
                    title: "Afmelden lukt niet",
                    textContent: "Probeer het zometeen nog een keer. Als dat niet werkt, neem dan contact op met de helpdesk van Uniec 3.",
                    ok: "Sluiten",
                }));
            }
            return false;
        }
    };

    /// De class NetworkError wordt gebruikt om een netwerkfout aan te geven, anders gezegd: als fetch een fout oplevert.
    class NetworkError extends Error {
        constructor(innerError, ...args) {
            super(innerError && innerError.message || innerError, ...args);
            this.name = NetworkError.name;
            this.innerError = innerError;
        }
    }

    factory.warning = function (warningCode, title = '') {
        const warning = factory.warnings.find(w => w.Id === warningCode);
        const alert = $mdDialog.alert()
            .title(title || warning && warning.FilterValue1 || 'waarschuwing')
            .htmlContent(warning && warning.Value || warningCode)
            .ok('Sluiten');

        progressCircle.setShowProgressValue(false);

        return $mdDialog.show(alert);
    };

    factory.confirm = function (warningCode, title = '') {
        const warning = factory.warnings.find(w => w.Id === warningCode);

        const confirm = $mdDialog.confirm()
            .title(title || warning && warning.FilterValue1 || 'waarschuwing')
            .htmlContent(warning && warning.Value || warningCode)
            .ok(warning.FilterValue2)
            .cancel(warning.FilterValue3);

        progressCircle.setShowProgressValue(false);

        return $mdDialog.show(confirm)
            .then(() => {
                return true;
            }, () => {
                return false;
            });
    };


    factory.waitForElement = function (elementSelector, timeoutMS) {
        const timeout = timeoutMS && Date.now() + timeoutMS || null;
        return new Promise(resolve => {
            const poller = setInterval(() => {
                const element = document.querySelector(elementSelector);
                if (element) {
                    clearInterval(poller);
                    resolve(element);
                } else if (timeout !== null && timeout < Date.now()) {
                    clearInterval(poller);
                    resolve();
                }
            }, 100);
        });
    }



    var saveBerekeningsNaamOnServer = async function (bldId, name) {
        const form = new FormData();
        form.set('buildingId', bldId);
        form.set('name', name);
        const response = await fetch("Projects/SaveBerekeningName", { method: 'POST', body: form });
        if (!response.ok) {
            throw response;
        }
    };

    var addBerekeningOnServer = function (folderId, type, ntaVersion) {
        return $http.post("Projects/AddBerekening?FolderId=" + folderId + "&Type=" + type + "&Version=" + ntaVersion);
    };

    var addFolderOnServer = function (parentId) {
        return $http.post("Projects/AddFolder?ParentId=" + parentId);
    };

    var deleteBerekeningOnServer = function (buildingIds) {
        return $http.post("Projects/DeleteBerekening", JSON.stringify(buildingIds));
    };

    var deleteFolderOnServer = function (folderId) {
        return $http.post("Projects/DeleteFolder?FolderId=" + folderId);
    };

    var saveFolderOnServer = function (folderdata) {
        const keysToSkip = new Set(['$$hashKey', 'ParentFolder', 'Subfolders']);
        const json = JSON.stringify(folderdata, (key, value) => keysToSkip.has(key) ? undefined : value);
        return $http.post("Projects/SaveFolderData", json);
    };

    var orderFoldersBy = function (order) {
        factory.folders = $filter('orderBy')(factory.folders, order);
        for (const folder of factory.folders) {
            folder.Subfolders = $filter('orderBy')(folder.Subfolders, order);
        }
    };

    var moveFolderOnServer = function (folderId, parentId) {
        return $http.post("Projects/MoveFolder?FolderId=" + folderId + "&ParentFolderId=" + parentId);
    };

    var moveBerekeningOnServer = function (buildingIds, parentId) {
        return $http.post("Projects/MoveBerekening?ParentFolderId=" + parentId, JSON.stringify(buildingIds));
    };

    var importFileOnServer = function (importData) {
        return $http.post("Projects/importFile", importData);
    };

    async function downloadFile(url, defaultFilename = null, contentType = null) {
        try {
            const response = await $http.get(url, { responseType: 'arraybuffer' });
            contentType = contentType || response.headers()['content-type'] || 'application/octet-stream';
            const file = new Blob([response.data], { type: contentType + ';base64' });
            const fileURL = window.URL.createObjectURL(file);
            try {
                const match = /filename[^;=\n]*=((['"])(.*?)\2|([^;\n]*))/.exec(response.headers()['content-disposition']);
                const filename = match && (match[3] || match[4]) || defaultFilename;
                const link = document.createElement("a");
                link.target = "_blank";
                link.style = "display: none";
                link.href = fileURL;
                link.download = filename;
                link.click();
                return true;
            } finally {
                setTimeout(() => URL.revokeObjectURL(fileURL), 30000);
            }
        } catch (err) {
            if (err.data) {
                try {
                    const decodedString = String.fromCharCode.apply(null, new Uint8Array(err.data));
                    if (err.status === 420) {
                        if (factory.warnings.some(w => w.Id === decodedString)) {
                            factory.warning(decodedString);
                        } else {
                            const alert = $mdDialog.alert()
                                .title('Download mislukt')
                                .htmlContent(decodedString)
                                .ok('Sluiten');
                            $mdDialog.show(alert);
                        }
                        return false;
                    }

                    const reply = JSON.parse(decodedString);
                    const message = reply && reply.error;
                    if (factory.warnings.some(w => w.Id === message)) {
                        factory.warning(message);
                        return false;
                    }
                } catch (ignoreErr) {
                    // als dit ook niet goed ging, negeren (bv. als de data geen JSON of zelfs tekst bevat)
                    $log.error('Download mislukt: ', err);
                }
            } else {
                $log.error('Download mislukt: ', err);
            }
            $mdDialog.show($mdDialog.alert({
                title: "Download mislukt",
                textContent: "Neem contact op met de helpdesk van Uniec 3.",
                ok: "Sluiten",
            }));
            return false;
        }
    }

    return factory;
}]);

