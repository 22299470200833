﻿angular.module('projectModule')
    .service('ntaResults',
        ['$log', 'ntaData', 'ntaEntityDataOrg', 'settingsMenuData',
function ($log,   ntaData,   ntaEntityDataOrg,   settingsMenuData) {
    'use strict';
    const self = this;

    /// == Description ============================================================================

    /// Deze service houdt een geïndexeerde lijst bij van alle resultaat-entiteiten,
    ///  geïndexeerd op de set van parents (GEB/UNIT, BASIS/VARIANT en NTA-RESULTS/MWA-RESULTS.
    /// Hij biedt zowel mogelijkheid om te index bij te werken als er resultaatparents toegevoegd
    ///  dan wel verwijderd zijn, alsook om ervoor te zorgen dat alle benodigde resultaatentiteiten
    ///  zelf wel aanwezig zijn.
    /// De index wordt geïnitialiseerd bij het aanmaken van de service, en kan daarna bijgewerkt
    ///  worden m.b.v. updateForParents (als een parent is toegevoegd of gekopieerd) en
    ///  updateForDeletedParent (als een parent is verwijderd).
    /// Met de method getForParents kan een dataset van resultaatentiteiten horend bij de
    ///  opgegeven parent(s) worden opgehaald.


    /// == Imports ================================================================================

    const _resEnergiefunctiePropInfos = ntaData.resultEnergiePropInfos;


    /// == Instance variables =====================================================================

    const _resultParentsOrder = new Map(['GEB', 'UNIT', 'UNIT-RZ', 'RZ', 'CONSTRT', 'BASIS', 'VARIANT', 'NTA-RESULTS', 'MWA-RESULTS'].map((entityId, index) => [entityId, index]));

    let _resultDatasets = new Map();


    /// We gebruiken StaleIndexError om tijdens checkResultEntities aan te geven dat de index
    ///  bijgewerkt moet worden, en dat checkResultEntities daarna opnieuw uitgevoerd moet worden.
    class StaleIndexError extends Error {
        constructor(...args) {
            super(...args);
            this.name = StaleIndexError.name;
        }
    } //== end: StaleIndexError ===================================================================

    /// == Exports ================================================================================

    Object.assign(self, {
        // methods
        initialize,
        getForParents,
        updateForParents,
        updateForDeletedParent,

        checkResultEntities,
        checkResultTOjuliRelations,
        checkResultTOjuliRelationsForUnitRz,

        createResultPvEntity,

        setUnitResultRelevancy,
    });


    /// == Initialization =========================================================================

    // Alleen in de debug-pagina hebben we deze niet, en daar willen we ook niets.
    if (ntaData.entities['GEB']) {
        initialize(true);
    }


    /// == Implementation =========================================================================

    function initialize(alsoCheck = false) {
        _resultDatasets = getAllResultDatasets();
        if (alsoCheck) {
            checkResultEntities();
            // als checkResultEntities nieuwe entiteiten heeft aangemaakt, zorgt deze er zelf voor
            //  dat de index opnieuw geïnitialiseerd wordt.
        }
    } //-- end: initialize ------------------------------------------------------------------------

    function getAllResultDatasets(gebOrUnit = null, variant = null, forTailoredAdvice = null, resultEntityIds = null) {
        const buildingData = ntaData.original;

        // parentcombinaties samenstellen:
        // GEB|UNIT + BASIS|VARIANT + NTA-RESULTS|MWA-RESULTS
        const gebUnitParents = gebOrUnit ? [gebOrUnit]
            : buildingData.getListWithEntityId('GEB').concat(buildingData.getListWithEntityId('UNIT'));

        const variantParents = variant ? [variant]
            : buildingData.getListWithEntityId('BASIS').concat(buildingData.getListWithEntityId('VARIANT'));

        const standardParents = [];
        if (forTailoredAdvice === false || forTailoredAdvice === null) {
            standardParents.push(...buildingData.getListWithEntityId('NTA-RESULTS'));
        }
        if (forTailoredAdvice === true || forTailoredAdvice === null) {
            standardParents.push(...buildingData.getListWithEntityId('MWA-RESULTS'));
        }

        if (resultEntityIds && !(resultEntityIds instanceof Set)) {
            resultEntityIds = new Set(resultEntityIds);
        }

        const parentEDs = gebUnitParents.concat(variantParents).concat(standardParents);

        // Alle datasets samenstellen (voor elke parent-combinatie)
        const datasets = [];
        const datasetByParentIds = new Map();
        for (const unit of gebUnitParents) {
            if (variantParents.length) {
                for (const variant of variantParents) {
                    for (const parent of standardParents) {
                        const dataset = {
                            parents: [unit, variant, parent],
                            resultEntdatasByEntityId: new Map(),
                        };
                        datasets.push(dataset);
                    }
                }
            } else {
                // in oude berekeningen hebben we alleen maar resultaten voor GEB/UNIT
                const dataset = {
                    parents: [unit],
                    resultEntdatasByEntityId: new Map(),
                };
                datasets.push(dataset);
            }
        }
        for (const dataset of datasets) {
            const key = dataset.parents.map(ed => ed.EntityDataId).join('|');
            datasetByParentIds.set(key, dataset);
        }

        // Dan alle resultaat-entdatas aflopen, en elke bij de juiste parents indelen
        let relationFilter;
        if (!resultEntityIds) {
            relationFilter = rel => rel.ChildEntityId === 'PRESTATIE' || rel.ChildEntityId.startsWith('RESULT-');
        } else {
            const resultEntityIdSet = new Set(resultEntityIds);
            relationFilter = rel => resultEntityIdSet.has(rel.ChildEntityId);
        }
        const resultEDs = new Set(parentEDs.flatMap(ed => buildingData.getChildRelations(ed))
            .filter(relationFilter)
            .map(rel => buildingData.get(rel.Child))
            .filter(ed => ed));
        for (const resultED of resultEDs) {
            let parents = buildingData.getParentRelations(resultED)
                .filter(rel => _resultParentsOrder.has(rel.ParentEntityId))
                .sort((relA, relB) => _resultParentsOrder.get(relA.ParentEntityId) - _resultParentsOrder.get(relB.ParentEntityId))
                .map(rel => buildingData.get(rel.Parent));
            if (parents.length === 0) {
                $log.error(`Geen parents gevonden voor ${resultED.EntityId}[${resultED.EntityDataId}].`);
                continue;
            }

            const unitParent = parents[0];
            if (unitParent.EntityId === 'CONSTRT') {
                const unit = buildingData.findEntity(unitParent, '^BEGR.^UNIT-RZ.^UNIT');
                if (!unit) {
                    $log.error(`Geen UNIT-parent gevonden voor ${unitParent.EntityId}[${unitParent.EntityDataId}] (tbv. resultaat ${resultED.EntityId}[${resultED.EntityDataId}]).`);
                    continue;
                }
                parents[0] = unit;
            } else if (unitParent.EntityId === 'RZ') {
                const geb = buildingData.getFirstWithEntityId('GEB');
                if (!geb) {
                    $log.error(`Geen GEB-parent gevonden voor ${unitParent.EntityId}[${unitParent.EntityDataId}] (tbv. resultaat ${resultED.EntityId}[${resultED.EntityDataId}]).`);
                    continue;
                }
                parents[0] = geb;
            } else if (unitParent.EntityId === 'UNIT-RZ') {
                const unit = buildingData.getFirstParent(unitParent, 'UNIT');
                if (!unit) {
                    $log.error(`Geen UNIT-parent gevonden voor ${unitParent.EntityId}[${unitParent.EntityDataId}] (tbv. resultaat ${resultED.EntityId}[${resultED.EntityDataId}]).`);
                    continue;
                }
                parents[0] = unit;
            }

            //parentsKey kent alleen maar GEB/UNIT, BASIS/VARIANT, NTA/MWA parents
            //RESULT-GTO en RESULT-TOJULI kunnen vierde parent hebben (RZ of UNIT-RZ)
            const validParentTypes = new Set(['GEB', 'UNIT', 'BASIS', 'VARIANT', 'NTA-RESULTS', 'MWA-RESULTS']);
            parents = parents.filter(x => validParentTypes.has(x.EntityId));

            const parentsKey = parents.map(ed => ed.EntityDataId).join('|');
            const dataset = datasetByParentIds.get(parentsKey);
            if (dataset) {
                const list = dataset.resultEntdatasByEntityId.get(resultED.EntityId) || [];
                if (list.length === 0) {
                    dataset.resultEntdatasByEntityId.set(resultED.EntityId, list);
                }
                const index = binarySearch(list, resultED, orderResults);
                if (index < 0) {
                    list.splice(~index, 0, resultED);
                }
            } else if (!resultEntityIds) {
                $log.error(`Geen dataset gevonden voor ${resultED.EntityId}[${resultED.EntityDataId}] in parent group`, parents);
            }
        }

        datasetByParentIds.units = gebUnitParents;
        datasetByParentIds.variants = variantParents;
        datasetByParentIds.standards = standardParents;

        datasetByParentIds.getForParents = ntaData.entities['BASIS'] ? getResultDatasetForParentsV2 : getResultDatasetForParentsV1;

        return datasetByParentIds;
    } //-- end: getAllResultDatasets --------------------------------------------------------------

    function getForParents(gebOrUnit = null, variant = null, forTailoredAdvice = false, resultEntityIds = null, forCheck = false) {
        return _resultDatasets.getForParents(gebOrUnit, variant, forTailoredAdvice, resultEntityIds, forCheck);
    } //-- end: getForParents ---------------------------------------------------------------------


    // Bij gebOrUnit, variant en forTailoredAdvice wordt bij waarde null alles opgehaald.
    // gebOrUnit = null -> gebouw en alle units, variant = null -> de BASIS en alle varianten, forTailoredAdvice = null -> NTA en MWA
    function getResultDatasetForParentsV2(gebOrUnit = null, variant = null, forTailoredAdvice = false, resultEntityIds = null, forCheck = false) {
        const datasets = [];
        const standardParent = this.standards[forTailoredAdvice ? 1 : 0];
        for (const unitED of gebOrUnit ? [gebOrUnit] : this.units) {
            for (const variantED of variant ? [variant] : this.variants) {
                const parents = [unitED, variantED, standardParent];
                const key = parents.map(parent => parent.EntityDataId).join('|');
                const dataset = this.get(key);
                if (dataset) {
                    datasets.push(dataset);
                } else if (!forCheck) {
                    $log.warn(`Geen dataset gevonden`, parents);
                }
            }
        }
        if (datasets.length > 1) {
            return mergeDatasets(datasets, resultEntityIds);
        } else {
            return datasets[0];
        }
    } //-- end: getResultDatasetForParentsV2 ------------------------------------------------------

    function getResultDatasetForParentsV1(gebOrUnit = null, variant = null, forTailoredAdvice = false, resultEntityIds = null, forCheck = false) {
        if (!gebOrUnit) {
            return mergeDatasets(this.units.map(unit => this.getForParents(unit, variant, forTailoredAdvice, resultEntityIds, forCheck)), resultEntityIds);
        }
        return this.get(gebOrUnit.EntityDataId);
    } //-- end: getResultDatasetForParentsV1 ------------------------------------------------------

    function mergeDatasets(datasets, resultEntityIds) {
        if (resultEntityIds) resultEntityIds = new Set(resultEntityIds);
        const parents = new Set();
        const resultEntdatasByEntityId = new Map();
        for (const dataset of datasets) {
            for (const parent of dataset.parents) {
                parents.add(parent);
            }
            for (const [entityId, resultEDs] of dataset.resultEntdatasByEntityId) {
                if (resultEntityIds && !resultEntityIds.has(entityId))
                    continue;

                const list = resultEntdatasByEntityId.get(entityId);
                if (!list) {
                    resultEntdatasByEntityId.set(entityId, resultEDs.slice());
                } else {
                    for (const resultED of resultEDs) {
                        const index = binarySearch(list, resultED, orderResults);
                        if (index < 0) {
                            list.splice(~index, 0, resultED);
                        }
                    }
                }
            }
        }
        return {
            parents: [...parents],
            resultEntdatasByEntityId,
        };
    } //-- end: mergeDatasets ---------------------------------------------------------------------

    function orderResults(a, b) {
        return a.Order - b.Order
            || a.EntityDataId.localeCompare(b.EntityDataId);
    } //-- end: orderResults ----------------------------------------------------------------------

    function hasTailoredAdvice() {
        return settingsMenuData.getSetting('SETTINGS_MAATADVIES').Value === 'True';
    } //-- end: hasTailoredAdvice -----------------------------------------------------------------

    function checkResultEntities() {
        if (ntaData.current !== ntaData.original) return false; // dit is alleen nodig in de ntaData.original.

        let hasChanges = false;

        try {

            // GEB & UNITs
            const units = ntaEntityDataOrg.getListWithEntityId('UNIT');
            for (const parent of ntaEntityDataOrg.getListWithEntityId('GEB').concat(units)) {
                hasChanges = checkResultEntitiesForUnit(parent) || hasChanges;
            }
            setUnitResultRelevancy(units);

        } catch (err) {
            // Als de index niet bij is, dan moet deze eerst opnieuw opgebouw worden, en de checks opnieuw uitgevoerd.
            if (err instanceof StaleIndexError) {
                initialize(true);
                return;
            } else {
                throw err;
            }
        }

        if (hasChanges) {
            // Als de check wijzigingen heeft moeten doorvoeren, dan de resultatenset opnieuw indexeren
            initialize(false);
        }
    } //-- end: checkResultEntities ---------------------------------------------------------------

    function checkResultEntitiesForUnit(gebOrUnit) {
        let hasChanges = false;

        // Als geen variant was meegegeven, doorloop dan zowel de basisberekening als alle varianten.
        const variants = [ntaEntityDataOrg.getFirstWithEntityId('BASIS')] // <-- is undefined in oude versies, wat (ook) neerkomt op de basisberekening
            .concat(ntaEntityDataOrg.getListWithEntityId('VARIANT'));

        const tailoredAdviceOptions = hasTailoredAdvice() ? [false, true] : [false];

        for (const variant of variants) {
            for (const forTailoredAdvice of tailoredAdviceOptions) {
                hasChanges = checkResultDataset(gebOrUnit, variant, forTailoredAdvice) || hasChanges;
            }
        }

        return hasChanges;
    } //-- end: checkResultEntitiesForUnit --------------------------------------------------------

    function checkResultDataset(gebOrUnit, variant, forTailoredAdvice) {
        let hasChanges = false;

        const dataset = getForParents(gebOrUnit, variant, forTailoredAdvice, null, true);
        const { parents, resultEntdatasByEntityId } = dataset || {
            parents: [gebOrUnit, variant, ntaEntityDataOrg.getFirstWithEntityId(forTailoredAdvice ? 'MWA-RESULTS' : 'NTA-RESULTS')].filter(p => p),
            resultEntdatasByEntityId: new Map(),
        };

        const parentRels = parents.map(parent => ({
            "OnCopy": 1, "OnDelete": 1, "Parent": parent.EntityDataId, "ParentEntityId": parent.EntityId,
        }));

        const prestaties = resultEntdatasByEntityId.get('PRESTATIE') || [];
        if (prestaties.length === 0) { //is er een res aanwezig
            // controleren of er niet intussen een entiteit bestaat, die alleen nog niet in de index zit.
            const prestatieExists = ntaEntityDataOrg.getListWithEntityId('PRESTATIE')
                .some(ed => parents.every(parent => ntaEntityDataOrg.isRelation(parent, ed)));
            if (prestatieExists) {
                throw new StaleIndexError();
            }
            // geen res -> aanmaken, met ook een relatie naar parent
            ntaEntityDataOrg.create('PRESTATIE', -1, parentRels, []);
            hasChanges = true;
        } else if (prestaties.length > 1) {
            // als er teveel zijn, dan de extra verwijderen
            for (const prestatie of prestaties.slice(1)) {
                ntaEntityDataOrg.delete(prestatie.EntityDataId);
            }
            hasChanges = true;
        }

        const energiefuncties = resultEntdatasByEntityId.get('RESULT-ENERGIEFUNCTIE') || [];
        if (energiefuncties.length !== _resEnergiefunctiePropInfos.length) { //is er een res aanwezig
            const existingEnergiefuncties = ntaEntityDataOrg.getListWithEntityId('RESULT-ENERGIEFUNCTIE')
                .filter(ed => parents.every(parent => ntaEntityDataOrg.isRelation(parent, ed)));
            for (const energiefunctie of existingEnergiefuncties) {
                const index = binarySearch(energiefuncties, energiefunctie, orderResults);
                if (index < 0) {
                    // er bestaan dus energiefuncties die niet in de index aanwezig zijn.
                    throw new StaleIndexError();
                }
            }
            // geen res of verkeerd aantal -> aanmaken, met ook een relatie naar parent; of verwijderen
            hasChanges = createResultEnergiefuncties(parentRels, energiefuncties) || hasChanges;
        }

        const energiegebruiks = resultEntdatasByEntityId.get('RESULT-ENERGIEGEBRUIK') || [];
        if (energiegebruiks.length === 0) { //is er een res aanwezig
            // controleren of er niet intussen een entiteit bestaat, die alleen nog niet in de index zit.
            const energiegebruikExists = ntaEntityDataOrg.getListWithEntityId('RESULT-ENERGIEGEBRUIK')
                .some(ed => parents.every(parent => ntaEntityDataOrg.isRelation(parent, ed)));
            if (energiegebruikExists) {
                throw new StaleIndexError();
            }
            // geen res -> aanmaken, met ook een relatie naar parent
            ntaEntityDataOrg.create('RESULT-ENERGIEGEBRUIK', -1, parentRels, []);
            hasChanges = true;
        } else if (energiegebruiks.length > 1) {
            // als er teveel zijn, dan de extra verwijderen
            for (const energiegebruik of energiegebruiks.slice(1)) {
                ntaEntityDataOrg.delete(energiegebruik.EntityDataId);
            }
            hasChanges = true;
        }

        //-- we maken ook een RESULT-PV aan voor elke unit (én voor het gebouw).
        //-- Dit is alleen nodig voor de NTA-basisberekening; niet voor varianten, en ook niet voor maatwerkadvies.
        if ((!variant || variant.EntityId === 'BASIS') && !forTailoredAdvice) {
            const resultPVs = resultEntdatasByEntityId.get('RESULT-PV') || [];
            const resultPVsPerZonnecolOrPv = resultPVs.reduce((map, resultPV) => {
                for (const zonnecolOrPv of ntaEntityDataOrg.getParents(resultPV, ['PV', 'ZONNECOL'])) {
                    const resultPVs = map.get(zonnecolOrPv) || [];
                    if (resultPVs.length === 0) {
                        map.set(zonnecolOrPv, resultPVs);
                    }
                    resultPVs.push(resultPV);
                    return map;
                }
            }, new Map());
            const zonnecolsAndPvs = ntaEntityDataOrg.getListWithEntityId('PV').concat(ntaEntityDataOrg.getListWithEntityId('ZONNECOL'));
            for (const zonnecolOrPv of zonnecolsAndPvs) {
                const resultPVs = resultPVsPerZonnecolOrPv.get(zonnecolOrPv) || [];
                if (resultPVs.length === 0) {
                    // controleren of er niet intussen een entiteit bestaat, die alleen nog niet in de index zit.
                    const resultPvExists = ntaEntityDataOrg.getListWithEntityId('RESULT-PV')
                        .some(ed => [zonnecolOrPv, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)));
                    if (resultPvExists) {
                        throw new StaleIndexError();
                    }
                    createResultPvEntity(zonnecolOrPv, ...parents);
                    hasChanges = true;
                } else if (resultPVs.length > 1) {
                    // Overbodige resultaatentiteiten verwijderen
                    for (const resultPV of resultPVs.slice(1)) {
                        ntaEntityDataOrg.delete(resultPV.EntityDataId);
                    }
                    hasChanges = true;
                } else if (resultPVs.length > 1) {
                    // Overbodige resultaatentiteiten verwijderen
                    for (const resultPV of resultPVs.slice(1)) {
                        ntaEntityDataOrg.delete(resultPV.EntityDataId);
                    }
                    hasChanges = true;
                }
            }
        }

        return hasChanges;
    } //-- end: checkResultDataset ----------------------------------------------------------------

    function createResultEnergiefuncties(parentRels, existingEnergiefuncties = []) {
        let hasChanges = false;

        const remaining = new Set(existingEnergiefuncties);

        for (const propInfo of _resEnergiefunctiePropInfos) {
            const propValues = [...Object.entries(propInfo)]
                .map(([key, value]) => ({
                    PropertyId: 'RESULT-ENERGIEFUNCTIE_' + key,
                    Value: value,
                }));
            // Controleer of we deze al hadden
            const existingEnergiefunctie = existingEnergiefuncties
                .find(ed => propValues.every(pd => {
                    const propdata = ed.PropertyDatas[pd.PropertyId];
                    return propdata && propdata.Value === pd.Value;
                }));
            if (!existingEnergiefunctie) {
                ntaEntityDataOrg.create('RESULT-ENERGIEFUNCTIE', -1, parentRels, [], propValues);
                hasChanges = true;
            } else {
                remaining.delete(existingEnergiefunctie);
            }
        }

        // alle resterende energiefuncties verwijderen; die zijn kennelijk overbodig
        hasChanges = hasChanges || remaining.size > 0;
        for (const energiefunctie of remaining) {
            ntaEntityDataOrg.delete(energiefunctie.EntityDataId);
        }

        return hasChanges;
    } //-- end: createResultEnergiefuncties -------------------------------------------------------

    function checkResultTOjuliRelations() {
        if (ntaData.current !== ntaData.original) return; // dit is alleen nodig in de ntaData.original.

        let hasChanges = false;

        //eerst bestaande tojuli en gto langslopen om te checken of ze een 1 op 1 relatie hebben met rz of unit-rz => zo nee, verwijderen.
        const tojulis = ntaEntityDataOrg.getListWithEntityId("RESULT-TOJULI");
        for (const tojuli of tojulis) {
            const parents = ntaEntityDataOrg.getParents(tojuli, ['RZ', 'UNIT-RZ']);
            if (parents.length !== 1) { // geen of teveel relaties -> verwijderen
                ntaEntityDataOrg.delete(tojuli.EntityDataId);
                hasChanges = true;
            }
        }
        const gtos = ntaEntityDataOrg.getListWithEntityId("RESULT-GTO");
        for (const gto of gtos) {
            const parents = ntaEntityDataOrg.getParents(gto, ['RZ', 'UNIT-RZ']);
            if (parents.length !== 1) { // geen of teveel relaties -> verwijderen
                ntaEntityDataOrg.delete(gto.EntityDataId);
                hasChanges = true;
            }
        }
        const gtoForms = ntaEntityDataOrg.getListWithEntityId("RESULT-TOJULI_FORM");
        for (const gtoForm of gtoForms) {
            const parents = ntaEntityDataOrg.getParents(gtoForm, ['GEB', 'UNIT']);
            if (parents.length !== 1) { // geen of teveel relaties -> verwijderen
                ntaEntityDataOrg.delete(gtoForm.EntityDataId);
                hasChanges = true;
            }
        }

        const tailoredAdviceOptions = hasTailoredAdvice() ? [false, true] : [false];

        const basis = ntaEntityDataOrg.getFirstWithEntityId('BASIS') || null;
        const variants = [basis].concat(ntaEntityDataOrg.getListWithEntityId('VARIANT'));

        const maxRetries = 4;
        for (let i = 0; i < maxRetries; i++) {
            try {
                for (const variant of variants) {
                    for (const forTailoredAdvice of tailoredAdviceOptions) {
                        hasChanges = checkResultTOjuliRelationsForParents(variant, forTailoredAdvice) || hasChanges;
                    }
                }
                break; // als er geen fout is opgetreden, verlaten we meteen de loop
            }
            catch (err) {
                if (err instanceof StaleIndexError) {
                    initialize(false); // de resultatensets opnieuw indexeren, en dan deze loop nog een keer uitvoeren
                    if (i >= maxRetries - 1) {
                        $log.warn(`${checkResultTOjuliRelations.name} heeft al ${i}x achter elkaar geïndexeerd!`);
                    }
                } else {
                    throw err;
                }
            }
        }

        if (hasChanges) {
            initialize(false); // de resultatensets opnieuw indexeren
        }
    } //-- end: checkResultTOjuliRelations --------------------------------------------------------

    function checkResultTOjuliRelationsForParents(variant, tailoredAdvice) {
        let hasChanges = false;

        const { parents, resultEntdatasByEntityId } = getForParents(null, variant, tailoredAdvice, ['RESULT-TOJULI', 'RESULT-GTO', 'RESULT-TOJULI_FORM', 'RESULT-LSTRM']);

        const parentRels = parents
            .filter(parent => parent.EntityId !== 'GEB' && parent.EntityId !== 'UNIT')
            .map(parent => ({
                "OnCopy": 1, "OnDelete": 1, "Parent": parent.EntityDataId, "ParentEntityId": parent.EntityId,
            }));

        const parentZones = ntaEntityDataOrg.getListWithEntityId('RZ')
            .filter(rz => rz.PropertyDatas['RZ_TYPEZ'].Value === 'RZ') // voor gehele gebouw resultaten heeft elke RZ een TOjuli en GTO resultaatbestand
            .filter(rz => ntaEntityDataOrg.getChildren(rz, 'UNIT-RZ').length) //check of RZ een relatie heeft met een UNIT-RZ -> anders geen result aanmaken
            .concat(ntaEntityDataOrg.getListWithEntityId("UNIT-RZ")) // voor resultaten per unit heeft elke UNIT-RZ een TOjuli en GTO resultaatbestand

        for (const parentZone of parentZones) { // RZ of UNIT-RZ
            const parentRelsZone = parentRels.concat({ "OnCopy": 1, "OnDelete": 1, "Parent": parentZone.EntityDataId, "ParentEntityId": parentZone.EntityId });

            const zoneToJulis = ntaEntityDataOrg.getChildren(parentZone, 'RESULT-TOJULI');
            const tojulis = zoneToJulis.intersection(resultEntdatasByEntityId.get('RESULT-TOJULI') || []);
            if (tojulis.length === 0) { // geen resultaat-bestand -> aanmaken
                // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                if (zoneToJulis.some(ed => [parentZone, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                    throw new StaleIndexError();
                }
                ntaEntityDataOrg.create('RESULT-TOJULI', -1, parentRelsZone, [], []);
                hasChanges = true;
            } else if (tojulis.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                tojulis.slice(1).forEach(tojuli => ntaEntityDataOrg.delete(tojuli.EntityDataId));
                hasChanges = true;
            }

            const zoneGtos = ntaEntityDataOrg.getChildren(parentZone, 'RESULT-GTO');
            const gtos = zoneGtos.intersection(resultEntdatasByEntityId.get('RESULT-GTO') || []);
            if (gtos.length === 0) { // geen resultaat-bestand -> aanmaken
                // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                if (zoneGtos.some(ed => [parentZone, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                    throw new StaleIndexError();
                }
                ntaEntityDataOrg.create('RESULT-GTO', -1, parentRelsZone, [], []);
                hasChanges = true;
            } else if (gtos.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                gtos.slice(1).forEach(gto => ntaEntityDataOrg.delete(gto.EntityDataId));
                hasChanges = true;
            }

            const zoneLstrms = ntaEntityDataOrg.getChildren(parentZone, 'RESULT-LSTRM');
            const luchtstromen = zoneLstrms.intersection(resultEntdatasByEntityId.get('RESULT-LSTRM') || []);
            if (luchtstromen.length === 0) { // geen resultaat-bestand -> aanmaken
                // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                if (zoneLstrms.some(ed => [parentZone, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                    throw new StaleIndexError();
                }
                ntaEntityDataOrg.create('RESULT-LSTRM', -1, parentRelsZone, [], []);
                hasChanges = true;
            } else if (luchtstromen.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                luchtstromen.slice(1).forEach(luchtstroom => ntaEntityDataOrg.delete(luchtstroom.EntityDataId));
                hasChanges = true;
            }
        }

        // GTO-Form resultaten voor elke unit en geb. Dit moet alleen gecheckt worden in le3-2 versies
        if (ntaData.ntaVersion.ntaVersionId < 300) {
            const units = ntaEntityDataOrg.getListWithEntityId('GEB').concat(ntaEntityDataOrg.getListWithEntityId('UNIT'));
            for (const unit of units) {
                const parentRelsUnit = parentRels.concat({ "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId });

                const unitGtoForms = ntaEntityDataOrg.getChildren(unit, 'RESULT-TOJULI_FORM');
                const gtoForms = unitGtoForms.intersection(resultEntdatasByEntityId.get('RESULT-TOJULI_FORM') || []);
                if (gtoForms.length === 0) { // geen resultaat-bestand -> aanmaken
                    // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                    if (unitGtoForms.some(ed => [unit, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                        throw new StaleIndexError();
                    }
                    ntaEntityDataOrg.create('RESULT-TOJULI_FORM', -1, parentRelsUnit, [], []);
                    hasChanges = true;
                } else if (gtoForms.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                    gtoForms.slice(1).forEach(gtoForm => ntaEntityDataOrg.delete(gtoForm.EntityDataId));
                    hasChanges = true;
                }
            }
        }

        return hasChanges;
    } //-- end: checkResultTOjuliRelationsForParents ----------------------------------------------

    function checkResultTOjuliRelationsForUnitRz(unitRz, unit = ntaEntityDataOrg.getFirstParent(unitRz, 'UNIT')) {
        if (ntaData.current !== ntaData.original) return; // dit is alleen nodig in de ntaData.original.

        let hasChanges = false;

        //eerst bestaande tojuli en gto langslopen om te checken of ze een 1 op 1 relatie hebben met rz of unit-rz => zo nee, verwijderen.
        const tojulis = ntaEntityDataOrg.getChildren(unitRz, "RESULT-TOJULI");
        for (const tojuli of tojulis) {
            const parents = ntaEntityDataOrg.getParents(tojuli, ['RZ', 'UNIT-RZ']);
            if (parents.length !== 1) { // geen of teveel relaties -> verwijderen
                ntaEntityDataOrg.delete(tojuli.EntityDataId);
                hasChanges = true;
            }
        }
        const gtos = ntaEntityDataOrg.getChildren(unitRz, "RESULT-GTO");
        for (const gto of gtos) {
            const parents = ntaEntityDataOrg.getParents(gto, ['RZ', 'UNIT-RZ']);
            if (parents.length !== 1) { // geen of teveel relaties -> verwijderen
                ntaEntityDataOrg.delete(gto.EntityDataId);
                hasChanges = true;
            }
        }
        const gtoForms = ntaEntityDataOrg.getChildren(unit, "RESULT-TOJULI_FORM");
        for (const gtoForm of gtoForms) {
            const parents = ntaEntityDataOrg.getParents(gtoForm, ['GEB', 'UNIT']);
            if (parents.length !== 1) { // geen of teveel relaties -> verwijderen
                ntaEntityDataOrg.delete(gtoForm.EntityDataId);
                hasChanges = true;
            }
        }

        const tailoredAdviceOptions = hasTailoredAdvice() ? [false, true] : [false];

        const basis = ntaEntityDataOrg.getFirstWithEntityId('BASIS') || null;
        const variants = [basis].concat(ntaEntityDataOrg.getListWithEntityId('VARIANT'));

        const maxRetries = 4;
        for (let i = 0; i < maxRetries; i++) {
            try {
                for (const variant of variants) {
                    for (const forTailoredAdvice of tailoredAdviceOptions) {
                        hasChanges = checkResultTOjuliRelationsForUnitRzParents(unitRz, variant, forTailoredAdvice) || hasChanges;
                    }
                }
                break; // als er geen fout is opgetreden, verlaten we meteen de loop
            }
            catch (err) {
                if (err instanceof StaleIndexError) {
                    initialize(false); // de resultatensets opnieuw indexeren, en dan deze loop nog een keer uitvoeren
                    if (i >= maxRetries - 1) {
                        $log.warn(`${checkResultTOjuliRelationsForUnitRz.name} heeft al ${i}x achter elkaar geïndexeerd!`);
                    }
                } else {
                    throw err;
                }
            }
        }

        if (hasChanges) {
            initialize(false); // de resultatensets opnieuw indexeren
        }
    } //-- end: checkResultTOjuliRelationsForUnitRz -----------------------------------------------

    function checkResultTOjuliRelationsForUnitRzParents(unitRz, variant, tailoredAdvice) {
        let hasChanges = false;

        const { parents, resultEntdatasByEntityId } = getForParents(null, variant, tailoredAdvice, ['RESULT-TOJULI', 'RESULT-GTO', 'RESULT-TOJULI_FORM', 'RESULT-LSTRM']);

        const parentRels = parents
            .filter(parent => parent.EntityId !== 'GEB' && parent.EntityId !== 'UNIT')
            .map(parent => ({
                "OnCopy": 1, "OnDelete": 1, "Parent": parent.EntityDataId, "ParentEntityId": parent.EntityId,
            }));

        for (const parentZone of [unitRz]) { // RZ of UNIT-RZ
            const parentRelsZone = parentRels.concat({ "OnCopy": 1, "OnDelete": 1, "Parent": parentZone.EntityDataId, "ParentEntityId": parentZone.EntityId });

            const zoneToJulis = ntaEntityDataOrg.getChildren(parentZone, 'RESULT-TOJULI')
            const tojulis = zoneToJulis.intersection(resultEntdatasByEntityId.get('RESULT-TOJULI') || []);
            if (tojulis.length === 0) { // geen resultaat-bestand -> aanmaken
                // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                if (zoneToJulis.some(ed => [parentZone, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                    throw new StaleIndexError();
                }
                ntaEntityDataOrg.create('RESULT-TOJULI', -1, parentRelsZone, [], []);
                hasChanges = true;
            } else if (tojulis.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                tojulis.slice(1).forEach(tojuli => ntaEntityDataOrg.delete(tojuli.EntityDataId));
                hasChanges = true;
            }

            const zoneGtos = ntaEntityDataOrg.getChildren(parentZone, 'RESULT-GTO');
            const gtos = zoneGtos.intersection(resultEntdatasByEntityId.get('RESULT-GTO') || []);
            if (gtos.length === 0) { // geen resultaat-bestand -> aanmaken
                // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                if (zoneGtos.some(ed => [parentZone, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                    throw new StaleIndexError();
                }
                ntaEntityDataOrg.create('RESULT-GTO', -1, parentRelsZone, [], []);
                hasChanges = true;
            } else if (gtos.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                gtos.slice(1).forEach(gto => ntaEntityDataOrg.delete(gto.EntityDataId));
                hasChanges = true;
            }

            const zoneLstrms = ntaEntityDataOrg.getChildren(parentZone, 'RESULT-LSTRM');
            const luchtstromen = zoneLstrms.intersection(resultEntdatasByEntityId.get('RESULT-LSTRM') || []);
            if (luchtstromen.length === 0) { // geen resultaat-bestand -> aanmaken
                // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                if (zoneLstrms.some(ed => [parentZone, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                    throw new StaleIndexError();
                }
                ntaEntityDataOrg.create('RESULT-LSTRM', -1, parentRelsZone, [], []);
                hasChanges = true;
            } else if (luchtstromen.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                luchtstromen.slice(1).forEach(luchtstroom => ntaEntityDataOrg.delete(luchtstroom.EntityDataId));
                hasChanges = true;
            }
        }

        // GTO-Form resultaten voor elke unit en geb. Dit moet alleen gecheckt worden in le3-2 versies
        if (ntaData.ntaVersion.ntaVersionId < 300) {
            for (const unit of ntaEntityDataOrg.getParents(unitRz, 'UNIT')) {
                const parentRelsUnit = parentRels.concat({ "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId });

                const unitGtoForms = ntaEntityDataOrg.getChildren(unit, 'RESULT-TOJULI_FORM');
                const gtoForms = unitGtoForms.intersection(resultEntdatasByEntityId.get('RESULT-TOJULI_FORM') || []);
                if (gtoForms.length === 0) { // geen resultaat-bestand -> aanmaken
                    // controleren of een van de children alle juiste parents heeft, dat betekent dat de index niet meer bij is.
                    if (unitGtoForms.some(ed => [unit, ...parents].every(parent => ntaEntityDataOrg.isRelation(parent, ed)))) {
                        throw new StaleIndexError();
                    }
                    ntaEntityDataOrg.create('RESULT-TOJULI_FORM', -1, parentRelsUnit, [], []);
                    hasChanges = true;
                } else if (gtoForms.length > 1) { // teveel resultaat-bestanden -> alle behalve de eerste verwijderen
                    gtoForms.slice(1).forEach(gtoForm => ntaEntityDataOrg.delete(gtoForm.EntityDataId));
                    hasChanges = true;
                }
            }
        }

        return hasChanges;
    } //-- end: checkResultTOjuliRelationsForUnitRzParents ----------------------------------------

    function createResultPvEntity(...parents) {
        if (ntaData.current !== ntaData.original) return false; // dit is alleen nodig in de ntaData.original.

        const parentRels = parents.filter(parent => parent) // filter null en undefined eruit
            .map(parent => ({
                "OnCopy": 1, "OnDelete": 1, "Parent": parent.EntityDataId, "ParentEntityId": parent.EntityId,
            }));
        return ntaEntityDataOrg.create('RESULT-PV', -1, parentRels, [], []);
    } //-- end: createResultPvEntity --------------------------------------------------------------

    function setUnitResultRelevancy(units = ntaEntityDataOrg.getListWithEntityId('UNIT')) {
        const resultEntdatas = units.flatMap(unit => ntaEntityDataOrg.getChildRelations(unit))
            .filter(rel => rel.ChildEntityId === 'PRESTATIE' || rel.ChildEntityId.startsWith('RESULT-'))
            .map(rel => ntaEntityDataOrg.get(rel.Child))
            .filter(ed => ed);
        ntaEntityDataOrg.setEntityRelevancy(resultEntdatas, shouldCalculateUnits());
    } //-- end: setResultRelevancy ----------------------------------------------------------------

    function shouldCalculate() {
        let calculateBuilding, calculateUnits;
        // Bij een los appartement of een unit in utiliteitsgebouw moet er niet gerekend worden op gebouwniveau, maar alleen op unitniveau.
        // Voor projectwoningen geldt hetzelfde.
        const gebouwtype = ntaEntityDataOrg.getFirstWithEntityId('GEB').PropertyDatas['GEB_TYPEGEB'].Value;
        const calcUnit = ntaEntityDataOrg.getFirstWithEntityId('RZFORM').PropertyDatas['RZFORM_CALCUNIT'].Value;
        if (["TGEB_APP", "TGEB_UTILUNIT"].includes(gebouwtype) || calcUnit === "RZUNIT_PROJECT") {
            calculateBuilding = false;
            calculateUnits = true;
        } else {
            calculateBuilding = true;
            calculateUnits = (gebouwtype === "TGEB_APPGEB" && calcUnit === "RZUNIT_GEBAPP")
                || (gebouwtype === "TGEB_UTILIT" && calcUnit === "RZUNIT_GEBUNIT");
        }
        return { calculateBuilding, calculateUnits };
    } //-- end: shouldCalculate -------------------------------------------------------------------

    function shouldCalculateUnits() {
        return shouldCalculate().calculateUnits;
    } //-- end: shouldCalculateUnits --------------------------------------------------------------

    function updateForParents(gebOrUnit = null, variant = null, forTailoredAdvice = null) {
        // Voorlopig initialiseren we de hele boel opnieuw. Optimaliseren indien nodig.
        initialize(true);
    } //-- end: updateForParents ------------------------------------------------------------------

    function updateForDeletedParent(parent) {
        switch (parent.EntityId) {
            case 'RZ':
            case 'UNIT-RZ':
                // dat wordt erg complex; daarom initialiseren we voorlopig maar de hele boel opnieuw.
                initialize(true);
                return;
        }

        const keysToDelete = [];
        for (const [key, dataset] of _resultDatasets) {
            if (dataset.parents.includes(parent)) {
                keysToDelete.push(key);
            }
        }
        for (const key of keysToDelete) {
            _resultDatasets.delete(key);
        }

        for (const parents of [_resultDatasets.units, _resultDatasets.variants, _resultDatasets.standards]) {
            if (!parents) continue;
            let index = parents.indexOf(parent);
            while (index > -1) {
                parents.splice(index, 1);
                index = parents.indexOf(parent, index);
            }
        }
    } //-- end: updateForDeletedParent ------------------------------------------------------------

    // https://stackoverflow.com/a/29018745/3092116
    function binarySearch(ar, el, compare_fn) {
        let m = 0;
        let n = ar.length - 1;
        while (m <= n) {
            const k = (n + m) >> 1;
            const cmp = compare_fn(el, ar[k]);
            if (cmp > 0) {
                m = k + 1;
            } else if (cmp < 0) {
                n = k - 1;
            } else {
                return k;
            }
        }
        return -m - 1;
    } //-- end: binarySearch ------------------------------------------------------------------

}]);
