﻿var projectModule = angular.module("projectModule", ["ngRoute", "ntaAppInfoModule", "ntaComponents", "progressModule", "settingsModule", "belemmeringModule", "ngAnimate", 'ngMaterial', 'ngMessages', 'ntaLogger', 'ui.sortable', 'chart.js'])
    .config(['$routeProvider', '$locationProvider', '$mdThemingProvider', '$mdDateLocaleProvider', 'ChartJsProvider', 'ntaAppInfoProvider',
function     ($routeProvider,   $locationProvider,   $mdThemingProvider,   $mdDateLocaleProvider,   ChartJsProvider,   ntaAppInfoProvider) {
    'use strict';

    const cacheBuster = '?v=' + encodeURIComponent(ntaAppInfoProvider.commit);
    $routeProvider.when('/Project/:id/Berekening/:id2/Algemene_gegevens',                       { templateUrl: '/src/app/templates/buildingattributes.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Indeling_gebouw',                         { templateUrl: '/src/app/templates/buildingcalczones.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Bouwkundige_bibliotheek',                 { templateUrl: '/src/app/templates/buildinglibrary.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Luchtdoorlaten',                          { templateUrl: '/src/app/templates/buildingluchtdoorlaten.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Begrenzing/:id3/',                        { templateUrl: '/src/app/templates/buildingbegrenzing.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Constructies/:id3/',                      { templateUrl: '/src/app/templates/buildingconstructies.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installaties',                            { templateUrl: '/src/app/templates/installationdefine.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_pvsys/:id3/',            { templateUrl: '/src/app/templates/installationpvt.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_pv/:id3/',               { templateUrl: '/src/app/templates/installationpv.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_windt/:id3/',            { templateUrl: '/src/app/templates/installationwindturbine.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_vent/:id3/',             { templateUrl: '/src/app/templates/installationventilatie.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_verw/:id3/',             { templateUrl: '/src/app/templates/installationverwarming.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_koel/:id3/',             { templateUrl: '/src/app/templates/installationkoeling.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_zonnb/:id3/',            { templateUrl: '/src/app/templates/installationzonnb.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_verl/:id3/',             { templateUrl: '/src/app/templates/installationverlichting.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_tapw/:id3/',             { templateUrl: '/src/app/templates/installationtapwater.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Installatie/inst_bev/:id3/',              { templateUrl: '/src/app/templates/installationbevochtiging.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Overzicht',                               { templateUrl: '/src/app/templates/buildingresultsoverzicht.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Energieprestatie',                        { templateUrl: '/src/app/templates/buildingresults.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/TOjuli',                                  { templateUrl: '/src/app/templates/buildingresultstojuli.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Gebruikersprofiel',                       { templateUrl: '/src/app/templates/gebruikersprofiel.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Energierekening',                         { templateUrl: '/src/app/templates/energierekening.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen',                             { templateUrl: '/src/app/templates/measures.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId',                   { templateUrl: '/src/app/templates/measure.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_vent/:id3',    { templateUrl: '/src/app/templates/installationventilatie.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_verw/:id3',    { templateUrl: '/src/app/templates/installationverwarming.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_koel/:id3',    { templateUrl: '/src/app/templates/installationkoeling.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_zonnb/:id3',   { templateUrl: '/src/app/templates/installationzonnb.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_verl/:id3',    { templateUrl: '/src/app/templates/installationverlichting.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_tapw/:id3',    { templateUrl: '/src/app/templates/installationtapwater.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_bev/:id3',     { templateUrl: '/src/app/templates/installationbevochtiging.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Maatregelen/:shadowId/inst_pv/:id3',      { templateUrl: '/src/app/templates/installationpv.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Varianten',                               { templateUrl: '/src/app/templates/variants.html' + cacheBuster });
    $routeProvider.when('/Project/:id/Berekening/:id2/Varianten/:shadowId',                     { templateUrl: '/src/app/templates/variant.html' + cacheBuster });

    $locationProvider.html5Mode(true);

    $mdThemingProvider.theme('default')
        .primaryPalette('light-green')
        .accentPalette('orange');

    moment.locale('nl');
    $mdDateLocaleProvider.months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    $mdDateLocaleProvider.shortMonths = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
    $mdDateLocaleProvider.formatDate = function (date) {
        if (!date) {
            return '';
        } else {
            return moment(date).format('DD-MM-YYYY');
        }

    };
    $mdDateLocaleProvider.parseDate = function (dateString) {
        const m = moment(dateString, 'DD-MM-YYYY', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
    };

    ChartJsProvider.setOptions('pie', {
        tooltips: { enabled: false }
    });
    ChartJsProvider.setOptions('pie', {
        events: []
    });


}]);


projectModule.factory('projecttree', ['ntaData', '$http', '$mdDialog', '$log', function (ntaData, $http, $mdDialog, $log) {
    return {
        projectnaam: ntaData.projectdata && ntaData.projectdata.Name,
        berekeningen: ntaData.projecttree && ntaData.projecttree.Gebouwberekeningen,
        buildingid: ntaData.buildingId,
        expanded: {
            buildingcalczones: true,
            installations: true,
            measures: true,
            variants: true,
        },
        // methods
        showVariants,
        showCosts,
        downloadFile,
    };

    function showVariants() {
        const settings = ntaData.original.getFirstWithEntityId('SETTINGS');
        const propdataVarianten = settings.PropertyDatas['SETTINGS_VARIANTEN'];
        return !!propdataVarianten && propdataVarianten.Value === 'True';
    } //-- end: showVariants ----------------------------------------------------------------------

    function showCosts() {
        // toon indien bij instellingen gekozen voor 'maatwerk advies'
        const settings = ntaData.original.getFirstWithEntityId('SETTINGS');
        const propdataMaatadvies = settings && settings.PropertyDatas['SETTINGS_MAATADVIES'];
        return !!propdataMaatadvies && propdataMaatadvies.Value === 'True';
    } //-- end: showCosts -------------------------------------------------------------------------

    async function downloadFile(url, defaultFilename = null,  postData = null) {
        try {

            const response = postData ? await $http.post(url, postData, { responseType: 'arraybuffer' }) : await $http.get(url, { responseType: 'arraybuffer' });
            const contentType = response.headers()['content-type'] || 'application/octet-stream';
            const file = new Blob([response.data], { type: contentType + ';base64' });
            const fileURL = window.URL.createObjectURL(file);
            try {
                const match = /filename[^;=\n]*=((['"])(.*?)\2|([^;\n]*))/.exec(response.headers()['content-disposition']);
                const filename = match && (match[3] || match[4]) || defaultFilename;
                const link = document.createElement("a");
                link.target = "_blank";
                link.style = "display: none";
                link.href = fileURL;
                link.download = filename;
                link.click();
                return true;
            } finally {
                setTimeout(() => URL.revokeObjectURL(fileURL), 30000);
            }
        } catch (err) {
            let title = 'Download mislukt';
            let messageText = 'Neem contact op met de helpdesk van Uniec 3.';
            if (err.data) {
                try {
                    const decodedString = String.fromCharCode.apply(null, new Uint8Array(err.data));
                    const reply = JSON.parse(decodedString);
                    const message = reply && reply.error;
                    const warning = ntaData.warnings.find(w => w.Id === message);
                    if (warning) {
                        title = warning.FilterValue1 || title;
                        messageText = warning.Value || messageText;
                    } else {
                        messageText = message + '\r\n\r\n' + messageText;
                    }
                } catch (ignoreErr) {
                    // als dit ook niet goed ging, negeren (bv. als de data geen JSON of zelfs tekst bevat)
                    $log.error(err.data);
                }
            } else {
                $log.error(err);
            }
            $mdDialog.show($mdDialog.alert({
                title: title,
                textContent: messageText,
                ok: "Sluiten",
            }));
            return false;
        }
    }



}]);


projectModule.factory('ntabuilding',
        ['$rootScope', 'ntaData', 'settingsMenuData', 'resultsMenuData', '$log', '$mdDialog', '$mdSidenav', 'projecttree', 'ntaMeldingen', 'ntaLocal', 'ntaStorage', 'baseNtaEntityData', 'ntaAlert', 'ntaBuildingNotification', 'PropertyData', 'ntaDeltas', 'time', 'ntaCalcValidation', 'NtaEntityDataFactory',
function ($rootScope,   ntaData,   settingsMenuData,   resultsMenuData,   $log,   $mdDialog,   $mdSidenav,   projecttree,   ntaMeldingen,   ntaLocal,   ntaStorage,   ntaEntityData,       ntaAlert,   ntaBuildingNotification,   PropertyData,   ntaDeltas,   time,   ntaCalcValidation,   NtaEntityDataFactory) {
    'use strict';

    const factory = {};
    factory.ntaDependencyValidation;    // wordt door ntaDependencyValidation zelf geïnjecteerd
    factory.ntaSharedLogic;             // wordt door ntaSharedLogic zelf geïnjecteerd
    factory.ntaDiagram;                 // wordt door ntaDiagram zelf geïnjecteerd

    factory.projectId = ntaData.projectdata.ProjectId;
    factory.errors = ntaData.errors;
    factory.warnings = ntaData.warnings;
    factory.commit = ntaData.commit;

    factory.startBerekening = startBerekening;

    factory.buildingId = 0;
    factory.results = [];

    factory.changeBuilding = function (newBuildingId) {
        const oldBuildingId = ntaData.buildingId;

        //evt. openstaand diagramWindow sluiten.
        if (factory.ntaDiagram) {
            factory.ntaDiagram.closeWindow();
        }

        // Change building
        ntaData.unlockBuilding(newBuildingId);
        ntaData.buildingId = newBuildingId;

        factory.buildingId = ntaData.buildingId;
        factory.entities = ntaData.entities;
        factory.properties = ntaData.properties;
        factory.ntaVersionId = ntaData.ntaVersion.ntaVersionId;
        factory.appVersionId =  ntaData.ntaVersion.appVersionId;

        ntaMeldingen.meldingen = [];
        factory.results = ntaData.results.filter(ed => ed.BuildingId === ntaData.buildingId);
        factory.buildingWarnings = getBuildingWarnings();

        const settings = ntaData.original.getFirstWithEntityId("SETTINGS", newBuildingId);
        settingsMenuData.setCurrentSettings(settings, ntaData.properties["SETTINGS"]);

        resultsMenuData.setBuildingId(ntaData.buildingId);
        resultsMenuData.setProjectId(ntaData.projectdata.ProjectId);

        ntaBuildingNotification.notifyBuildingOpened(newBuildingId);

        buildingLoaded(oldBuildingId);

        return factory;
    };

    factory.canSave = function () {
        return ntaData.canSaveBuilding(factory.buildingId);
    };

    // Als er een error doorkomt via SignalR, dan alleen een foutmelding weergeven als het om het actieve gebouw gaat.
    ntaStorage.on('updateError', (buildingId, message, processedIds) => {
        if (processedIds.includes(ntaData.getBuildingCalculationId(buildingId))) {
            ntaData.setBuildingCalculationId(buildingId, null);
            const ntaEntityDataOriginal = new NtaEntityDataFactory();
            ntaEntityDataOriginal.setCalculationNeeded(buildingId, true);
        }
        if (buildingId === factory.buildingId) {
            ntaAlert.showError();
        }
    });

    // Initialiseer ntabuilding met de juiste gebouwgegevens
    factory.changeBuilding(ntaData.buildingId);

    return factory;

    function getBuildingWarnings() {
        return ntaData.buildingWarnings || [];
    }

    async function buildingLoaded(prevBuildingId) {
        const projectsButton = document.getElementById('showProjectsIndex');
        if (projectsButton) {
            projectsButton.addEventListener('click', showProjects);
        }

        const buildingId = factory.buildingId;

        if (!await checkLicenseModules(buildingId, prevBuildingId))
            return;

        if (ntaData.needsReload) {
            // de server was nog wijzigingen aan het opslaan bij het laden van deze pagina
            $log.warn('needs reload:', buildingId, ntaData.needsReload);
            try {
                const buildingOption = await waitForElement(`md-option[value="${buildingId}"]`, 15000);
                if (buildingOption) {
                    // haal het gebouw op, en de ProjectTreeController
                    const gebouw = angular.element(buildingOption).scope().gebouw;
                    // zoek de controller die `setActiveBuilding` heeft; dat is de ProjectTreeController.
                    const projectTreeController = Array.from(document.querySelectorAll('div[ng-controller]'))
                        .map(element => angular.element(element).controller())
                        .find(ctrl => ctrl.setActiveBuilding);
                    if (gebouw && projectTreeController) {
                        await projectTreeController.setActiveBuilding(gebouw);
                        return;
                    }
                }
            } catch (err) {
                $log.error(`Reload van berekening ${buildingId} mislukt: `, err);
            }
            location.reload();
        } else {
            // Dit gebouw wordt niet doorgerekend
            ntaData.setBuildingCalculationId(buildingId, null);

            // Verwerk openstaande client-wijzigingen indien nodig
            if (await ntaLocal.hasUpdates(buildingId)) {
                await ntaEntityData.processLocalUpdates(buildingId);
            }

            // Data controleren
            const fixes = ntaData.original.checkData(PropertyData);

            // als er propdatas of relaties gefixt zijn, moeten we dat nog doorvoeren
            try {
                processFixes('savePropertyData', fixes.propdatasToSave);
                processFixes('addEntityData', fixes.entdatasToSave);
                processFixes('addRelationData', fixes.reldatasToSave);
                processFixes('deletePropertyData', fixes.propdatasToDelete);
                processFixes('deleteEntityData', fixes.entdatasToDelete);
                processFixes('deleteRelationData', fixes.reldatasToDelete);
                processFixes('deleteDeltas', unloadObsoleteDeltas());
            } catch (err) {
                $log.error(err);
            }

            // De resultaten worden uitgegrijsd als er meldingen zijn
            const calculationNeeded = ntaEntityData.getCalculationNeeded(buildingId) || ntaMeldingen.getMeldingen().length > 0;
            ntaEntityData.setCalculationNeeded(buildingId, calculationNeeded);

            // client-side opslag opruimen (niet afwachten)
            ntaLocal.clearExpiredUpdates();
        }
    } //-- end: buildingLoaded --------------------------------------------------------------------

    async function checkLicenseModules(buildingId, prevBuildingId) {
        // Controleer of er sprake is van varianten of maatwerkadvies. Zo ja, controleer dan de licentiemodules.
        const settings = ntaEntityData.getFirstWithEntityId('SETTINGS');
        const propdataVarianten = settings.PropertyDatas.SETTINGS_VARIANTEN;
        const heeftVarianten = !!propdataVarianten && String(propdataVarianten.Value).toLowerCase() === 'true';
        const propdataMwa = settings.PropertyDatas.SETTINGS_MAATADVIES;
        const isMaatwerkadvies = !!propdataMwa && String(propdataMwa.Value).toLowerCase() === 'true';
        if (heeftVarianten || isMaatwerkadvies) {
            const isUtiliteit = ['TGEB_UTILIT', 'TGEB_UTILUNIT'].includes(ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas.GEB_TYPEGEB.Value);
            const module = isUtiliteit ? 'nonResidential.bespoke' : 'residential.bespoke';
            if (!ntaData.modules.includes(module)) {
                // Als er geen geldige module voor is, vraag dan of de berekening alsnog
                //  geopend moet worden ([W130]/[W131]).
                const warningCode = isUtiliteit ? '[W131]' : '[W130]';
                if (await ntaMeldingen.warning(warningCode)) {
                    // Zo ja, zet dan beide instellingen uit;
                    ntaEntityData.saveprops([propdataVarianten, propdataMwa], 'False');
                } else {
                    // Zo nee, ga dan terug naar de vorige berekening.
                    let url;
                    if (prevBuildingId && prevBuildingId !== buildingId) {
                        url = location.href.replace(/(\/Berekening\/)(\d+)(\/.*)?$/, (match, prefix, buildingId, suffix) => prefix + prevBuildingId + suffix);
                    } else {
                        url = '/Projects';
                    }
                    await ntaStorage.whenDoneSaving(() => {
                        location.assign(url);
                    }, { title: 'laden', timeoutMS: 4 * 60 * 1000, leaveProgress: true });
                    return false;
                }
            }
        }
        return true;
    }

    function showProjects(event) {
        event.preventDefault();
        event.stopPropagation();

        if (factory.ntaDiagram) {
            factory.ntaDiagram.closeWindow(); //evt. openstaand diagramWindow sluiten.
        }

        const projectsButton = event.target;
        return ntaStorage.whenDoneSaving(() => {
            location.assign(projectsButton.href);
        }, { title: 'projectenoverzicht laden', timeoutMS: 4 * 60 * 1000, leaveProgress: true });
    } //-- end: showProjects ----------------------------------------------------------------------

    function processFixes(action, datas) {
        try {
            if (datas.length > 0) {
                const datasToSave = datas.splice(0); // leegt de oorspronkelijke lijst
                const buildingIds = new Set(datasToSave.map(data => data.BuildingId));
                for (const buildingId of buildingIds) {
                    let data = datasToSave.filter(data => data.BuildingId === buildingId);
                    if (action === 'addEntityData') {
                        data = { newEntdata: data, newrelations: [] };
                    } else if (action === 'deleteEntityData') {
                        data = { entitydatas: data, relationdatas: [] };
                    }
                    ntaEntityData.updateData(buildingId, action, data);
                }
            }
        } catch (err) {
            $log.error(err);
        }
    } //-- end: processFixes ----------------------------------------------------------------------

    function unloadObsoleteDeltas() {
        const deltasToDelete = [];

        for (const [shadowId, deltasById] of ntaData.deltas) {
            const shadowEntdata = ntaData.original.get(shadowId);
            if (shadowEntdata && shadowEntdata.BuildingId === factory.buildingId) {
                const [_, __, obsoleteDeltas] = ntaDeltas.apply(deltasById.values(), ntaData.original.clone());
                if (obsoleteDeltas && obsoleteDeltas.length) {
                    const deletedDeltas = new Set(obsoleteDeltas);
                    for (const [id, delta] of deltasById) {
                        if (deletedDeltas.has(delta)) {
                            deltasById.delete(id);
                            deltasToDelete.push(delta);
                        }
                    }
                }
            }
        }

        return deltasToDelete;
    } //-- end: unloadObsoleteDeltas --------------------------------------------------------------

    async function startBerekening() {
        ntaData.switching = true;
        try {
            // geef angular de gelegenheid om het scherm ‘berekening valideren’ weer te geven dat hoort bij ntaData.switching...
            await time.delay(100);

            //-- huidige opslaan en switchen naar de originele berekening
            const currentShadowId = ntaData.current.shadowId;
            await switchToShadow(null);

            // de originele berekening valideren
            validateCurrent();

            // wacht eerst tot alle eerdere validaties zijn afgerond
            await time.whenDelayedActionsDone();

            // verwijder dan evt. overbodige meldingen
            ntaMeldingen.deleteObsolete(ntaData.original);

            // controleer dan dat de berekening zelf geen fouten meer bevat. Zo wel, dan hoeven we de maatregelen en varianten niet eens te controleren
            const shouldValidateMeasures = projecttree.showVariants()
                && ntaMeldingen.getMeldingen().every(ed => ed.PropertyDatas['MELD_SHADOWID'] && ed.PropertyDatas['MELD_SHADOWID'].Value);

            // Valideer dan alle gebruikte maatregelen (die met delta’s werken)
            if (shouldValidateMeasures) {
                const measures = ntaEntityData.getListWithEntityId('MEASURE', factory.buildingId)
                    .filter(m => m.Relevant && ntaEntityData.findEntities(m, 'VARIANT-MEASURE.^VARIANT', 'VARIANT').some(v => v.Relevant));
                for (const measure of measures) {
                    const logic = factory.ntaDependencyValidation.getMeasureLogic(measure);
                    if (logic && !logic.generateDeltas) {
                        // Alleen dan hoeven we het resultaat van deze maatregel te valideren;
                        //  als de logic wel generateDeltas ondersteunt, dan vindt de validatie
                        //  al plaats in de basisberekening.
                        const measureId = measure.EntityDataId;

                        await switchToShadow(measureId);

                        //// De schaduwkopie valideren.
                        ntaData.suspendSavingDeltas = true;
                        try {
                            validateCurrent();

                            // Wacht tot alle validaties klaar zijn
                            await time.whenDelayedActionsDone();
                        } finally {
                            ntaData.suspendSavingDeltas = false;
                        }

                        // Verwijder alle overbodige meldingen van deze maatregel
                        ntaMeldingen.deleteObsolete(ntaData.current);
                    }
                }
            }

            const allVariantDeltas = [];

            // controleer dan dat de basisberekening en maatregelen geen fouten meer bevatten. Zo wel, dan hoeven we de varianten niet eens te controleren
            const shouldProcessVariants = projecttree.showVariants()
                && !ntaMeldingen.getMeldingen()
                .some(ed => {
                    const shadowId = ed.PropertyDatas['MELD_SHADOWID'] && ed.PropertyDatas['MELD_SHADOWID'].Value;
                    const shadowEntdata = ntaData.original.get(shadowId);
                    return !shadowEntdata || shadowEntdata.EntityId !== 'VARIANT';
                });
            if (shouldProcessVariants) {
                const varianten = ntaEntityData.getListWithEntityId('VARIANT', factory.buildingId);

                // Ruim evt. oude/achterhaalde delta’s van de varianten op
                for (const variant of varianten) {
                    ntaEntityData.deleteDeltas(variant);
                }

                for (const variant of varianten) {
                    // een (schone) schaduwkopie samenstellen voor variant, en daarin de delta’s doorvoeren
                    const variantId = variant.EntityDataId;

                    await switchToShadow(variantId);

                    // genereer delta's per maatregel van variant
                    const measures = ntaEntityData.findEntities(variant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE');
                    for (const measure of measures) {
                        const logic = factory.ntaDependencyValidation.getMeasureLogic(measure);
                        let deltas = [];
                        if (logic && logic.generateDeltas) {
                            deltas = logic.generateDeltas(variantId);
                        } else {
                            const deltasByKey = ntaData.deltas.get(measure.EntityDataId);
                            // Kopieer alle delta’s van deze maatregel, en zet erbij dat ze bij deze variant horen
                            deltas = deltasByKey && [...deltasByKey.values()]
                                .map(delta => Object.assign(Object.create(null), delta, { ShadowId: variantId }))
                                || [];
                        }
                        if (deltas.length) {
                            ntaData.mergeDeltas(variantId, deltas);
                        } else {
                            $log.warn('Geen delta’s gegenereerd of gevonden voor', measure, deltas);
                        }
                    }

                    // deltas ophalen en toepassen. Hou bij welke delta’s overbodig waren (en dus niet opgeslagen hoeven worden)
                    const deltas = (ntaData.deltas.get(variantId) || new Map()).values();
                    const [_, skippedDeltas] = ntaDeltas.apply(deltas, ntaData.shadow);

                    // De schaduwkopie valideren. Let op: dit kan nieuwe delta’s tot gevolg hebben!
                    validateCurrent();

                    // Wacht tot alle validaties (en evt. wijzigingen) klaar zijn
                    await time.whenDelayedActionsDone();

                    // Specifieke validaties doorvoeren voor varianten
                    ntaCalcValidation.validateVariant();

                    // Wacht tot al deze validaties (en evt. wijzigingen) ook klaar zijn
                    await time.whenDelayedActionsDone();

                    // Verwijder alle overbodige meldingen van deze variant
                    ntaMeldingen.deleteObsolete(ntaData.current);

                    // delta’s verzamelen om later op te slaan
                    const deltasToSkip = new Set(skippedDeltas);
                    const deltasToSave = (ntaData.deltas.get(variantId) || new Map()).values();
                    for (const delta of deltasToSave) {
                        if (!deltasToSkip.has(delta)) {
                            allVariantDeltas.push(delta);
                        }
                    }
                }

                // Na alle validaties weer terug switchen naar de basisberekening
                await switchToShadow(null);

                // Verwijder alle overbodige meldingen van de basisberekening
                ntaMeldingen.deleteObsolete(ntaData.original);
            }


            if (ntaMeldingen.getMeldingen().length === 0) {
                //-- pas als we gaan rekenen de juiste resultaatentiteiten zichtbaar maken (of juist niet).
                factory.ntaSharedLogic.markResultsVisible(shouldProcessVariants);

                ntaEntityData.setCalculationNeeded(factory.buildingId, false);
                ntaEntityData.updateData(factory.buildingId, 'calculate', allVariantDeltas);

            } else {
                $mdSidenav('right').open();

                const errorsAlert = $mdDialog.alert()
                    .title('Gebouw kan niet doorgerekend worden')
                    .htmlContent('Er kan niet worden gerekend vóór alle problemen opgelost zijn.<br/>'
                        + 'Zie het overzicht van openstaande problemen aan de rechterzijde.')
                    .ok('Sluiten');
                $mdDialog.show(errorsAlert);
            }

            //-- terug switchen naar de oorspronkelijke shadowId
            await switchToShadow(currentShadowId);

        } catch (err) {
            $log.error('Oeps', `Building ${factory.buildingId}:`, err);
            ntaStorage.showErrorAndReload();
        } finally {
            ntaData.switching = false;
        }
    } //-- end: startBerekening -------------------------------------------------------------------

    async function switchToShadow(shadowId) {
        // wacht eerst tot alle eerdere validaties zijn afgerond,
        await time.whenDelayedActionsDone();
        // en switch dan pas naar de gewenste schaduwomgeving.
        const applyDeltas = ntaData.switchToShadow(shadowId);
        if (applyDeltas) {
            const deltas = (ntaData.deltas.get(shadowId) || new Map()).values();
            ntaDeltas.apply(deltas, ntaData.shadow);
        }
    } //-- end: switchToShadow --------------------------------------------------------------------

    function validateCurrent() {
        factory.ntaDependencyValidation.validateLineaireConstructies();
        //-- valideer alle logics
        factory.ntaDependencyValidation.validateAllLogics();

        //check de resultaatentiteiten voor TO-Juli
        factory.ntaDependencyValidation.checkResultTOjuliRelations();
        const unirzs = ntaEntityData.getListWithEntityId('UNIT-RZ');
        for (const unirz of unirzs) {
            factory.ntaDependencyValidation.checkResultTOjuliRelationsForUnitRz(unirz);
        }

        //-- zorg dat alle unit-specifieke resultaatentiteiten bestaan
        factory.ntaSharedLogic.checkUnitSpecificResultEntities();
        //-- valideer openstaand formulier
        $rootScope.$broadcast('callEndFormValidation');
    } //-- end: validateCurrent -------------------------------------------------------------------


}]);


projectModule.factory('projectattributes', ['ntaData', function (ntaData) {
    return {
        id: ntaData.projectdata.ProjectId,
        name: ntaData.projectdata.Name,
        plaats: ntaData.projectdata.Plaats,
        omschrijving: ntaData.projectdata.Description
    };
}]);

projectModule.factory('berekeningstypes', ['ntaData', function (ntaData) {
    return {
        types: ntaData.projecttree.BerekeningsTypes
    };
}]);

projectModule.factory('gebruikstypes', ['ntaData', function (ntaData) {
    return {
        types: ntaData.projecttree.GebruiksTypes
    };
}]);

projectModule.factory('positietypes', ['ntaData', function (ntaData) {
    return {
        types: ntaData.projecttree.PositieTypes
    };
}]);
