﻿angular.module('projectModule')
    .factory('ResultsTOjuliFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaEntityData', 'ntaResults', 'ntaSharedLogic', 'BuildingResultsFactory',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaEntityData,   ntaResults,   ntaSharedLogic,   BuildingResultsFactory) {
    'use strict';

    // Constantes die voor elke instantie hetzelfde zijn

    return function ResultsTOjuli(ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================

        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;


        //== Instance data ========================================================================

        const resultsLogic = new BuildingResultsFactory();

        const _validateProperties = new Set([
            'RESULT_TOJULI_TYPE_KOEL',
            'RESULT-TOJULI_KOELCAP',
            'RESULT_TOJULI_RISICO',
            'RESULT-TOJULI_AANW_AANV_BER',
            'RESULT-TOJULI_FORM_TEMPMETH',
            'RESULT-TOJULI_FORM_GTO',
        ]);

        const _resultMapsByShadowId = new Map();

        let _actieveBerekening;

        const _isVersionLe32 = ntabuilding.ntaVersionId < 300;
        const _geb = ntaEntityDataOrg.getFirstWithEntityId('GEB');
        const _rzform = ntaEntityDataOrg.getFirstWithEntityId('RZFORM');
        const _gebType = _geb.PropertyDatas["GEB_TYPEGEB"].Value;
        const _calcUnit = _rzform.PropertyDatas["RZFORM_CALCUNIT"].Relevant && _rzform.PropertyDatas["RZFORM_CALCUNIT"].Value;

        //== Exports ==============================================================================

        /// properties
        self.gtoProperties = ntaData.properties['RESULT-GTO'];
        self.tojuliProperties = ntaData.properties['RESULT-TOJULI'].slice() || []; // kopie maken. worden in bij init lege regels toegevoegd
        self.tojuliFormProperties = ntaData.properties['RESULT-TOJULI_FORM'] || [];

        self.berekeningen = resultsLogic.getBerekeningen();
        self.getResult = resultsLogic.getResult;
        self.getRisicoOververhitting = resultsLogic.getRisicoOververhitting;
        self.getForParents = ntaResults.getForParents;
        self.getIcon = resultsLogic.getIcon;

        self.isVersionLe32 = _isVersionLe32;

        self.TOjuliResultaten = () => currentResults().toJuliResultaten;
        self.GTOResultaten = () => currentResults().gtoResultaten;
        self.TOjuliForm = () => currentResults().toJuliForm;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            isGebouw,
            isAppPerGebouw,
            isAppPerAppartement,
            isBerekendPerApp_Unit,
            showToJuliTable,
            showGtoTable,
            conditionG,
            conditionS,
            conditionW,
            setActiveDataAndValidate,
            // standaard methods tbv validatie
            isHidden,
            getCodedValues,
            getListItem,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
        });


        //== Initialization =======================================================================
        ntaResults.checkResultTOjuliRelations();
        _actieveBerekening = self.berekeningen[0]?.Id;

        // De lege regels boven TO24, TO27 en TO28 tussenvoegen
        self.tojuliProperties.forEach(p => self.tojuliProperties[p.Id] = p);
        for (const legeRegel of ['RESULT-TOJULI_WEINIG_RAMEN', 'RESULT-TOJULI_AANW_AANV_BER', 'RESULT_TOJULI_RISICO']) {
            const index = self.tojuliProperties.findIndex(prop => prop && prop.Id === legeRegel);
            if (index > -1) {
                self.tojuliProperties.splice(index, 0, { Id: null, showId: legeRegel });
            }
        }


        //== Implementation =======================================================================

        function currentResults(berekeningId = _actieveBerekening, shadowId = ntaEntityData.getShadowId()) {
            /// Deze functie geeft de resultaten terug voor de opgegeven berekening en variant (dan wel basis).
            /// Als de gevraagde resultatenset nog niet bekend is, wordt deze aangemaakt en gecachet.
            let resultsByBerekeningId = _resultMapsByShadowId.get(shadowId);
            if (!resultsByBerekeningId) {
                resultsByBerekeningId = makeResultsByBerekeningId(shadowId);
                _resultMapsByShadowId.set(shadowId, resultsByBerekeningId);
            }
            return resultsByBerekeningId.get(berekeningId);
        } //-- end: currentResults ----------------------------------------------------------------

        function setActiveDataAndValidate(berekeningId) {
            _actieveBerekening = berekeningId;

            return validateActiveBerekening();
        } //-- end: setActiveDataAndValidate ------------------------------------------------------

        function makeResultsByBerekeningId(shadowId = ntaEntityData.getShadowId()) {
            const resultsByBerekeningId = new Map();

            /// We moeten de resultatenset teruggeven van de huidige variant indien van toepassing.
            /// Maatregelen hebben geen eigen resultatenset; daarvoor geven we dan de
            ///  resultaatentiteiten van de basisberekening.
            const shadowEntdata = ntaEntityDataOrg.get(shadowId);
            const basisOrVariant = shadowEntdata?.EntityId === 'VARIANT'
                ? shadowEntdata
                : ntaEntityDataOrg.getFirstWithEntityId("BASIS");

            const { resultEntdatasByEntityId } = ntaResults.getForParents(null, basisOrVariant, false, ['RESULT-TOJULI', 'RESULT-GTO']);
            const allToJuliResultaten = resultEntdatasByEntityId.get('RESULT-TOJULI') || [];
            const allGtoResultaten = resultEntdatasByEntityId.get('RESULT-GTO') || [];

            /// maak dictionaries per berekening met tojuli entiteiten voor RESULT-TOJULI, RESULT-TOJULI_FORM en RESULT-TOJULI_GTO
            for (const berekening of self.berekeningen) {
                /// check of Id gebouw of unit is
                /// Indien gebouw haal rzs op en daarna de TOjulis/GTOs
                /// Indien unit haal unitrzs op en daarna de TOjulis/GTOs
                const gebOrUnit = ntaEntityDataOrg.get(berekening.Id);
                const rekenzoneIds = gebOrUnit.EntityId === "GEB"
                    ? ntaEntityDataOrg.getListWithEntityId('RZ').map(rz => rz.EntityDataId)
                    : ntaEntityDataOrg.getChildIds(gebOrUnit, "UNIT-RZ");

                const results = {
                    toJuliResultaten: [],
                    gtoResultaten: [],
                    toJuliForm: null,
                };
                results.toJuliResultaten = allToJuliResultaten
                    .filter(ed => rekenzoneIds.intersection(ntaEntityDataOrg.getParentIds(ed)).length);
                results.gtoResultaten = allGtoResultaten
                    .filter(ed => rekenzoneIds.intersection(ntaEntityDataOrg.getParentIds(ed)).length);

                const { resultEntdatasByEntityId } = ntaResults.getForParents(gebOrUnit, basisOrVariant, false, ['RESULT-TOJULI_FORM']);
                results.toJuliForm = (resultEntdatasByEntityId.get('RESULT-TOJULI_FORM') || [])[0];

                resultsByBerekeningId.set(berekening.Id, results);
            }

            return resultsByBerekeningId;
        } //-- end: makeResultsByBerekeningId -----------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;

            /// Resultaatentiteiten moeten altijd opgeslagen worden in de basisberekening, dus geven we ntaEntityDataOrg mee.
            ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function getCodedValues(prop, entdata) {
            let typesList = prop && prop.Domain && prop.Domain.Codes || [];

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'RESULT-TOJULI_AANW_AANV_BER': {
                    //condition [T]
                    if (isAppPerGebouw()) {
                        typesList = typesList.filter(x => x.Id !== 'RESULT-TOJULI_AANW_AANV_BER2');
                    }
                    //condition [M]
                    if (!ntaSharedLogic.isUtiliteit() &&
                        (!ntaSharedLogic.isNieuwbouw() || (entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL'].Value !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE' ||
                                                           entdata.PropertyDatas['RESULT-TOJULI_KOELCAP'].Value !== 'ja'))) {
                        typesList = typesList.filter(x => x.Id !== 'RESULT-TOJULI_AANW_AANV_BER3' && x.Id !== 'RESULT-TOJULI_AANW_AANV_BER4');
                    }
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, self);

            return typesList;
        } //-- end: getCodedValues ----------------------------------------------------------------

        function isHidden(prop, entdata = null) {
            /// als entdata null dan komt het vanuit het formulier en gaat het om het weergeven van de rij
            /// in de tabel
            if (typeof prop === 'string') prop = ntaData.properties[prop];

            let visible = true;
            let relevant = null; // null = zelfde als visible

            switch (prop && prop.Id) {
                case 'RESULT-TOJULI_FORM_GTO':
                    visible = conditionS();
                    break;
                case 'RESULT-TOJULI_FORM_TEMPMETH':
                    visible = conditionL();
                    break;
                case 'RESULT-TOJULI_NOORD':         // TO04
                case 'RESULT-TOJULI_NOORD_OOST':    // TO05
                case 'RESULT-TOJULI_OOST':          // TO06
                case 'RESULT-TOJULI_ZUID_OOST':     // TO07
                case 'RESULT-TOJULI_ZUID':          // TO08
                case 'RESULT-TOJULI_ZUID_WEST':     // TO09
                case 'RESULT-TOJULI_WEST':          // TO10
                case 'RESULT-TOJULI_NOORD_WEST': {  // TO11
                    /// conditie [C]:
                    /// toon alleen de orientaties die voorkomen in het gebouw of de unit (als een oriëntatie in geen enkele rekenzone van het gebouw of de unit voorkomt de rij verbergen)
                    visible = currentResults().toJuliResultaten.some(result => prop.getValue(result));
                    visible = visible && !conditionH();
                    break;
                }
                case 'RESULT-TOJULI_MAX': {         // TO12
                    visible = !conditionH();
                    break;
                }
                case 'RESULT-TOJULI_WEINIG_RAMEN':  // TO24
                case 'RESULT-TOJULI_BEP_ZON': {     // TO25
                    //-- conditie [I] toon alleen in gt3-2
                    visible = ntabuilding.ntaVersionId >= 300 && !ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isNieuwbouw();
                    break;
                }
                case 'RESULT-TOJULI_KOELCAP': {     // TO26
                    //-- conditie [J] toon alleen in gt3-2 EN berekening is WN EN als bij minimaal 1 rekenzone is sprake van een actief koelsysteem
                    const propKoeling = self.tojuliProperties["RESULT_TOJULI_TYPE_KOEL"];
                    visible = ntabuilding.ntaVersionId >= 300
                        && !ntaSharedLogic.isUtiliteit()
                        && ntaSharedLogic.isNieuwbouw()
                        && !!propKoeling
                        && currentResults().toJuliResultaten.some(ed => propKoeling.getValue(ed) === 'RESULT-TOJULI_TYPE_KOEL_ACTIVE');
                    break;
                }
                case 'RESULT-TOJULI_AANW_AANV_BER': {  // TO27
                    visible = ntabuilding.ntaVersionId >= 300; //condition [V]
                    break;
                }
                case 'RESULT_TOJULI_RISICO': {  // TO28 -> De underscore is expres ivm de BuildingSummary
                    visible = !ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isNieuwbouw() && ntabuilding.ntaVersionId >= 300; //condition [N] en [V]
                    break;
                }
                case 'RESULT_TOJULI_TYPE_KOEL': { // specifiek voor opslag actieve koeling. De underscore is expres ivm de BuildingSummary
                    visible = false;
                    break;
                }
                case null: {                     // t.b.v. de lege rijen boven TO24, TO27 en TO28
                    switch (prop.showId) {
                        case 'RESULT-TOJULI_WEINIG_RAMEN': {
                            visible = !isHidden(ntabuilding.properties['RESULT-TOJULI_MAX'])
                                && !isHidden(ntabuilding.properties['RESULT-TOJULI_WEINIG_RAMEN']); // condition [H]
                            break;
                        }
                        case 'RESULT-TOJULI_AANW_AANV_BER':
                        case 'RESULT_TOJULI_RISICO': {
                            visible = ntabuilding.ntaVersionId >= 300; // condition [V]
                            break;
                        }
                        default: {
                            visible = true;
                            break;
                        }
                    }
                    break;
                }
                default: {
                    $log.warn(`Unexpected prop.Id in ResultsTOjuliFactory.isHidden:`, prop);
                    break;
                }

            }

            if (prop && entdata) {
                /// als er wel een entdata is, kan ik de relevantie zetten.
                const propdata = entdata.PropertyDatas[prop.Id];
                if (propdata) {
                    if (relevant === null) relevant = visible;
                    ntaEntityDataOrg.setPropdataStatus(propdata, relevant, visible);
                }
            }

            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function showToJuliTable() {
            /// conditie [A] verberg risico op oververhitting tabel indien:
            ///    - le3.2 EN TO01 ≠ TOjuli conform NTA 8800
            ///    - le3.2 EN G04 = appartementengebouw en Z23 = per gebouw
            ///    - ge3-3 EN G04 = appartementengebouw en Z23 = per gebouw EN bestaande bouw
            ///    - G04 = appartementengebouw en Z23 = per gebouw en per appartement bij de 'gebouw' berekening (eerste tegel)

            var sitA = _isVersionLe32 && currentResults().toJuliForm?.PropertyDatas["RESULT-TOJULI_FORM_TEMPMETH"].Value !== "TEMPMETH_TOJULI";
            var sitB = _isVersionLe32 && isAppPerGebouw();
            var sitC = !_isVersionLe32 && isAppPerGebouw() && !ntaSharedLogic.isNieuwbouw() ;
            var sitD = isAppPerAppartement() && isGebouw();
            var hide = sitA || sitB || sitC || sitD;

            return !hide;
        } //-- end: showToJuliTable ---------------------------------------------------------------

        function showGtoTable() {
            /// [E] toon deze tabel indien:
            ///  - TO01 wordt getoond EN = GTO berekening conform Regeling Bouwbesluit <-- conditie [S]
            ///  - TO27 = 'GTO berekening volgens omgevingsregeling en GTO ≤ 450' bij minimaal 1 rekenzone
            return conditionS()
                || currentResults().toJuliResultaten
                    .map(ed => ed.PropertyDatas["RESULT-TOJULI_AANW_AANV_BER"]) // TO27
                    .some(pd => pd?.Relevant && pd.Value === "RESULT-TOJULI_AANW_AANV_BER2"); // GTO berekening volgens Omgevingsregeling en GTO ≤ 450;
        } //-- end: showGtoTable ------------------------------------------------------------------

        function conditionH() {
            ///  conditie [H]:
            ///  verberg rij indien G04 = appartementengebouw en Z23 = per gebouw;
            ///  verberg rij indien G04 = appartementengebouw en Z23 = per gebouw en per appartement bij de 'gebouw' berekening(eerste tegel)
            return isAppPerGebouw() || (isAppPerAppartement() && isGebouw());
        }

        function conditionS() {
            /// condite [S] toon alleen als TO01 wordt getoond EN TO01 = GTO berekening conform Regeling Bouwbesluit
            return (_isVersionLe32 && currentResults().toJuliForm?.PropertyDatas["RESULT-TOJULI_FORM_TEMPMETH"].Value === "TEMPMETH_GTO")
        } //-- end: conditionS --------------------------------------------------------------------

        function conditionL(){
            // conditie [L] tonen indien le3-2
            return !ntaSharedLogic.isUtiliteit() && _isVersionLe32;
        } //-- end: conditionL --------------------------------------------------------------------

        function conditionG() {
            /// conditie [G] indien le3-2 als G04='appartementengebouw' en Z23='per gebouw' OF
            /// als G04 = 'appartementengebouw' en Z23 = 'per gebouw en appartement' en het betreft de gebouwberekening(eerste tegel),
            /// dan alle onderstaande velden op dit formulier verbergen en de volgende tekst tonen(...)
            const result = _isVersionLe32
                && (isAppPerGebouw() || isAppPerAppartement() && isGebouw());

            return result;
        } //-- end: conditionG --------------------------------------------------------------------

        function conditionW() {
            ///  conditie [W] indien ge3-3:
            ///   - als G04='appartementengebouw' EN  Z23='per gebouw en appartement' en het betreft de gebouwberekening (eerste tegel)
            ///   - als WB EN G04 = 'appartementengebouw' EN  Z23='per gebouw'
            ///  dan alle onderstaande velden op dit formulier verbergen en de volgende tekst tonen (...)
            const result = !_isVersionLe32
                && (isAppPerAppartement() && isGebouw()
                || !ntaSharedLogic.isUtiliteit() && !ntaSharedLogic.isNieuwbouw() && isAppPerGebouw());

            return result;
        } //-- end: conditionW --------------------------------------------------------------------

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                let itemId = prop.getValue(entdata);
                switch (prop.Id) {

                }
                if (!list) {
                    //-- anders ongefilterde codedValue list gebruiken
                    list = prop.Domain.Codes;
                }
                if (list && list.length > 0) {
                    listItem = list.find(item => item.Id === itemId);
                }
            }

            return listItem;
        } //-- end: getListItem -------------------------------------------------------------------

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }
            const hidden = isHidden(prop, entdata);
            let valid = ntaValidation.IsValid(self.form_tojuli, prop, propdata, hidden);

            switch (prop.Id) {
                // hier evt. property-specifieke validaties opnemen
                case 'RESULT-TOJULI_AANW_AANV_BER': {
                    /// in de getCodedValues wordt voor deze property een lijst samengesteld en gecontroleerd
                    /// of je juiste propdata nog klopt. Door het aanroepen van deze getCodedValue wordt
                    /// deze property gevalideerd.
                    getCodedValues(prop, entdata);
                    break;
                }
                case 'RESULT_TOJULI_TYPE_KOEL': { // specifiek voor opslag actieve koeling. De underscore is expres ivm de BuildingSummary
                    const variant = ntaEntityDataOrg.getFirstParent(entdata, 'VARIANT');
                    const variantId = variant?.EntityDataId ?? null;
                    if (variantId === ntaEntityData.getShadowId()) {
                        let typeKoel = 'RESULT-TOJULI_TYPE_KOEL_NONE';

                        // Gebruik hier ntaEntityData (en niet ntaEntityDataOrg) omdat we bij een variant de koelsystemen van die variant willen beschouwen (niet die van de basisberekening).
                        const koelopwekkers = ntaEntityData.findEntities(entdata, '^RZ.^KOEL.KOEL-OPWEK', '^UNIT-RZ.^RZ.^KOEL.KOEL-OPWEK');
                        if (koelopwekkers.length > 0) {
                            /// (er is sprake van actieve koeling indien bij een koelsysteem KO05 ≠ dauwpuntskoeling / booster)
                            const opwekkertypesPassieveKoeling = new Set(['KOEL-OPWEK_TYPE_6', 'KOEL-OPWEK_TYPE_11']); // dauwpuntskoeling / booster
                            const actieveKoeling = koelopwekkers.some(opwek => !opwekkertypesPassieveKoeling.has(opwek.PropertyDatas['KOEL-OPWEK_TYPE'].Value)); // KO05
                            typeKoel = actieveKoeling ? 'RESULT-TOJULI_TYPE_KOEL_ACTIVE' : 'RESULT-TOJULI_TYPE_KOEL_PASSIVE';
                        }

                        saveValue(prop, entdata, typeKoel);
                    }
                    break;
                }
                case 'RESULT-TOJULI_KOELCAP': {
                    /// toon 'n.v.t.' indien geen (actieve) koeling in de rekenzone aanwezig;
                    let koelCapValue = 'n.v.t';

                    /// conditie [K]
                    const typeKoel = entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL'].Value;
                    if (typeKoel === 'RESULT-TOJULI_TYPE_KOEL_ACTIVE') {
                        /// toon 'ja' in alle overige gevallen
                        koelCapValue = 'ja';

                        /// toon 'nee' indien:
                        /// - TO12 ≤ 1,20 (overslaan indien niet aanwezig) OF
                        const tojuliMax = ntaSharedLogic.parseFloat(entdata.PropertyDatas['RESULT-TOJULI_MAX'].Value, 1.50); /// als waarde leeg of null is, is tojuliMax niet berekend
                        if (tojuliMax <= 1.20) {
                            koelCapValue = 'nee';
                        } else {

                            /// - er sprake is van actieve koeling EN TO24 = JA OF
                            /// - er sprake is van actieve koeling EN TO25 = JA;

                            var weinigRamen = entdata.PropertyDatas['RESULT-TOJULI_WEINIG_RAMEN'].Value === 'true'; // TO24
                            var beperktZon = entdata.PropertyDatas['RESULT-TOJULI_BEP_ZON'].Value === 'true';       // TO25

                            if (weinigRamen || beperktZon) {
                                koelCapValue = "nee";
                            }
                        }
                    }

                    saveValue(prop, entdata, koelCapValue);

                    break;
                }
                case 'RESULT_TOJULI_RISICO': {
                    let risico;
                    const isVariant = ntaEntityDataOrg.getFirstParent(entdata, 'VARIANT');
                    if (!isVariant) {
                        //condition [P]
                        const koelcapAantonen = entdata.PropertyDatas['RESULT-TOJULI_KOELCAP']?.Value === 'ja';
                        const koelcapNietAantonen = entdata.PropertyDatas['RESULT-TOJULI_KOELCAP']?.Value === 'nee'; /// kan namelijk ook n.v.t. zijn
                        const typeKoeling = entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL']?.Value;
                        const aanvAanwBerekening = entdata.PropertyDatas['RESULT-TOJULI_AANW_AANV_BER']?.Value;
                        const tojuliMax = ntaSharedLogic.parseFloat(entdata.PropertyDatas['RESULT-TOJULI_MAX'].Value, 1.50); /// als waarde leeg of null is, is tojuliMax niet berekend

                        if (isAppPerGebouw() && (
                            (koelcapAantonen && aanvAanwBerekening == 'RESULT-TOJULI_AANW_AANV_BER1') ||
                            (typeKoeling !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE'))) {
                            risico = 'maak EP berekening per appartement';
                        } else if (tojuliMax <= 1.2 || koelcapNietAantonen || aanvAanwBerekening != 'RESULT-TOJULI_AANW_AANV_BER1') {
                            risico = 'voldoet';
                        } else {
                            risico = 'voldoet niet';
                        }
                    } else {
                        /// Bij varianten:
                        /// - als TOjuli voldoet aan de eis dan 'voldoet' met een vinkje
                        /// - als TOjuli niet voldoet aan de eis en geen actieve koeling in de variant dan 'voldoet niet' met een kruisje
                        /// - als TOjuli niet voldoet aan de eis en actieve koeling aanwezig dan 'n.t.b' en beoordeling zonder kruisje of vinkje

                        const tojuliMax = ntaSharedLogic.parseFloat(entdata.PropertyDatas['RESULT-TOJULI_MAX'].Value, 1.50); /// als waarde leeg of null is, is tojuliMax niet berekend
                        const typeKoeling = entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL']?.Value; // apart bepalen voor variant

                        if (tojuliMax <= 1.2) {
                            risico = 'voldoet';
                        } else if (typeKoeling !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE') {
                            risico = 'voldoet niet';
                        } else {
                            risico = 'n.t.b.';
                        }
                    }
                    saveValue(prop, entdata, risico);
                    break;
                }
            }

            return valid;
        } //-- end: validate ----------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                /// hier evt. property-specifieke validaties opnemen waardoor andere invoervelden die horen bij
                /// deze logic aangepast zouden moeten worden
                case "RESULT-TOJULI_AANW_AANV_BER": {
                    /// als deze waarde wijzigt, kan dat effect hebben op ander RESULT-TOJULI properties.
                    validateActiveBerekening();
                    break;
                }
                case "RESULT-TOJULI_WEINIG_RAMEN":
                case "RESULT-TOJULI_BEP_ZON":
                case "RESULT-TOJULI_MAX": {
                    /// Als deze waarde gewijzigd is, dan moeten we de andere properties in dezelfde entiteit valideren.
                    const propdatasToValidate = entdata.PropertyDatas.filter(pd => _validateProperties.has(pd.PropertyId));
                    for (const pd of propdatasToValidate) {
                        self.validate(ntaData.properties[pd.PropertyId], pd, entdata);
                    }
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop, entdata);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            const invalidPropdatas = new Set();
            /// voor het TOjuli resultaten formulier kan ik geen gebruik maken van de startFormValidation van
            /// sharedLogic, omdat ik wel alle entiteiten van het gebouw moet valideren, maar ze niet altijd
            /// zichtbaar zijn omdat ik resultaten per unit laat zien. Ik kan dus niet uitgaan van de standaard
            /// isHidden.

            if (ntabuilding.canSave()) {
                /// ik moet per berekening de validaties af
                for (const berekening of self.berekeningen) {
                    _actieveBerekening = berekening.Id;

                    const wrongPropdatas = validateActiveBerekening();
                    for (const propdata of wrongPropdatas) {
                        invalidPropdatas.add(propdata);
                    }
                }
            }

            return [...invalidPropdatas];
        } //-- end: startFormValidation -----------------------------------------------------------

        function validateActiveBerekening() {
            const invalidPropdatas = [];

            const entdatas = getAllEntDatasForBerekening();
            for (const entdata of entdatas) {
                const propdatasToValidate = entdata.PropertyDatas.filter(pd => _validateProperties.has(pd.PropertyId));
                for (const propdata of propdatasToValidate) {
                    try {
                        const isValid = self.validate(ntaData.properties[propdata.PropertyId], propdata, entdata);
                        if (!isValid) {
                            invalidPropdatas.push(propdata);
                        }
                    } catch (err) {
                        $log.warn('while validating ', propdata, ':', err.message, err);
                    }
                }
            }

            return invalidPropdatas;
        }   //-- end: validateActiveBerekening ----------------------------------------------------

        function endFormValidation() {
            /// voor het TOjuli resultaten formulier kan ik geen gebruik maken van de startFormValidation van
            /// sharedLogic, omdat ik wel alle entiteiten van het gebouw moet valideren, maar ze niet altijd
            /// zichtbaar zijn omdat ik resultaten per unit laat zien. Ik kan dus niet uitgaan van de standaard
            /// isHidden.

            const entdatas = getAllEntDatas();

            for (const entdata of entdatas) {
                const propdatasToValidate = entdata.PropertyDatas.filter(pd => _validateProperties.has(pd.PropertyId));
                for (const propdata of propdatasToValidate) {
                    if (!propdata.Touched) {
                        propdata.Touched = true;
                        ntaEntityDataOrg.saveprop(propdata);
                    }
                }
            }
            return startFormValidation();
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatasForBerekening(berekeningId = _actieveBerekening) {
            const shadowId = ntaEntityData.getShadowId();

            /// We gaan geen validaties afvuren voor de maatregelen; die hebben geen eigen resultatenset.
            const shadowEntdata = ntaEntityDataOrg.get(shadowId);
            if (shadowEntdata?.EntityId === 'MEASURE')
                return [];

            const results = currentResults(berekeningId, shadowId);
            return []
                .concat(results.gtoResultaten)
                .concat(results.toJuliResultaten)
                .concat(results.toJuliForm)
                .filter(ed => ed);
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function getAllEntDatas() {
            const set = new Set(self.berekeningen.flatMap(b => getAllEntDatasForBerekening(b.Id)));
            return [...set];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function isGebouw(id = _actieveBerekening) {
            return resultsLogic.isGebouw(id);
        } //-- end: isGebouw ----------------------------------------------------------------------

        function isAppPerGebouw() {
            return _gebType === "TGEB_APPGEB" && _calcUnit === "RZUNIT_GEB";
        } //-- end: isAppPerGebouw ----------------------------------------------------------------

        function isAppPerAppartement() {
            return _gebType === "TGEB_APPGEB" && _calcUnit === "RZUNIT_GEBAPP";
        } //-- end: isAppPerAppartement -----------------------------------------------------------

        function isBerekendPerApp_Unit() {
            return _calcUnit === "RZUNIT_GEBAPP" || _calcUnit === "RZUNIT_GEBUNIT";
        } //-- end: isBerekendPerApp_Unit ---------------------------------------------------------
    };
}]);
