﻿angular.module('afmeldModule')
    .controller("afmeldController",
        ['ntaData', 'progressCircle', '$scope', 'time', '$mdDialog', '$http', '$log', 'ntaValidation', 'baseNtaEntityData', 'ntaStorage', 'ntaAlert', 'ntaBuildingNotification', 'ntaRounding', 'ntaLocal',
function (ntaData,   progressCircle,   $scope,   time,   $mdDialog,   $http,   $log,   ntaValidation,   ntaEntityData,       ntaStorage,   ntaAlert,   ntaBuildingNotification,   ntaRounding,   ntaLocal) {
    'use strict';
    const vm = this;

    vm.ntaValidation = ntaValidation;
    vm.commit = ntaData.commit;
    const isVersieLagerDanMeestRecent = ntaData.ntaVersion.ntaVersionId < 300;  /// 300 is de versierange van Uniec 3.3

    vm.getForm = function() {
        return vm.form_afmelden;
    };

    vm.setAfmeldId = function (afmeldId) {
        vm.afmeldId = afmeldId;
    };

    // Stuur bericht rond dat wij deze berekeningen hebben
    ntaBuildingNotification.notifyBuildingsOpened(ntaData.projecttree.Gebouwberekeningen.map(b => b.GebouwId));

    vm.propertiesUNIT = ntaData.properties['UNIT'];
    vm.propertiesOBJ = ntaData.properties['AFMELDOBJECT'];
    vm.propertiesLOC = ntaData.properties['AFMELDLOCATIE'];
    vm.propertiesINFO = ntaData.properties['AFMELDINFO'];

    vm.propTypeAfmelding = vm.propertiesOBJ['AFMOBJ_ACTIE'];
    vm.propOpnameDatum = vm.propertiesLOC['AFMLOC_OPNAMEDATUM'];

    vm.units = ntaEntityData.getListWithEntityId('UNIT'); //Let op: alle units van alle gebouwen in ntaData
    vm.infoDatas = ntaEntityData.getListWithEntityId('AFMELDINFO');
    vm.mainInfo = vm.infoDatas[0];
    vm.afmeldObjecten = ntaEntityData.getListWithEntityId('AFMELDOBJECT');
    vm.gebouwAfmeldLocatie = ntaEntityData.getFirstChild(ntaEntityData.getFirstChild(ntaEntityData.getFirstWithEntityId('GEB'), 'AFMELDOBJECT'), 'AFMELDLOCATIE'); //Eerste child afmeldLocatie van het eerste child afmeldObject van GEB
    vm.mainAfmeldObject = vm.afmeldObjecten[0];
    vm.mainAfmeldLocatie = ntaEntityData.getFirstChild(ntaEntityData.getFirstChild(ntaEntityData.getFirstWithEntityId('UNIT'), 'AFMELDOBJECT'), 'AFMELDLOCATIE'); //Eerste child afmeldLocatie van het eerste child afmeldObject van UNIT;

    const propdataSettingsMwa = ntaEntityData.getFirstWithEntityId('SETTINGS').PropertyDatas['SETTINGS_MAATADVIES'];
    vm.isMaatwerkadvies = String(propdataSettingsMwa && propdataSettingsMwa.Value).toLowerCase() === "true";


    let _afmeldLocaties = ntaEntityData.getListWithEntityId('AFMELDLOCATIE');

    const typeGebouw = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_TYPEGEB'].Value;
    const soortGebouw = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_SRTBW'].Value;
    const calcUnit = ntaEntityData.getFirstWithEntityId('RZFORM').PropertyDatas['RZFORM_CALCUNIT'].Value;
    const isUtiliteit = typeGebouw === 'TGEB_UTILIT' || typeGebouw === 'TGEB_UTILUNIT';
    const isNieuwbouw = soortGebouw === 'NIEUWB';
    const _locationFields = new Map();
    const _dialogPromises = new Map();

    let _usingRepresentativity = usingRepresentativity();

    {   // Bepaal welke entiteiten afgemeld moeten worden (en dus relevant zijn), en welke niet
        const entityIds = new Set();
        switch (typeGebouw) {
            case 'TGEB_APPGEB': // appartementengebouw
                if (isNieuwbouw || vm.isMaatwerkadvies) {
                    entityIds.add('GEB');
                }
                if (calcUnit !== 'RZUNIT_GEB') {
                    entityIds.add('UNIT');
                }
                break;

            case 'TGEB_APP': // los appartement in appartementengebouw
                entityIds.add('UNIT');
                break;

            case 'TGEB_UTILIT': // utiliteitsgebouw
                if (calcUnit === 'RZUNIT_GEBUNIT') {
                    entityIds.add('GEB');
                }
                entityIds.add('UNIT');
                break;

            case 'TGEB_UTILUNIT': // losse unit in utiliteitsgebouw
                entityIds.add('UNIT');
                break;

            default: // grondgebonden woning
                entityIds.add('UNIT');
                break;
        }

        const relevanteAOs = [], irrelevanteAOs = [];
        for (const afmeldobject of vm.afmeldObjecten) {
            const parent = ntaEntityData.getFirstParent(afmeldobject);
            const isRelevant = parent && entityIds.has(parent.EntityId) || false;
            (isRelevant ? relevanteAOs : irrelevanteAOs).push(afmeldobject);
        }
        // Bij representativiteit of het aanwezig zijn van niet-zelfstandige VBO's moet alleen het eerste AFMELDOBJECT van elke UNIT relevant zijn
        if (usingRepresentativity() || usingNietZelfstandigeVBOs()) {
            const units = ntaEntityData.getListWithEntityId('UNIT');
            for (const unit of units) {
                const afmeldobjecten = ntaEntityData.getChildren(unit, "AFMELDOBJECT");
                for (const afmeldobject of afmeldobjecten.slice(1)) { // de eerste overslaan
                    const index = relevanteAOs.indexOf(afmeldobject);
                    if (index > -1) {
                        relevanteAOs.splice(index, 1);
                    }
                    if (!irrelevanteAOs.includes(afmeldobject)) {
                        irrelevanteAOs.push(afmeldobject);
                    }
                }
            }
        }

        ntaEntityData.setEntityRelevancy(relevanteAOs, true);
        ntaEntityData.setEntityRelevancy(irrelevanteAOs, false);
    }

    vm.creditsUnit = isUtiliteit ? 'm²' : 'woningen';
    let _credits = '..';

    vm.getCredits = () => _credits;

    function calculateCredits() {
        const eerderMislukt = isEerderMislukt();
        const propCredits = ntaData.properties['AFMOBJ_CREDITS'];

        const afmeldobjecten = ntaEntityData.getListWithEntityId('AFMELDOBJECT')
            .filter(ed => ed.Relevant)
            .filter(ed => !['AFM_ACTIE_VERVANGEN', 'AFM_ACTIE_HERLABELEN'].includes(ed.PropertyDatas['AFMOBJ_ACTIE'].Value)) // Vervangen en herlabelen kost geen tegoed
            .filter(ed => !eerderMislukt || ed.PropertyDatas['AFMOBJ_STATUS'].Value !== '4'); // bij een nieuwe afmelding eerdere succesvolle afmeldingen niet meetellen

        // Het aantal credits kan veranderen bij representativiteit of het aanwezig zijn van niet-zelfstandige VBO's, maar ook als er afmeldobjecten toegevoegd worden
        if (!isUtiliteit) {
            for (const afmeldobject of afmeldobjecten.filter(ed => ntaEntityData.getFirstParent(ed, 'UNIT'))) {
                const credits = usingRepresentativity() || usingNietZelfstandigeVBOs()
                    ? ntaEntityData.getChildRelations(afmeldobject, 'AFMELDLOCATIE').length
                    : 1;
                ntaEntityData.saveprop(propCredits.getData(afmeldobject), credits.toString());
            }
        }

        const totalCredits = afmeldobjecten
            .reduce((sum, ed) => sum + (parseInt(ed.PropertyDatas['AFMOBJ_CREDITS'].Value) || 0), 0);

        _credits = ntaRounding.formatNumber(totalCredits, 0);
        if (!isUtiliteit) {
            vm.creditsUnit = totalCredits === 1 ? 'woning' : 'woningen';
        }
    }

    function buildEntities() {
        _afmeldLocaties = ntaEntityData.getListWithEntityId('AFMELDLOCATIE');
        vm.afmeldObjecten = ntaEntityData.getListWithEntityId('AFMELDOBJECT');

        for (const unit of vm.units) {
            unit.afmeldObjecten = ntaEntityData.getChildren(unit, 'AFMELDOBJECT').filter(x => x.Relevant); // alleen relevante afmeldObjecten
            for (const afmeldObject of unit.afmeldObjecten) {
                afmeldObject.afmeldLocaties = ntaEntityData.getChildren(afmeldObject, 'AFMELDLOCATIE').filter(x => x.Relevant); //alleen relevante afmeldLocaties
            }
        }
        calculateCredits();
    }
    buildEntities();

    function checkData() {
        // als afmeldformulier van een gekopieerde berekening wordt geopend en deze heeft de afmeldgegevens van het origineel bij de eerste keer van het openen van het afmeldformulier de melding [W076] tonen
        const propdatasEarlierNames = vm.infoDatas
            .map(ed => ed.PropertyDatas['AFM_NAAM_ORIGINEEL'])
            .filter(pd => pd && pd.Value);
        if (propdatasEarlierNames.length > 0) {
            const earlierNames = '“' + propdatasEarlierNames.map(pd => pd.Value).join('”, “') + '”';
            warningDialog('[W076]', null, { 'naam berekening': earlierNames })
                .then(() => {
                    for (const propdata of propdatasEarlierNames) {
                        ntaEntityData.saveprop(propdata, null);
                    }
                });
        }
        checkOpnameDatum();
    }

    function checkOpnameDatum() {
        //check of AFMLOC_OPNAMEDATUM is ingevuld bij unit afmeldLocaties
        if (!vm.propertyIsHidden('AFMLOC_OPNAMEDATUM', 'AFMELDINFO')) {
            const propdatasOpnameDatumEmpty = _afmeldLocaties
                .map(ed => ed.PropertyDatas['AFMLOC_OPNAMEDATUM'])
                .filter(pd => pd && !pd.Value);
            const value = vm.mainAfmeldLocatie.PropertyDatas['AFMLOC_OPNAMEDATUM'].Value;
            for (const propdata of propdatasOpnameDatumEmpty) {
                ntaEntityData.saveprop(propdata, value);
            }
        }
    }


    vm.getPropertiesUNIT = function () {
        //conditie [AL]
        const positie = typeGebouw === 'TGEB_APP'
                    ||  typeGebouw === 'TGEB_UTILUNIT'
                    || (typeGebouw === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEBAPP')
                    || (typeGebouw === 'TGEB_UTILIT' && calcUnit === 'RZUNIT_GEBUNIT');

        return vm.propertiesUNIT.filter(x => x.Id === 'UNIT_OMSCHR'
                                        ||  (x.Id === 'UNIT_POS' && positie)
                                        ||  (x.Id === 'UNIT_TYPEWON' && typeGebouw === 'TGEB_GRWON'));
    }

    vm.getFirstPropertiesInfo = function () {
        return vm.propertiesINFO.filter(x => x.Id === 'AFM_AANLEIDING' || x.Id === 'AFM_REGISTRATIE_ENERL');
    };

    vm.getLastPropertiesInfo = function () {
        return vm.propertiesINFO.filter(x => x.Id !== 'AFM_AANLEIDING' && x.Id !== 'AFM_REGISTRATIE_ENERL');
    };

    vm.getPropData = function (prop, entdata) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];
        const propdata = prop.getData(entdata);

        switch (prop.Id) {
            case 'AFM_IDENTIFICATIEMETHODE': {
                //defaultwaarde:
                //- indien A24 = aanvraag omgevingsvergunning-- > geen BAG identificatie beschikbaar
                //- indien A24≠aanvraag omgevingsvergunning EN G04 = 'woonwagen' --> 'standplaats ID
                //- indien A24≠aanvraag omgevingsvergunning EN G04 = 'woonboot - ligplaats voor 1 januari 2018' of 'woonboot - ligplaats vanaf 1 januari 2018' --> ligplaatsid
                //- In alle overige gevallen VBO-id
                if (!vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value) { //set default
                    if (vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_AANVR') {
                        propdata.Value = 'IDENTM_ZONDER_BAG';
                        vm.saveValue(prop, entdata);
                    } else {
                        if (typeGebouw === 'TGEB_WOONW') {
                            propdata.Value = 'IDENTM_STANDPLAATS';
                            vm.saveValue(prop, entdata);
                        } else if (typeGebouw === 'TGEB_WOONBB' || typeGebouw === 'TGEB_WOONBN') {
                            propdata.Value = 'IDENTM_LIGPLAATS';
                            vm.saveValue(prop, entdata);
                        } else {
                            propdata.Value = 'IDENTM_BAG';
                            vm.saveValue(prop, entdata);
                        }
                    }
                }
                break;
            }
            case 'UNIT_OMSCHR': {
                const buildingPrefix = ntaEntityData.getFirstWithEntityId('GEB', entdata.BuildingId).PropertyDatas['GEB_OMSCHR'].Value + ' - ';
                const unitName = propdata.Value || '';
                if (!unitName.startsWith(buildingPrefix)) {
                    propdata.Value = buildingPrefix + unitName;
                    // Dit niet opslaan! Is alleen bedoeld voor weergave op dit afmeldformulier.
                }
                break;
            }
            case 'AFM_REGISTRATIE_ENERL': {
                if (!propdata.Value) {
                    let value;
                    if (typeGebouw === 'TGEB_UTILIT') {
                        value = 'AFM_LBL_GEB_GEHEEL';
                    } else if (typeGebouw === 'TGEB_UTILUNIT') {
                        value = 'AFM_LBL_PER_UNIT';
                    }
                    if (value) {
                        propdata.Value = value;
                        vm.saveValue(prop, entdata);
                    }
                }
                break;
            }
        }

        return propdata;
    };

    vm.getPropertyType = function(prop) {
        return prop && prop.PropertyType;
    }

    vm.getName = function(prop, objectType) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];

        let str = prop.Name || "";
        if (str) {
            const unit = vm.getUnit(prop);
            if (unit) {
                str += " [" + unit + "]"; getName
            }
        }

        switch (prop.Id) {
            case 'AFMLOC_OPNAMEDATUM': {
                //[AC]
                if (['AFM_ACTIE_VERVANGEN', 'AFM_ACTIE_HERLABELEN'].includes(vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value)) {
                    str += ' ingevuld bij oorspronkelijke registratie';
                }
                if (vm.isMaatwerkadvies) {
                    str = 'datum maatwerkadvies';
                }
                break;
            }
            case 'AFM_IDENT_METH_EERDER': {
                str += " " + conditieBD(); //[BD]
                break;
            }
            case 'AFMLOC_OMSCHR': {
                if (objectType === 'AFMELDGEBOUW')
                    str += " pand";
                else
                    str += " " + conditieAN_BA(); //[AN] en [BA]
                break;
            }
            case 'AFMLOC_PROVISIONAL_ID': {
                if (objectType === 'AFMELDGEBOUW')
                    str += " pand";
                break;
            }
            case 'AFMLOC_BAG_ID': {
                const identificatie = vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value;
                if (objectType === 'AFMELDGEBOUW') {
                    str = 'BAG verblijfsobject ID van één van de appartementen in het pand'; // A45 [BR]
                } else {
                    switch (identificatie) {
                        case 'IDENTM_LIGPLAATS': {
                            str = 'ligplaats ID';
                            break;
                        }
                        case 'IDENTM_STANDPLAATS': {
                            str = 'standplaats ID';
                            break;
                        }
                        case 'IDENTM_MEER_BAG':
                        case 'IDENTM_BAG': {
                            str = 'verblijfsobject ID';
                            break;
                        }
                        default: {
                            str = 'verblijfsobject ID';
                        }
                    }
                }
                break;
            }
        }
        return str;
    }

    vm.getUnit = function(prop) {
        return prop && prop.Unit || "";
    }

    vm.getStyle = function (prop) {
        const propId = typeof prop === 'string' ? prop : prop.Id;
        switch (propId) {
            case 'AFMOBJ_ADV_VOORL':
            case 'AFMOBJ_ADV_TUSSENV':
            case 'AFMLOC_REPRESENTATIEF':
            case 'AFMLOC_POSTCODE':
            case 'AFMLOC_HUISNR':
            case 'AFMLOC_HUISLETTER':
            case 'AFMLOC_HUISNRTOEV': {
                return { 'width': '80px','max-width':'80px' };
            }
            case 'AFMLOC_PROVISIONAL_ID': {
                return { 'width': '224px', 'min-width': '224px', 'max-width': '224px' };
            }
            case 'AFMLOC_OMSCHR':
            case 'UNIT_OMSCHR': {
                return { 'width': 'auto', 'min-width': '240px' };
            }
        }
        return { width: '240px' };
    }

    vm.getCodedValues = function (prop, entdata) {
        let values = ntaValidation.codedValues(prop);

        switch (prop.Id) {
            case 'AFM_AANLEIDING': {
                if (!isNieuwbouw) {
                    values = values.filter(x => x.Id !== 'AFM_AANL_AANVR' && x.Id !== 'AFM_AANL_OPLVNB');
                } else {
                    values = values.filter(x => x.Id !== 'AFM_AANL_BESTB');
                }

                // [CN] verberg indien A33 = herlabelen
                const hasRelabeling = vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value === 'AFM_ACTIE_HERLABELEN'; // A33
                if (hasRelabeling) {
                    values = values.filter(x => x.Id !== 'AFM_AANL_AANVR');
                }
                break;
            }
            case 'AFM_IDENTIFICATIEMETHODE': {
                //[AD]
                const aanleiding = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'];
                if (aanleiding.Relevant && !aanleiding.Value) {
                    values = [];
                } else if (!aanleiding.Relevant || aanleiding.Value !== 'AFM_AANL_AANVR') {
                    if (vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === "IDENTM_ZONDER_BAG") { // waarde niet meer in dropdown -> leegmaken
                        prop.getData(vm.mainInfo).Value = "";
                        vm.saveValue(prop, vm.mainInfo);
                    }
                    values = values.filter(x => x.Id !== 'IDENTM_ZONDER_BAG');
                }
                if (!isNieuwbouw) {
                    values = values.filter(x => x.Id !== 'IDENTM_ZONDER_BAG');
                }

                //[BX]
                values = values.filter(x => x.Id != 'IDENTM_MEER_BAG' || typeGebouw != 'TGEB_APPGEB');

                //[CG]
                const identMeerBags = values.find(x => x.Id === 'IDENTM_MEER_BAG');
                if (identMeerBags && vm.isMaatwerkadvies) {
                    identMeerBags.Value = 'meerdere maatwerkadviezen op één BAG identificatie';
                }

                if (isUtiliteit || (vm.isMaatwerkadvies && typeGebouw === 'TGEB_APPGEB')) { //verberg standplaats ID en ligplaats ID, bij Utiliteit of [CK] als A33 = maatwerkadvies EN G04 = appartementengebouw
                    values = values.filter(x => x.Id != 'IDENTM_LIGPLAATS' && x.Id != 'IDENTM_STANDPLAATS');
                }

                break;
            }
            case 'AFM_IDENT_METH_EERDER': {
                values = ntaValidation.codedValues(prop, conditieBD());

                //[AY]
                if (vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_ZONDER_BAG') {
                    if (vm.mainInfo.PropertyDatas['AFM_IDENT_METH_EERDER'].Value === "IDENTM_EERDER_BAG_REG_OORSPR" || vm.mainInfo.PropertyDatas['AFM_IDENT_METH_EERDER'].Value === "IDENTM_EERDER_BAG_REG_OMGVERG") { // waarde niet meer in dropdown -> leegmaken
                        prop.getData(vm.mainInfo).Value = "";
                        vm.saveValue(prop, vm.mainInfo);
                    }
                    values = values.filter(x => x.Id !== 'IDENTM_EERDER_BAG_REG_OORSPR' && x.Id !== 'IDENTM_EERDER_BAG_REG_OMGVERG');
                }
                break;
            }
            case 'AFMOBJ_ACTIE': {
                const bs = !isVersieLagerDanMeestRecent;            // [BS]
                const ce = !vm.isMaatwerkadvies;                    // [CE]
                const cf = vm.isMaatwerkadvies;                     // [CF]
                const ch = ntaData.ntaVersion.ntaVersionId >= 100;  // [CH] toon bij berekeningen ge3-1

                const choiceConditions = {
                    AFM_ACTIE_NIEUW: bs && ce,
                    AFM_ACTIE_VERVANGEN: ce,
                    AFM_ACTIE_HERLABELEN: ce && ch,
                    AFM_ACTIE_MWA: cf,
                };

                values = values.filter(x => !Object.hasOwn(choiceConditions, x.Id) || choiceConditions[x.Id]);
                break;
            }
            case 'AFM_REGISTRATIE_ENERL': {
                //Conditie [BU]
                if (typeGebouw === 'TGEB_UTILUNIT') {
                    values = values.filter(x => x.Id !== 'AFM_LBL_GEB_GEHEEL');
                }

                break;
            }
        }

        //check of propdata.Value in values zit, anders propdata.Value leegmaken
        const propdata = prop.getData(entdata);
        if (propdata && propdata.Value && !values.map(x => x.Id).includes(propdata.Value)) {
            propdata.Value = "";
            vm.saveValue(prop, entdata);
        }

        return values;
    };

    vm.saveValue = async function (prop, entdata, objectType = null) {
        if (typeof prop === 'string')
            prop = ntaData.properties[prop];
        if (!prop || !entdata) {
            return false;
        }

        const propdata = prop.getData(entdata);

        objectType = objectType || entdata.EntityId;
        if (objectType === 'AFMELDINFO') {
            if (prop.Id === 'AFMOBJ_ACTIE') {
                const mainValue = prop.getValue(vm.mainAfmeldObject);
                for (const afmobj of vm.afmeldObjecten) {
                    if (afmobj !== entdata && prop.getValue(afmobj) !== mainValue) {
                        prop.getData(afmobj).Value = mainValue;
                        vm.saveValue(prop, afmobj);
                    }
                }
            } else if (prop.Id === 'AFMLOC_OPNAMEDATUM') {
                const mainValue = prop.getValue(vm.mainAfmeldLocatie);
                for (const afmloc of _afmeldLocaties) {
                    if (afmloc !== entdata && prop.getValue(afmloc) !== mainValue) {
                        prop.getData(afmloc).Value = mainValue;
                        vm.saveValue(prop, afmloc);
                    }
                }
            } else if (prop.Id === 'AFM_NZELFST_WOONEENH_INDIV_VBO') {
                //[CA] indien keuze voor 'niet-zelfstandige wooneenheden met individuele VBO-id's aanwezig' zet het buildingcategorie in de XML op 8.
                //Alle opgegeven VBO's worden dan onder 1 registratienummer geregistreerd.
                //Toon[W100] als op mapniveau wordt afgemeld en toon[W099] in andere gevallen
                //[CD] indien Z23 = voor projectwoningen / per gebouw en per appartement EN Z12 in 1 van de rijen van tabel Z08 > 1 geef waarschuwing[W101].In dat geval niet[W099] of[W100] tonen.
                const mainValue = prop.getValue(entdata);
                if (mainValue === "AFM_NZELFST_WOONEENH_INDIV_VBO_AANW") {
                    if ((calcUnit === "RZUNIT_PROJECT" || calcUnit === "RZUNIT_GEBAPP") && vm.units.some(x => x.PropertyDatas['UNIT_AANTA'].Value > 1)) {
                        await warningDialog("[W101]");
                        propdata.Value = "AFM_NZELFST_WOONEENH_INDIV_VBO_NAANW"; //terug zetten
                        return false;
                    } else {
                        let warning = "[W099]";
                        const bldIds = vm.units.flatMap(x => x.BuildingId);
                        if (!bldIds.every(x => x === bldIds[0])) { //meerdere gebouwen = afmelden op mapniveau
                            warning = "[W100]";
                        }
                        try {
                            await confirmDialog(warning)
                        } catch (err) {
                            //-- gekozen voor {annuleren}
                            propdata.Value = "AFM_NZELFST_WOONEENH_INDIV_VBO_NAANW"; //terug zetten
                            return false;
                        }
                    }

                }

                for (const unit of vm.units) {
                    changeAfmeldlocaties(unit, mainValue === "AFM_NZELFST_WOONEENH_INDIV_VBO_AANW");
                }
                buildEntities();

            } else if (entdata === vm.mainInfo) { // alle andere props van afmeldInfo opslaan voor bij alle andere infoDatas
                _dialogPromises.delete('[W090]');
                const mainValue = prop.getValue(vm.mainInfo);
                for (const afmInfo of vm.infoDatas.slice(1)) {
                    const propdata = prop.getData(afmInfo);
                    if (propdata.Value !== mainValue) {
                        propdata.Value = mainValue;
                        vm.saveValue(prop, afmInfo);
                    }
                }
                if (prop.Id === 'AFM_ADVISEUR') {
                    _locationFields.clear();
                }
            }
        } else if (objectType === 'AFMELDLOCATIE') {
            if (prop.Id === 'AFMLOC_OPNAMEDATUM') {
                if (!isUtiliteit) {
                    ////[BE] als er meerdere rijen in de tabel staan bij het invullen van de datum in de eerste rij deze doorkopiëren naar alle overige rijen;
                    ////     de datum bij de overige rijen kunnen wel handmatig overschreven worden
                    //// !!! Volgend deel van de conditie wordt door de server gedaan -> indien afmelding gebouw met 'Z23=per gebouw en per appartement' voor de afmelding van het hele gebouw de nieuwste datum uit de tabel gebruiken
                    if (entdata === vm.mainAfmeldLocatie) { //eerste rij
                        const entdatasOpnameDatumEmpty = _afmeldLocaties.filter(ent => !prop.getValue(ent) && ent.EntityDataId !== entdata.EntityDataId);
                        const value = prop.getValue(vm.mainAfmeldLocatie);
                        for (const entdataEmpty of entdatasOpnameDatumEmpty) {
                            prop.getData(entdataEmpty).Value = value;
                            vm.saveValue(prop, entdataEmpty);
                        }
                    }
                } else { //Utiliteit => Opnamedatum zit op het niveau van de Unit. Bij opslaan de opnameDatum ook toekennen aan de overige afmeldLocaties van de unit
                    const value = prop.getValue(entdata);
                    const unit = ntaEntityData.findEntity(entdata, "^AFMELDOBJECT.^UNIT", "^AFMELDOBJECT.^GEB");
                    const afmeldlocaties = ntaEntityData.findEntities(unit, 'AFMELDOBJECT.AFMELDLOCATIE');
                    const opnamedatumPropdatas = afmeldlocaties.map(afmeldlocatie => prop.getData(afmeldlocatie));
                    ntaEntityData.saveprops(opnamedatumPropdatas, value);
                }
            }
        }
        if (new Set(['AFMOBJ_ADV_NAAM', 'AFMOBJ_ADV_VOORL', 'AFMOBJ_ADV_TUSSENV', 'AFMOBJ_ADV_ACHTERN', 'AFMOBJ_ADV_EXAMENNUMMER']).has(prop.Id)) {
            /// [BW] als er meerdere rijen in de tabel staan, bij het invullen van de datum
            /// in de eerste rij deze doorkopiëren naar alle overige rijen; de velden bij
            /// de overige rijen kunnen wel handmatig overschreven worden
            if (entdata === ntaEntityData.getFirstParent(vm.mainAfmeldLocatie, 'AFMELDOBJECT')) { //eerste rij
                const entdatasEmpty = vm.afmeldObjecten.filter(ed => ed !== entdata && !prop.getValue(ed));
                const value = prop.getValue(entdata);
                for (const entdataEmpty of entdatasEmpty) {
                    prop.getData(entdataEmpty).Value = value;
                    vm.saveValue(prop, entdataEmpty);
                }
            }
        }

        if (!propdata) {
            return false; // dan valt er ook niets op te slaan
        }

        propdata.Touched = true; // opslaan veld is aangeraakt
        if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
            return true;
        }

        if (prop.Id === 'AFM_REGISTRATIE_ENERL') {
            if (propdata.Value === "AFM_LBL_PER_UNIT" && typeGebouw === "TGEB_UTILIT") { ///conditie [BV]
                let promise = _dialogPromises.get('[W090]');
                if (!promise) {
                    promise = confirmDialog('[W090]');
                    _dialogPromises.set('[W090]', promise);
                }
                try {
                    await promise;
                } catch (err) {
                    //-- gekozen voor {annuleren}
                    propdata.Value = "AFM_LBL_GEB_GEHEEL"; //terug zetten
                    return false;
                }
            }
        }

        vm.validate(prop, entdata, objectType);
        if (ntaEntityData.saveprop(propdata)) {
            time.delayActions(function () {
                vm.validateDependencies(prop, entdata);
            });

            return true;
        }

        return false;
    };

    vm.validate = function (prop, entdata, objectType = null) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];
        if (!prop || !entdata) {
            return;
        }

        const propdata = prop.getData(entdata);
        objectType = objectType || entdata.EntityId;
        const hidden = vm.isHidden(prop, entdata, objectType);
        const readonly = vm.isReadOnly(prop, entdata);
        let valid = ntaValidation.IsValid(vm.form_afmelden, prop, propdata, hidden, readonly);

        //Nodig om het formulier invalid te maken igv relevante propdatas zonder waarde, die wel relevant en required zijn
        if (propdata.Relevant && propdata.Value) {
            const contrl = vm.form_afmelden && vm.form_afmelden['ntainput' + propdata.PropertyDataId];
            if (contrl) {
                contrl.$setTouched()
            }
        }

        //domeinen
        vm.setMelding("[D023]", propdata, true);
        vm.setMelding("[D022]", propdata, true);
        vm.setMelding("[D021]", propdata, true);
        vm.setMelding("[D020]", propdata, true);
        vm.setMelding("[D019]", propdata, true);
        vm.setMelding("[D018]", propdata, true);
        vm.setMelding("[D017]", propdata, true);
        vm.setMelding("[D016]", propdata, true);

        if (valid && propdata.Relevant && !!propdata.Value) { // moet ook altijd een waarde hebben om te valideren. -> geen waarde wordt opgepakt door ntaValidation.IsValid
            vm.setMelding("[D024]", propdata, true);
            switch (prop.Id) {
                case 'AFMLOC_DETAILAANDUIDING': {
                    valid = (propdata.Value || '').length <= 25;
                    vm.setMelding("[D023]", propdata, valid);
                    break;
                }
                case 'AFMLOC_HUISNRTOEV': {
                    valid = /^[A-Za-z0-9]+$/i.test(propdata.Value);
                    valid = valid && !!propdata.Value && propdata.Value.length <= 4;
                    vm.setMelding("[D022]", propdata, valid);
                    break;
                }
                case 'AFMLOC_HUISLETTER': {
                    valid = /^[A-Za-z]{1}$/i.test(propdata.Value);
                    vm.setMelding("[D021]", propdata, valid);
                    break;
                }
                case 'AFMLOC_HUISNR': {
                    valid = /^[0-9]+$/i.test(propdata.Value);
                    vm.setMelding("[D020]", propdata, valid);
                    break;
                }
                case 'AFMLOC_POSTCODE': {
                    valid = /^[0-9]{4}[A-Z]{2}$/.test(propdata.Value);
                    vm.setMelding("[D019]", propdata, valid);
                    break;
                }
                case 'AFM_PROJECTNAAM': {
                    valid = /^[\w '`,-]+$/.test(propdata.Value);
                    vm.setMelding("[D018]", propdata, valid);
                    break;
                }
                case 'AFMLOC_OMSCHR': {
                    valid = /^[\w '`,-]+$/.test(propdata.Value);
                    vm.setMelding("[D018]", propdata, valid);

                    valid = conditieBO(propdata) && valid;
                    break;
                }
                case 'AFMLOC_PROVISIONAL_ID': {
                    valid = /^[a-z0-9]{32}$/i.test(propdata.Value); //alfanumeriek, 32 karakters
                    vm.setMelding("[D017]", propdata, valid);
                    break;
                }
                case 'AFMLOC_BAG_ID': {
                    valid = /^[0-9]{16}$/.test(propdata.Value); //numeriek, 16 cijfers
                    vm.setMelding("[D016]", propdata, valid);

                    valid = conditieBagUnique(propdata) && valid;
                    break;
                }
            }
        }

        return valid;
    };

    vm.setMelding = function(code, propdata, valid) {
        if (!propdata) {
            return
        }
        //-- de message voor de control op formulier zetten
        const contrl = vm.form_afmelden && vm.form_afmelden['ntainput' + propdata.PropertyDataId];
        if (contrl) {
            contrl.$setValidity(code, valid);
            if (!valid) { // contrl op touched zetten igv false, want er kon worden gevalideerd (er is dus een waarde) en is nodig om melding zichtbaar te maken
                contrl.$setTouched();
            }
        } else if (!valid) {
            // als er geen control is, dan zoeken we op of deze propdata bij een andere pagina hoort
            const entdata = ntaEntityData.get(propdata.EntityDataId);
            const unit = getUnitForEntdata(entdata);
            const page = unit && getUnitPage(unit);
            if (page) {
                page.Valid &= valid;
            }
        }
    };

    function getUnitForEntdata(entdata) {
        while (entdata && entdata.EntityId !== 'UNIT') {
            entdata = ntaEntityData.getFirstParent(entdata);
        }
        return entdata;
    }

    vm.validateDependencies = function (prop, entdata) {
        if (!prop || !entdata) {
            return;
        }

        const useRepresentativity = usingRepresentativity();
        if (useRepresentativity !== _usingRepresentativity) {

            representativityChanged(useRepresentativity);
            _usingRepresentativity = useRepresentativity;

        } else {
            // wordt ook al aangeroepen vanuit representativityChanged()
            calculateCredits();
        }

        if (!vm.propertyIsHidden('AFMLOC_OMSCHR', 'AFMELDLOCATIE')) { //[AH]
            vm.units.forEach(function (unit) {
                unit.afmeldObjecten.forEach(function (afmeldObject) {
                    afmeldObject.afmeldLocaties.forEach(function (afmeldLocatie) {
                        vm.validate('AFMLOC_OMSCHR', afmeldLocatie, 'AFMELDLOCATIE');
                    });
                });
            });
        }

        if (!vm.propertyIsHidden('AFMLOC_PROVISIONAL_ID', 'AFMELDLOCATIE')) { //[AI]
            vm.units.forEach(function (unit) {
                unit.afmeldObjecten.forEach(function (afmeldObject) {
                    afmeldObject.afmeldLocaties.forEach(function (afmeldLocatie) {
                        vm.validate('AFMLOC_PROVISIONAL_ID', afmeldLocatie, 'AFMELDLOCATIE');
                    });
                });
            });
        }

        if (!vm.propertyIsHidden('AFMLOC_BAG_ID', 'AFMELDLOCATIE')) { //[AI]
            vm.units.forEach(function (unit) {
                unit.afmeldObjecten.forEach(function (afmeldObject) {
                    afmeldObject.afmeldLocaties.forEach(function (afmeldLocatie) {
                        vm.validate('AFMLOC_BAG_ID', afmeldLocatie, 'AFMELDLOCATIE');
                    });
                });
            });
        }

        if (!vm.propertyIsHidden('AFMLOC_OMSCHR', 'AFMELDGEBOUW')) { //[AU]
            vm.validate('AFMLOC_OMSCHR', vm.gebouwAfmeldLocatie, 'AFMELDGEBOUW');
        }

        if (!vm.propertyIsHidden('AFMLOC_PROVISIONAL_ID', 'AFMELDGEBOUW')) { //[AV]
            vm.validate('AFMLOC_PROVISIONAL_ID', vm.gebouwAfmeldLocatie, 'AFMELDGEBOUW');
        }

        if (!vm.propertyIsHidden('AFMLOC_OPNAMEDATUM', 'AFMELDINFO')) {
            vm.validate('AFMLOC_OPNAMEDATUM', vm.mainAfmeldLocatie, 'AFMELDINFO');
        }

        if (prop.Id === "AFM_IDENTIFICATIEMETHODE") {
            for (const unit of vm.units) {
                for (const afmeldObject of unit.afmeldObjecten) {
                    for (const afmeldLocatie of afmeldObject.afmeldLocaties) {
                        vm.validate('AFMLOC_BAG_ID', afmeldLocatie, 'AFMELDLOCATIE');
                    }
                }
            }
        }

        for (const prop of ntaData.properties['AFMELDINFO']) {
            if (!vm.propertyIsHidden(prop.Id, 'AFMELDINFO')) {
                vm.validate(prop.Id, vm.mainInfo, 'AFMELDINFO');
            }
        }
    };

    function representativityChanged(useRepresentativity = usingRepresentativity()) {
        if (isUtiliteit || (typeGebouw === "TGEB_APPGEB" && calcUnit === "RZUNIT_GEB" ) ) return; // Bij U-bouw ondersteunen we geen representativiteit, en ook niet als een gebouw alleen op gebouwniveau berekend wordt

        for (const unit of vm.units) {
            const afmeldlocaties = changeAfmeldlocaties(unit, useRepresentativity);

            if (useRepresentativity) {
                // Zorgen dat precies één locatie representatief is
                const representatieveLocatie = afmeldlocaties.find(afmloc => afmloc.PropertyDatas['AFMLOC_REPRESENTATIEF'].Value === 'true')
                    || afmeldlocaties[0];
                for (const afmeldlocatie of afmeldlocaties) {
                    ntaEntityData.saveprop(afmeldlocatie.PropertyDatas['AFMLOC_REPRESENTATIEF'], (afmeldlocatie === representatieveLocatie).toString());
                }
            }
        }

        //vm.units bijwerken met nieuwe situatie
        buildEntities();
    }

    function changeAfmeldlocaties(unit, onder1AfmeldObject) {
        const afmeldobjecten = ntaEntityData.getChildren(unit, 'AFMELDOBJECT');
        const propdataCount = unit.PropertyDatas['UNIT_AANTA'];
        const count = propdataCount.Relevant && parseInt(propdataCount.Value);

        if (onder1AfmeldObject) {
            // * Alle afmeldobject binnen unit op niet relevant zetten, behalve de eerste
            // * Zorg dat alle afmeldlocaties binnen de unit een relatie krijgen met het eerste afmeldobject

            const relevantAfmeldobject = afmeldobjecten[0];
            ntaEntityData.setEntityRelevancy(relevantAfmeldobject, true);

            // Hou een lijst bij van alle afmeldlocaties van deze unit (deze moeten allemaal onder relevantAfmeldobject komen, en straks gesorteerd in de juiste volgorde)
            const afmeldlocaties = ntaEntityData.getChildren(relevantAfmeldobject, 'AFMELDLOCATIE');

            const irrelevanteAOs = afmeldobjecten.filter(ao => ao !== relevantAfmeldobject);
            ntaEntityData.setEntityRelevancy(irrelevanteAOs, false);

            for (const afmeldobject of irrelevanteAOs) {
                for (const afmeldlocatie of ntaEntityData.getChildren(afmeldobject, 'AFMELDLOCATIE')) {
                    //bestaande relatie verwijderen
                    const relation = ntaEntityData.getRelation(afmeldobject, afmeldlocatie)
                    if (relation) {
                        ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                    }
                    //Nieuwe aanmaken met eerste afmeldobject
                    ntaEntityData.createRelation(relevantAfmeldobject.EntityDataId, afmeldlocatie.EntityDataId, true, true);
                    // bijhouden in onze eigen lijst
                    afmeldlocaties.push(afmeldlocatie);
                }
            }

            if (count && !usingNietZelfstandigeVBOs()) {
                // Verwijder extra afmeldlocaties indien aanwezig
                const extraAfmeldlocaties = afmeldlocaties.splice(count);
                for (const afmeldlocatie of extraAfmeldlocaties) {
                    ntaEntityData.delete(afmeldlocatie.EntityDataId);
                }

                // Voeg eventuele afmeldlocaties die we tekort komen
                const parentRels = [
                    { "OnCopy": 1, "OnDelete": 1, "Parent": relevantAfmeldobject.EntityDataId, "ParentEntityId": relevantAfmeldobject.EntityId },
                ];
                const propValues = [
                    { PropertyId: 'AFMLOC_OPNAMEDATUM', Value: afmeldlocaties[0] && afmeldlocaties[0].PropertyDatas['AFMLOC_OPNAMEDATUM'].Value || '' },
                    { PropertyId: 'AFMLOC_REPRESENTATIEF', Value: 'false' },
                ];
                while (afmeldlocaties.length < count) {
                    const newId = ntaEntityData.create(unit.BuildingId, 'AFMELDLOCATIE', -1, parentRels, [], propValues);
                    afmeldlocaties.push(ntaEntityData.get(newId));
                }
            }

            ntaEntityData.setEntityRelevancy(afmeldlocaties, true);

            // Zorgen dat ze allemaal een unieke Order hebben, zodat ze later evt. weer correct teruggezet kunnen worden
            ntaEntityData.SaveReOrder(afmeldlocaties);

            return afmeldlocaties;

        } else {
            // * Zorg dat de afmeldlocaties binnen de unit een 1 op 1 relatie hebben met de afmeldobjecten
            // * Alle afmeldobjecten binnen unit op relevant zetten
            const afmeldlocaties = afmeldobjecten.flatMap(ao => ntaEntityData.getChildren(ao, 'AFMELDLOCATIE'));

            if (count) {
                const entitiesToDelete = afmeldobjecten.splice(count)
                    .concat(afmeldlocaties.splice(count));
                for (const entdata of entitiesToDelete) {
                    ntaEntityData.delete(entdata.EntityDataId);
                }
            }

            let maxCount = afmeldlocaties.length;
            if (count > maxCount) {
                maxCount = count;
            }

            for (let i = 0; i < maxCount; i++) {
                let afmeldobject = afmeldobjecten[i];
                let afmeldlocatie = afmeldlocaties[i];

                if (!afmeldobject) {
                    // Als er te weinig afmeldobjecten waren, deze aanmaken
                    const parentRels = [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId },
                    ];
                    const template = afmeldobjecten[0];
                    const propValues = [
                        { PropertyId: 'AFMOBJ_ACTIE', Value: template && template.PropertyDatas['AFMOBJ_ACTIE'].Value || 'AFM_ACTIE_NIEUW' },
                        { PropertyId: 'AFMOBJ_STATUS', Value: template && template.PropertyDatas['AFMOBJ_STATUS'].Value || '0' },
                    ];
                    const newId = ntaEntityData.create(unit.BuildingId, 'AFMELDOBJECT', -1, parentRels, [], propValues);
                    afmeldobject = ntaEntityData.get(newId);
                }

                if (afmeldlocatie) {
                    // Eventuele relaties met verkeerd(e) afmeldobjecten verwijderen
                    const oldRelations = ntaEntityData.getParentRelations(afmeldlocatie, 'AFMELDOBJECT')
                        .filter(rel => !afmeldobject || rel.Parent !== afmeldobject.EntityDataId);
                    oldRelations.forEach(rel => ntaEntityData.deleteRelation(rel.EntityRelationDataId));
                } else {
                    // Als er te weinig afmeldlocaties waren, deze ook aanmaken. De relatie met het afmeldobject wordt straks gelegd.
                    const template = afmeldlocaties[0];
                    const propValues = [
                        { PropertyId: 'AFMLOC_OPNAMEDATUM', Value: template && template.PropertyDatas['AFMLOC_OPNAMEDATUM'].Value || '' },
                        { PropertyId: 'AFMLOC_REPRESENTATIEF', Value: 'false' },
                    ];
                    const newId = ntaEntityData.create(unit.BuildingId, 'AFMELDLOCATIE', -1, [], [], propValues);
                    afmeldlocatie = ntaEntityData.get(newId);
                }

                // Als de gewenste relatie nog niet bestaat, dan deze aanmaken
                const relation = ntaEntityData.getRelation(afmeldobject, afmeldlocatie);
                if (!relation) {
                    ntaEntityData.createRelation(afmeldobject.EntityDataId, afmeldlocatie.EntityDataId, true, true);
                }

                // En het afmeldobject (weer) relevant maken
                ntaEntityData.setEntityRelevancy(afmeldobject, true);

            }
            return [];
        }
    }

    function validateEntityData(entdata, objectType = null) {
        let entIsValid = true;
        if (Array.isArray(entdata)) {
            for (const ed of entdata) {
                entIsValid = validateEntityData(ed, objectType) && entIsValid;
            }
        } else {
            for (const propdata of entdata.PropertyDatas) {
                try {
                    entIsValid = vm.validate(propdata.PropertyId, entdata, objectType) && entIsValid;
                } catch (err) {
                    $log.error(err);
                }
            }
        }
        return entIsValid;
    }

    function validateAll(setTouched = false) {
        let allIsValid = true;

        //validate infoDatas
        allIsValid = validateEntityData(vm.infoDatas) && allIsValid;

        //validate mainAfmeldObject
        allIsValid = validateEntityData(vm.mainAfmeldObject, "AFMELDINFO") && allIsValid;

        //validate mainAfmeldLocatie
        allIsValid = validateEntityData(vm.mainAfmeldLocatie, "AFMELDINFO") && allIsValid;

        //validate vm.gebouwAfmeldLocatie
        allIsValid = validateEntityData(vm.gebouwAfmeldLocatie) && allIsValid;

        //validate pages en units en afmeldobjecten en afmeldlocaties
        const currentPageId = vm.currentPage;
        for (const page of _pages) {
            renderPage(page);
            validatePage(page, setTouched);
            allIsValid = page.Valid && allIsValid;
        }
        renderPage(_pages[currentPageId]);

        return allIsValid;

        function renderPage(page) {
            vm.currentPage = page.Id;
            if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
                $scope.$apply();
            }
        }
    }

    vm.propertyIsHidden = function (prop, objectType) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];

        let showit = true;
        if (objectType === 'AFMELDINFO') {
            switch (prop.Id) {
                case 'AFM_AANLEIDING': {
                    showit = !vm.isMaatwerkadvies; //[CE]
                    break;
                }
                case 'AFM_STATUS':
                case 'AFM_NAAM_ORIGINEEL': {
                    showit = false;
                    break;
                }
                case 'AFMLOC_OPNAMEDATUM': {
                    showit = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_AANVR' //[BK]
                        || vm.hideTable() || vm.isMaatwerkadvies;
                    break;
                }
                case 'AFM_AANVRAAG_VERGUNNING': { //per versie 59/115/207 bestaat deze property niet meer. Voor eerdere versies code laten staan.
                    showit = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_OPLVNB'; //[AA];
                    break;
                }
                case 'AFM_REGISTRATIE_ENERL': {
                    //[BN] & [CE];
                    showit = !vm.isMaatwerkadvies && (vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_AANVR' || vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_OPLVNB') && isUtiliteit;
                    break;
                }
                case 'AFM_ADV_NAAM':            // A06
                case 'AFM_ADV_VOORL':           // A06
                case 'AFM_ADV_TUSSENV':         // A06
                case 'AFM_ADV_ACHTERN':         // A06
                case 'AFM_ADV_EXAMENNUMMER': {  // A07
                    showit = conditieAB() // [AB]
                        && vm.hideTable(); // [BY]
                    break;
                }
                case 'AFM_IDENT_METH_EERDER': {
                    showit = conditieBQ(); //[BQ];
                    break;
                }
                case 'AFM_PROJECTNAAM': {
                    showit = isNieuwbouw && vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_ZONDER_BAG'; //[BM];

                    if (!showit) { //default de projectnaam
                        ntaEntityData.saveprop(prop.getData(vm.mainInfo), ntaData.projectdata.Name)
                    }
                    break;
                }
                case 'AFM_REPRESENTATIVITEIT': {
                    showit = conditieAE() && !vm.isMaatwerkadvies; // [AE] & [CE]
                    break;
                }
                case 'AFM_NZELFST_WOONEENH_INDIV_VBO': {
                    showit = !isUtiliteit && !conditieCB() && !vm.isMaatwerkadvies; // [CB] [CE]
                    break;
                }
            }
        } else if (objectType === 'AFMELDGEBOUW') {
            switch (prop.Id) {
                case 'AFMOBJ_STATUS':
                case 'AFMOBJ_REG_DATUM':
                case 'AFMOBJ_CREDITS':
                case 'AFMOBJ_ACTIE':
                case 'AFMOBJ_REG_NUMMER':
                case 'AFMOBJ_LABEL_RES_ID':
                case 'AFMOBJ_ERRORS':
                case 'AFMLOC_OPNAMEDATUM':
                case 'AFMLOC_POSTCODE':
                case 'AFMLOC_STRAAT':
                case 'AFMLOC_HUISNR':
                case 'AFMLOC_HUISLETTER':
                case 'AFMLOC_HUISNRTOEV':
                case 'AFMLOC_DETAILAANDUIDING':
                case 'AFMLOC_PLAATS':
                case 'AFMLOC_LABEL_RES_ID':
                case 'AFMLOC_REPRESENTATIEF': {
                    showit = false;
                    break;
                }
                case 'AFM_ADV_NAAM':            // A06
                case 'AFM_ADV_VOORL':           // A06
                case 'AFM_ADV_TUSSENV':         // A06
                case 'AFM_ADV_ACHTERN':         // A06
                case 'AFM_ADV_EXAMENNUMMER': {  // A07
                    showit = conditieAB() // [AB]
                        && vm.hideTable(); // [BY]
                    break;
                }
                case 'AFMOBJ_ADV_NAAM':
                case 'AFMOBJ_ADV_VOORL':
                case 'AFMOBJ_ADV_TUSSENV':
                case 'AFMOBJ_ADV_ACHTERN':
                case 'AFMOBJ_ADV_EXAMENNUMMER': {
                    showit = conditieAB(); //[AB];
                    break;
                }
                case 'AFMLOC_OMSCHR': {
                    //[AU]
                    showit = typeGebouw === 'TGEB_APPGEB'
                        && (vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value === 'AFM_ACTIE_NIEUW' || vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value === 'AFM_ACTIE_VERVANGEN')
                        && vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_AANVR'
                        && vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_ZONDER_BAG';
                    break;
                }
                case 'AFMLOC_PROVISIONAL_ID': {
                    //[AV]
                    const actie = vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'];
                    const aanleiding = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'];
                    const meth_eerder = vm.mainInfo.PropertyDatas['AFM_IDENT_METH_EERDER'];
                    showit = typeGebouw === 'TGEB_APPGEB'
                        && actie.Value === 'AFM_ACTIE_VERVANGEN'
                        && aanleiding.Value === 'AFM_AANL_AANVR'
                        && meth_eerder.Relevant && meth_eerder.Value === 'IDENTM_EERDER_ZONDER_BAG_REG_OORSPR';
                    break;
                }
                case 'AFMLOC_BAG_ID': { // A45
                    // [BR]
                    const identificatie = vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value;
                    const aanleiding = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'];
                    showit = vm.hideTable()
                        && (!aanleiding.Relevant || aanleiding.Value === 'AFM_AANL_AANVR' || aanleiding.Value === 'AFM_AANL_OPLVNB')
                        && (identificatie === 'IDENTM_BAG' || identificatie === 'IDENTM_LIGPLAATS' || identificatie === 'IDENTM_STANDPLAATS');
                    break;
                }
            }
        } else if (objectType === 'UNIT') {
            switch (prop.Id) {
                case 'AFMLOC_OPNAMEDATUM': {
                    showit = isUtiliteit && !!vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value && vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value !== 'AFM_AANL_AANVR'; //[BL]
                    break;
                }
            }
        } else if (objectType === 'AFMELDOBJECT') {
            switch (prop.Id) {
                case 'UNIT_POS': {
                    showit = (typeGebouw === 'TGEB_APP' || typeGebouw === 'TGEB_UTILUNIT' || (typeGebouw === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEBAPP') || (typeGebouw === 'TGEB_UTILIT' && calcUnit === 'RZUNIT_GEBUNIT')) && !vm.isMaatwerkadvies; //[AL] & [CE]
                    break;
                }
                case 'UNIT_TYPEWON': {
                    showit = typeGebouw === 'TGEB_GRWON'; //[AL]
                    break;
                }
                case 'AFMOBJ_STATUS':
                case 'AFMOBJ_REG_DATUM':
                case 'AFMOBJ_CREDITS':
                case 'AFMOBJ_ACTIE':
                case 'AFMOBJ_REG_NUMMER':
                case 'AFMOBJ_ERRORS': {
                    showit = false;
                    break;
                }
                case 'AFMOBJ_ADV_NAAM':             // A49
                case 'AFMOBJ_ADV_VOORL':            // A49
                case 'AFMOBJ_ADV_TUSSENV':          // A49
                case 'AFMOBJ_ADV_ACHTERN':          // A49
                case 'AFMOBJ_ADV_EXAMENNUMMER': {   // A50
                    showit = conditieAB(); // [AB]
                    break;
                }
            }
        } else if (objectType === 'AFMELDLOCATIE') {
            switch (prop.Id) {
                case 'AFMLOC_OPNAMEDATUM': {
                    showit = !isUtiliteit && !!vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value && vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value !== 'AFM_AANL_AANVR'; //[BL]
                    break;
                }
                case 'AFMLOC_OMSCHR': {
                    //[AH]
                    showit = (vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value === 'AFM_ACTIE_NIEUW' || vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value === 'AFM_ACTIE_VERVANGEN') &&
                        vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value === 'AFM_AANL_AANVR' &&
                        vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_ZONDER_BAG';
                    break;
                }
                case 'AFMLOC_PROVISIONAL_ID': {
                    showit = conditieAI(); //[AI]
                    break;
                }
                case 'AFMLOC_BAG_ID': {
                    showit = vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value !== 'IDENTM_ZONDER_BAG'; //[AG]
                    break;
                }
                case 'AFMLOC_POSTCODE':
                case 'AFMLOC_HUISNR':
                case 'AFMLOC_HUISLETTER':
                case 'AFMLOC_HUISNRTOEV':
                case 'AFMLOC_DETAILAANDUIDING': {
                    showit = vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_MEER_BAG'; //[BI]
                    break;
                }
                case 'AFMLOC_REPRESENTATIEF': {
                    showit = usingRepresentativity(); // [AJ]
                    break;
                }
                case 'AFMLOC_STRAAT':
                case 'AFMLOC_PLAATS':
                case 'AFMLOC_LABEL_RES_ID': {
                    showit = false;
                    break;
                }
                case 'AFMOBJ_ADV_NAAM':             // A49
                case 'AFMOBJ_ADV_VOORL':            // A49
                case 'AFMOBJ_ADV_TUSSENV':          // A49
                case 'AFMOBJ_ADV_ACHTERN':          // A49
                case 'AFMOBJ_ADV_EXAMENNUMMER': {   // A50
                    showit = conditieAB(); //[AB];
                    break;
                }
            }
        }

        return !showit;
    };

    vm.isHidden = function (prop, entdata, objectType) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];

        if (objectType === "AFMELDINFO") {
            if (prop.Id === "AFMOBJ_ACTIE") {
                entdata = vm.mainAfmeldObject;
            }
            if (prop.Id === "AFMLOC_OPNAMEDATUM") {
                entdata = vm.mainAfmeldLocatie;
            }
        }

        let propdata = null;
        if (entdata) {
            propdata = prop.getData(entdata);
        }


        let showit = true;
        let relevant = null;

        showit = !vm.propertyIsHidden(prop, objectType);

        // Touched op true zetten als het gaat om visible props die niet required zijn
        if (showit && !prop.Required) {
            const contrl = vm.form_afmelden && vm.form_afmelden['ntainput' + propdata.PropertyDataId];
            if (contrl) {
                contrl.$setTouched()
            }
        }

        //Relevant zetten van Gebouw AfmeldObject en AfmeldLocatie igv conditie [AU] -> appartementgebouw
        if (prop.Id === 'AFMLOC_OMSCHR' && objectType === 'AFMELDGEBOUW') {
            const afmObject = ntaEntityData.getFirstParent(entdata, 'AFMELDOBJECT');
            ntaEntityData.setEntityVisibility([entdata, afmObject], showit);
        }

        //als de propdata van objectType AFMELDLOCATIE of UNIT komt en deze is hidden, maar niet hidden op het AFMELDINFO niveau, moet de relevancy niet op false worden gezet.
        //als de propdata van objectType AFMELDINFO of UNIT komt en deze is hidden, maar niet hidden op het AFMELDLOCATIE niveau, moet de relevancy niet op false worden gezet.
        //als de propdata van objectType AFMELDINFO of AFMELDLOCATIE komt en deze is hidden, maar niet hidden op het UNIT niveau, moet de relevancy niet op false worden gezet.
        //Anders wordt de relevancy steeds op false / true / false / enz.gezet.
        if (prop.Id === 'AFMLOC_OPNAMEDATUM' && !showit) {
            if (((objectType === "AFMELDLOCATIE" || objectType === "UNIT") && !vm.propertyIsHidden(prop, "AFMELDINFO")) ||
                ((objectType === "AFMELDINFO" || objectType === "UNIT") && !vm.propertyIsHidden(prop, "AFMELDLOCATIE")) ||
                ((objectType === "AFMELDINFO" || objectType === "AFMELDLOCATIE") && !vm.propertyIsHidden(prop, "UNIT")))
            {
                propdata = null;
            }
        }

        if (propdata) {
            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;
            ntaEntityData.setPropdataStatus(propdata, relevant, showit);
        }

        return !showit;
    };

    vm.isReadOnly = function (prop, entdata) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];

        if (prop.Id === 'UNIT_OMSCHR' || prop.Id === 'UNIT_POS' || prop.Id === 'UNIT_TYPEWON') {
            return true;
        }
        if (entdata && entdata.EntityId === 'AFMELDOBJECT' && !['AFM_ACTIE_VERVANGEN', 'AFM_ACTIE_HERLABELEN'].includes(entdata.PropertyDatas['AFMOBJ_ACTIE'].Value)) {
            return entdata.PropertyDatas['AFMOBJ_STATUS'].Value === "4"; //succesvol
        }

        return false;
    };

    vm.isEerderMislukt = isEerderMislukt;
    function isEerderMislukt() {
        const propStatus = vm.propertiesOBJ['AFMOBJ_STATUS'];
        return !['AFM_ACTIE_VERVANGEN', 'AFM_ACTIE_HERLABELEN'].includes(vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value)
            && vm.afmeldObjecten.some(afmeldObject => propStatus.getValue(afmeldObject) === "8") //mislukt
            && vm.afmeldObjecten.some(afmeldObject => propStatus.getValue(afmeldObject) === "4"); //geslaagd
    }

    //vm.getPlaceholder = function(prop, entdata) {
    //    let placeholderStr = "";
    //    const bReadOnly = vm.isReadOnly(prop, entdata);
    //    switch (prop.PropertyType) {
    //        case 1:
    //            {
    //                placeholderStr = bReadOnly ? "" : "- vul in -";
    //                break;
    //            }
    //        case 2:
    //            {
    //                if (prop.Domain && prop.Domain.DomainType > 1) {
    //                    placeholderStr = bReadOnly ? "n.v.t." : "- maak uw keuze -";
    //                } else {
    //                    if (prop.Id.includes("OMSCH") && prop.Name !== 'omschrijving') {
    //                        placeholderStr = bReadOnly ? "n.v.t." : "- geef omschrijving -";
    //                    }
    //                }
    //                break;
    //            }
    //        default:
    //    }
    //    return placeholderStr;
    //} //-- end: getPlaceholder ------------------------------------------------------------------------//

    vm.enableTabConfirm = function() {
        angular.element(document).ready(function () {
            $('md-option').on('keydown', function (e) {
                if (e.which === 9) {
                    $(this).click();
                }
            });
        });
    }

    vm.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    };

    function usingRepresentativity() { // conditie [AJ]
        return conditieAE() && vm.mainInfo.PropertyDatas['AFM_REPRESENTATIVITEIT'].Value === 'AFM_REPRES_WEL' ;
    }

    function usingNietZelfstandigeVBOs() {
        return vm.mainInfo.PropertyDatas['AFM_NZELFST_WOONEENH_INDIV_VBO'].Relevant && vm.mainInfo.PropertyDatas['AFM_NZELFST_WOONEENH_INDIV_VBO'].Value === 'AFM_NZELFST_WOONEENH_INDIV_VBO_AANW';
    }

    function conditieAE() {
        return !isUtiliteit && vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value !== 'AFM_AANL_AANVR' && !usingNietZelfstandigeVBOs(); // [AE]
    }

    function conditieCB() {
        //[CB] verberg als 1 of meer van de volgende situaties zich voordoet:
        //- A08 getoond EN A08 = geen BAG identificatie beschikbaar / meerdere energielabels op één BAG identificatie
        //- G04 = appartementengebouw en Z23 = per gebouw
        const identificatieMethode = vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'];
        return (identificatieMethode.Relevant && identificatieMethode.Value !== 'IDENTM_BAG') || (typeGebouw === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEB');
    }

    function conditieBD () { //[BD]
        const actie = vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value;
        const aanleiding = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value;

        if (actie === 'AFM_ACTIE_VERVANGEN' && aanleiding === 'AFM_AANL_AANVR') {
            return 'oorspronkelijke registratie';

        } else if (actie === 'AFM_ACTIE_NIEUW' && aanleiding === 'AFM_AANL_OPLVNB') {
            return 'registratie omgevingsvergunning';

        } else {
            return '';
        }
    }

    //* A33 (AFMOBJ_ACTIE) = registratie vervangen (AFM_ACTIE_VERVANGEN) EN A24 (AFM_AANLEIDING) = aanvraag omgevingsvergunning (AFM_AANL_AANVR) EN A09 (AFM_IDENT_METH_EERDER) =oorspronkelijke registratie zonder BAG identificatie (IDENTM_ZONDER_BAG), OF
    //* A33 ((AFMOBJ_ACTIE)) = nieuwe registratie ((AFM_ACTIE_NIEUW)) EN A24 (AFM_AANLEIDING) = oplevering nieuw gebouw (AFM_AANL_OPLVNB) EN A09 (AFM_IDENT_METH_EERDER) = registratie omgevingsvergunning zonder BAG identificatie (IDENTM_BAG)
    function conditieAI() { //[AI]
        const actie = vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'];
        const aanleiding = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'];
        const meth_eerder = vm.mainInfo.PropertyDatas['AFM_IDENT_METH_EERDER'];
        return actie.Value === 'AFM_ACTIE_VERVANGEN'
            && aanleiding.Value === 'AFM_AANL_AANVR'
            && meth_eerder.Relevant && meth_eerder.Value === "IDENTM_EERDER_ZONDER_BAG_REG_OORSPR";
    }

    function conditieBQ() { //[BQ]
        const actie = vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value;
        const aanleiding = vm.mainInfo.PropertyDatas['AFM_AANLEIDING'].Value;
        const aanvraag = vm.mainInfo.PropertyDatas['AFM_AANVRAAG_VERGUNNING'] && vm.mainInfo.PropertyDatas['AFM_AANVRAAG_VERGUNNING'].Value;
        return (actie === 'AFM_ACTIE_VERVANGEN' && aanleiding === 'AFM_AANL_AANVR')
            || (actie === 'AFM_ACTIE_NIEUW' && aanleiding === 'AFM_AANL_OPLVNB' && aanvraag === 'AFM_VERGAANV_VANAF_2021');
    }

    function conditieAN_BA() { //[AN][BA]
        if (typeGebouw === 'TGEB_GRWON' || typeGebouw === 'TGEB_WOONBN' || typeGebouw === 'WOONBB' || typeGebouw === 'TGEB_WOONW' || typeGebouw === 'TGEB_VAKWON') {
            return 'woning';
        }
        if ((typeGebouw === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEBAPP') || typeGebouw === 'TGEB_APP') {
            return 'appartement';
        }
        if (typeGebouw === 'TGEB_UTILIT' && calcUnit === 'RZUNIT_GEB') {
            return 'gebouw';
        }
        if ((typeGebouw === 'TGEB_UTILIT' && calcUnit === 'RZUNIT_GEBUNIT') || typeGebouw === 'TGEB_UTILUNIT') {
            return 'unit';
        }
    }

    function conditieBO(propdataOmschr) { // [BO]
        return checkPropIsUniqueSoFar(propdataOmschr, vm.units.flatMap(x => x.afmeldObjecten).flatMap(x => x.afmeldLocaties), '[E075]');
    }

    function conditieBagUnique(propdataBagId) { // [BT]
        //Bij detailaanduiding niet controleren op het uniek zijn van VBO-Id #3001
        const noDuplicates = vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_MEER_BAG';
        return checkPropIsUniqueSoFar(propdataBagId, vm.units.flatMap(x => x.afmeldObjecten).flatMap(x => x.afmeldLocaties), '[E076]', noDuplicates);
    }

    function checkPropIsUniqueSoFar(propdataToCheck, entdatas, errorCode, noDuplicates = false) {
        const propdatas = entdatas.map(entdata => entdata.PropertyDatas[propdataToCheck.PropertyId]);
        const values = new Set(), duplicates = new Set();
        for (const propdata of propdatas.filter(pd => pd.Value)) {
            if (values.has(propdata.Value) && !noDuplicates) {
                duplicates.add(propdata);
            } else {
                values.add(propdata.Value);
            }
        }
        for (const propdata of propdatas.filter(pd => !duplicates.has(pd))) {
            vm.setMelding(errorCode, propdata, true);
        }
        for (const duplicate of duplicates) {
            vm.setMelding(errorCode, duplicate, false);
        }
        return !duplicates.has(propdataToCheck);
    }

    vm.getAddName = function () {
        //[BG] toon 'unieke omschrijving'  indien A08='geen BAG identificatie beschikbaar' /
        //     toon 'BAG verblijfsobject ID' indien A08 = verblijfsobject id / standplaats id / ligplaats id
        return vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === "IDENTM_ZONDER_BAG" ? "unieke omschrijving" : "BAG verblijfsobject ID";
    };

    vm.hideTable = function () { // [AW]
        return typeGebouw === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEB';
    };

    function conditieAB() {
        // [AB]
        return vm.mainInfo.PropertyDatas['AFM_ADVISEUR'].Value === 'AFM_ADVISEUR_VERSCH';
    }

    vm.getPropertiesLOC = function () {
        const props = vm.propertiesLOC.slice();
        if (conditieAB()) { // [AB]
            if (ntaData.properties['AFMOBJ_ADV_ACHTERN']) {
                props.push(ntaData.properties['AFMOBJ_ADV_VOORL']);
                props.push(ntaData.properties['AFMOBJ_ADV_TUSSENV']);
                props.push(ntaData.properties['AFMOBJ_ADV_ACHTERN']);
            } else {
                props.push(ntaData.properties['AFMOBJ_ADV_NAAM']);
            }
            props.push(ntaData.properties['AFMOBJ_ADV_EXAMENNUMMER']);
        }
        return props;
    };

    vm.getLocFields = function (afmeldlocatie) {
        let fields = _locationFields.get(afmeldlocatie);
        if (!fields) {
            const afmeldobject = ntaEntityData.getFirstParent(afmeldlocatie, 'AFMELDOBJECT');
            const afmeldlocaties = ntaEntityData.getChildren(afmeldobject, 'AFMELDLOCATIE');
            const propdatas = afmeldlocatie.PropertyDatas.map(pd => [afmeldlocatie, pd, 1]);
            if (conditieAB() && afmeldlocatie === afmeldlocaties[0]) {
                const propIds = [];
                if (ntaData.properties['AFMOBJ_ADV_ACHTERN']) {
                    propIds.push('AFMOBJ_ADV_VOORL', 'AFMOBJ_ADV_TUSSENV', 'AFMOBJ_ADV_ACHTERN');
                } else {
                    propIds.push('AFMOBJ_ADV_NAAM');
                }
                propIds.push('AFMOBJ_ADV_EXAMENNUMMER');
                propdatas.push(...propIds.map(propId => [afmeldobject, afmeldobject.PropertyDatas[propId], afmeldlocaties.length]));
            }
            fields = propdatas.map(([entdata, propdata, rowspan]) => {
                const prop = ntaData.properties[propdata.PropertyId];
                return {
                    propdata,
                    entdata,
                    prop,
                    rowspan,
                    isReadOnly: () => vm.isReadOnly(prop, afmeldobject),
                    isHidden: () => vm.isHidden(prop, entdata, 'AFMELDLOCATIE'),
                    saveValue: () => vm.saveValue(prop, entdata, 'AFMELDLOCATIE'),
                    getStyle: () => vm.getStyle(prop),
                };
            });
            _locationFields.set(afmeldlocatie, fields);
        }
        return fields;
    };

    vm.hideAddUnit = function (isHeader) { //[AQ] en [BZ]
        // [AQ]
        let show = ntaEntityData.getListWithEntityId('GEB').length === 1 && (typeGebouw === 'TGEB_GRWON' || typeGebouw === 'TGEB_WOONBN' || typeGebouw === 'WOONBB' || typeGebouw === 'TGEB_WOONW' ||
            typeGebouw === 'TGEB_VAKWON' || typeGebouw === 'TGEB_APP' || typeGebouw === 'TGEB_UTILUNIT' || (typeGebouw === 'TGEB_UTILIT' && calcUnit === 'RZUNIT_GEB')) && calcUnit !== "RZUNIT_PROJECT";

        // [BZ]
        const propVBO = vm.propertiesINFO['AFM_NZELFST_WOONEENH_INDIV_VBO'];
        const valueVBO = propVBO.getValue(vm.mainInfo);
        const isSingleBuilding = ntaEntityData.getListWithEntityId('GEB').length === 1;
        const hasDependentUnits = valueVBO === 'AFM_NZELFST_WOONEENH_INDIV_VBO_AANW';

        if (hasDependentUnits
            && (!isSingleBuilding
                || calcUnit === "RZUNIT_PROJECT"
                || (typeGebouw === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEBAPP')
            )) {
            show = !isHeader;
        }

        // Indien A33 = 'registratie vervangen' / 'herlabelen' verberg de button 'unieke omschrijving' 'BAG verblijfsobject ID' EN verberg tevens de buttons 'drag', 'add' 'copy' and 'delete' in de rijen.
        if (['AFM_ACTIE_VERVANGEN', 'AFM_ACTIE_HERLABELEN'].includes(vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value)) {
            show = false;
        }

        return !show || vm.isEerderMislukt();
    };

    vm.hideAddUniekeOmschr = function () {
        // Indien A33 = 'registratie vervangen'  / 'herlabelen' verberg de button 'unieke omschrijving' 'BAG verblijfsobject ID' EN verberg tevens de buttons 'drag', 'add' 'copy' and 'delete' in de rijen.
        return ['AFM_ACTIE_VERVANGEN', 'AFM_ACTIE_HERLABELEN'].includes(vm.mainAfmeldObject.PropertyDatas['AFMOBJ_ACTIE'].Value)
            || vm.propertyIsHidden('AFMLOC_OMSCHR', 'AFMELDLOCATIE'); // [AH]
    };

    vm.addAfmeldLocatie = function (afmeldObject) {
        if (!afmeldObject) {
            afmeldObject = vm.units[0].afmeldObjecten[0];
        }

        const entityDatasToValidate = [];

        //bij isUtiliteit of representiviteit of aanwezig zijn van niet-zelfsandige VBO's alleen AFMLDLOCATIE aanmaken
        if (isUtiliteit || usingRepresentativity() || usingNietZelfstandigeVBOs()) {
            const afmeldLocatieId = ntaEntityData.create(afmeldObject.BuildingId, 'AFMELDLOCATIE', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": afmeldObject.EntityDataId, "ParentEntityId": afmeldObject.EntityId }]);
            buildEntities();
            checkOpnameDatum();
            entityDatasToValidate.push(ntaEntityData.get(afmeldLocatieId));
        } else {
            //bij geen representativiteit AFMELDLOCATIE en AFMELDOBJECT aanmaken
            const unit = ntaEntityData.getFirstParent(afmeldObject, 'UNIT');
            const afmeldObjectId = ntaEntityData.create(afmeldObject.BuildingId, 'AFMELDOBJECT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId }]);
            const afmeldLocatieId = ntaEntityData.create(afmeldObject.BuildingId, 'AFMELDLOCATIE', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": afmeldObjectId, "ParentEntityId": "AFMELDOBJECT" }]);
            buildEntities();
            checkOpnameDatum();
            entityDatasToValidate.push(ntaEntityData.get(afmeldObjectId));
            entityDatasToValidate.push(ntaEntityData.get(afmeldLocatieId));
        }

        time.delayActions(() => validateEntityData(entityDatasToValidate));
    };

    vm.copyAfmeldLocatie = function (afmeldLocatie) {
        let entityDatasToValidate = [];

        //bij utiliteit of representativiteit alleen AFMELDLOCATIE kopiëren
        if (isUtiliteit || usingRepresentativity() || usingNietZelfstandigeVBOs()) {
            entityDatasToValidate = ntaEntityData.copy(afmeldLocatie.EntityDataId);
        } else {
            //bij geen representativiteit AFMELDLOCATIE en AFMELDOBJECT kopiëren
            const afmeldObject = ntaEntityData.getFirstParent(afmeldLocatie, 'AFMELDOBJECT');
            entityDatasToValidate = ntaEntityData.copy(afmeldObject.EntityDataId);
        }
        // zorg dat de kopie nooit representatief is
        for (const afmeldlocatie of entityDatasToValidate.filter(ed => ed.EntityId === 'AFMELDLOCATIE')) {
            ntaEntityData.saveprop(afmeldlocatie.PropertyDatas['AFMLOC_REPRESENTATIEF'], 'false');
        }

        buildEntities();

        time.delayActions(() => validateEntityData(entityDatasToValidate));
    };

    vm.deleteAfmeldLocatie = function (afmeldLocatie, afmeldObject) {
        if (afmeldObject && afmeldObject.PropertyDatas['AFMOBJ_STATUS'].Value === "4") {
            return; //afmeldObject is al succesvol afgemeld -> kan je niet meer verwijderen.
        }

        //bij isUtiliteit of representiviteit niet verwijderen als er maar één child AFMLDLOCATIE van parent AFMELDOBJECT aanwezig is
        if (isUtiliteit || usingRepresentativity() || usingNietZelfstandigeVBOs()) {
            const afmeldObject = ntaEntityData.getFirstParent(afmeldLocatie, 'AFMELDOBJECT');
            if (ntaEntityData.getChildren(afmeldObject, 'AFMELDLOCATIE').length > 1) {
                ntaEntityData.delete(afmeldLocatie.EntityDataId);
            }
        } else {
            //bij geen representativiteit AFMELDLOCATIE niet verwijderen als er maar één child AFMLDOBJECT van parent UNIT aanwezig is
            const afmeldObject = ntaEntityData.getFirstParent(afmeldLocatie, 'AFMELDOBJECT');
            const unit = ntaEntityData.getFirstParent(afmeldObject, 'UNIT');
            if (ntaEntityData.getChildren(unit, 'AFMELDOBJECT').length > 1) {
                ntaEntityData.delete(afmeldLocatie.EntityDataId);
                ntaEntityData.delete(afmeldObject.EntityDataId);
            }
        }

        buildEntities();
    };

    vm.clickCheckBox = function (prop, afmeldLocatie) {
        prop.getData(afmeldLocatie).Value = "true";
        vm.saveValue(prop, afmeldLocatie);

        const afmLocaties = ntaEntityData.findEntities(afmeldLocatie, '^AFMELDOBJECT.AFMELDLOCATIE');
        for (const afmLocatie of afmLocaties) {
            if (afmLocatie !== afmeldLocatie) {
                const afmLocPropData = prop.getData(afmLocatie);
                if (afmLocPropData.Value !== 'false') {
                    afmLocPropData.Value = 'false';
                    vm.saveValue(prop, afmLocatie);
                }
            }
        }
    };

    vm.addUniekeOmschr = async function () {
        try {
            await confirmDialog('[W074]')
        } catch (err) {
            //-- gekozen voor {annuleren}
            return;
        }

        //-- bij {doorgaan} de omschrijving van de UNIT toevoegen aan de AFMELDLOCATIE.
        const prop = ntaData.properties['AFMLOC_OMSCHR'];
        for (const unit of vm.units) {
            const unitOmschr = (vm.getPropData('UNIT_OMSCHR', unit).Value || '')
                .split('').map(c => c.normalize('NFKD')[0]).join('') // verander ‘Fryslân’ in ‘Fryslan’
                .replace(/[^\w '`,-]+/g, ' ')
                .trim();
            for (const afmeldobject of unit.afmeldObjecten) {
                for (const afmeldlocatie of afmeldobject.afmeldLocaties) {
                    if (!vm.isReadOnly(prop, afmeldlocatie)) {
                        const propdata = prop.getData(afmeldlocatie);
                        if (propdata.Value !== unitOmschr) {
                            propdata.Value = unitOmschr;
                            vm.saveValue(prop, afmeldlocatie);
                        }
                    }
                }
            }
        }
        validateAll();
    }

    vm.afmeldenStarted = false;

    vm.rowsPerPage = 25;
    vm.currentPage = 0;

    const _pages = Array.from({ length: Math.ceil(vm.units.length / vm.rowsPerPage) })
        .map((_, index) => ({
            Id: index,
            Valid: false,
            Touched: isPageTouched(index),
        }));

    function isPageTouched(id) {
        let isTouched = true;
        for (const unit of getPageUnits(id)) {
            for (const afmobj of unit.afmeldObjecten) {
                isTouched &&= afmobj.PropertyDatas.every(pd => pd.Touched);
                for (const afmloc of afmobj.afmeldLocaties) {
                    isTouched &&= afmloc.PropertyDatas.every(pd => pd.Touched);
                }
            }
        }
        return isTouched;
    }

    vm.getPageUnits = getPageUnits;

    function getPageUnits(pageId = vm.currentPage) {
        const start = pageId * vm.rowsPerPage;
        const end = start + vm.rowsPerPage;
        return vm.units.slice(start, end);
    }

    function getUnitPage(unit) {
        const index = vm.units.indexOf(unit);
        return _pages[Math.floor(index / vm.rowsPerPage)];
    }

    vm.setPage = function (page) {
        validatePage(_pages[vm.currentPage], true);

        vm.currentPage = page.Id;

        time.delayActions(() => {
            if (page.Touched) {
                for (const control of vm.form_afmelden.$$controls) {
                    control.$setTouched();
                }
            }
            validatePage(_pages[vm.currentPage], true);
        }, 100);
    };

    vm.getPages = () => _pages;

    function validatePage(page, setTouched = false) {
        page.Valid = true;
        page.Touched ||= setTouched;
        //alle afmeldobjecten en afmeldlocaties van deze pagina op touched zetten.
        for (const unit of getPageUnits(page.Id)) {
            for (const afmobj of unit.afmeldObjecten) {
                for (const propdata of afmobj.PropertyDatas) {
                    if (setTouched && !propdata.Touched) {
                        propdata.Touched = true;
                        ntaEntityData.saveprop(propdata);
                    }
                    if (propdata.Touched) {
                        const control = vm.form_afmelden && vm.form_afmelden['ntainput' + propdata.PropertyDataId];
                        control?.$setTouched();
                    }
                    page.Valid = vm.validate(propdata.PropertyId, afmobj) && page.Valid;
                }
                for (const afmloc of afmobj.afmeldLocaties) {
                    for (const propdata of afmloc.PropertyDatas) {
                        if (setTouched && !propdata.Touched) {
                            propdata.Touched = true;
                            ntaEntityData.saveprop(propdata);
                        }
                        if (propdata.Touched) {
                            const control = vm.form_afmelden && vm.form_afmelden['ntainput' + propdata.PropertyDataId];
                            control?.$setTouched();
                        }
                        let objectType;
                        if (propdata.PropertyId === 'AFMLOC_OPNAMEDATUM' && !vm.propertyIsHidden(propdata.PropertyId, 'UNIT')) {
                            objectType = 'UNIT';
                        }
                        page.Valid = vm.validate(propdata.PropertyId, afmloc, objectType) && page.Valid;
                    }
                }
            }
        }
        return page.Valid;
    }

    function areRepresentativesValid() {
        if (!usingRepresentativity()) return true;

        const afmeldobjecten = vm.units.flatMap(unit => unit.afmeldObjecten);
        for (const afmeldobject of afmeldobjecten) {
            const locations = ntaEntityData.getChildren(afmeldobject, 'AFMELDLOCATIE');
            let count = 0;
            for (const afmeldlocatie of locations) {
                if (afmeldlocatie.PropertyDatas['AFMLOC_REPRESENTATIEF'].Value === 'true') {
                    count++;
                }
            }
            if (count !== 1) {
                return false;
            }
        }
        return true;
    }

    function htmlEncode(text) {
        if (!text) return text;

        const span = document.createElement('span');
        span.textContent = text;
        return span.innerHTML;
    }

    async function warningDialog(warningCode, title = '', args = null) {
        // FilterValue1 = de titel
        // FilterValue2 = de tekst van de ‘OK’ button
        // FilterValue3 = de tekst van de ‘cancel’ button
        // (Als FilterValue3 niet opgegeven is, wordt `alert` gebruikt, anders `confirm`).
        const warning = ntaData.warnings.find(w => w.Id === warningCode) || { Value: warningCode };

        const alert = warning.FilterValue3 ? $mdDialog.confirm() : $mdDialog.alert();

        alert.title(title || warning.FilterValue1 || 'waarschuwing');

        let html = warning.Value;
        if (args && html) {
            html = html.replace(/{{\s*(.*?)\s*}}/g, (match, key) => htmlEncode(args[key]));
        }
        alert.htmlContent(html);

        alert.ok(warning.FilterValue2 || 'sluiten');

        if (warning.FilterValue3) {
            alert.cancel(warning.FilterValue3);
        }

        progressCircle.setShowProgressValue(false);
        try {
            return await $mdDialog.show(alert);
        } catch (err) {
            return false;
        }
    }

    function confirmDialog(warningCode, title = '', args = null) {
        const warning = ntaData.warnings.find(w => w.Id === warningCode) || { Value: warningCode };

        if (!title) title = warning.FilterValue1;

        let html = warning.Value;
        if (args && html) {
            html = html.replace(/{{\s*(.*?)\s*}}/g, (match, key) => htmlEncode(args[key]));
        }

        progressCircle.setShowProgressValue(false);

        const confirm = $mdDialog.confirm()
            .title(title || 'waarschuwing')
            .htmlContent(html)
            .ok('doorgaan')
            .cancel('annuleren');
        return $mdDialog.show(confirm);
    }


    vm.canStartAfmelden = function () {
        return !vm.form_afmelden.$invalid
            && !vm.afmeldenStarted
            && vm.form_afmelden.$$controls.every(control => control.$touched || control.$name === 'afmeldbutton')
            && _pages.filter(p => p.Id !== vm.currentPage).every(x => x.Touched && x.Valid)
            && areRepresentativesValid()
            && ntaData.projecttree.Gebouwberekeningen.every(b => ntaData.canSaveBuilding(b.GebouwId));
    };

    vm.startAfmelden = async function () {
        validateAll(true);
        if (!vm.canStartAfmelden()) return;

        vm.afmeldenStarted = true;
        try {
            // Als we gaan afmelden zonder BAG-IDs, dan alle BAG-IDs leeghalen.
            if (vm.mainInfo.PropertyDatas['AFM_IDENTIFICATIEMETHODE'].Value === 'IDENTM_ZONDER_BAG') {
                const bagIdPropdatas = ntaEntityData.getListWithEntityId('AFMELDLOCATIE')
                    .map(entdata => entdata.PropertyDatas['AFMLOC_BAG_ID']);
                for (const propdata of bagIdPropdatas) {
                    ntaEntityData.saveprop(propdata, '');
                }
            }

            await ntaStorage.whenDoneSaving({ waitAfterMS: 0, leaveProgress: true });

            const response = await $http.post("epc/PrepareRegistration", JSON.stringify({ AfmeldId: vm.afmeldId }));
            if (response.data.canContinue) {
                window.location.href = vm.isMaatwerkadvies
                    ? "epc/registermwa/" + encodeURIComponent(vm.afmeldId)
                    : "epc/register/" + encodeURIComponent(vm.afmeldId);
            } else { // geen afmeldtegoed of reservering niet goed gegaan -> melding
                const creditsShort = response.data.creditsShort;
                if (await warningDialog('[W053]', null, { creditsShort })) { // melding te weinig tegoed
                    window.location.href = "/Account/Licence"; // licentiebeheer
                } else {
                    vm.afmeldenStarted = false;
                }
            }
        } catch (err) {
            vm.afmeldenStarted = false;
            progressCircle.setShowProgressValue(false);
            $log.error(err);
            if (err && err.status === 403) {
                await ntaAlert.showLogin();
            } else if (err instanceof ntaStorage.NetworkError) {
                await ntaAlert.showNetworkError();
            } else {
                await ntaAlert.showError();
            }
        }
    };

    angular.element(async function () { // valideren na het laden van form

        // Verwerk eerst evt. openstaande client-wijzigingen
        const buildingIds = ntaData.projecttree.Gebouwberekeningen.map(b => b.GebouwId);
        const singleBuilding = buildingIds.length === 1;
        const warnings = [];
        await Promise.all(buildingIds.map(async buildingId => {
            if (await ntaLocal.hasUpdates(buildingId)) {
                await ntaEntityData.processLocalUpdates(buildingId);
                // Controleer of de berekening nog steeds afgemeld kan worden
                if (ntaEntityData.getCalculationNeeded(buildingId)) {
                    warnings.push(singleBuilding ? '[W042]' : '[W044]');
                }
                if (ntaEntityData.getFirstWithEntityId('MELDING', buildingId, true)) {
                    warnings.push(singleBuilding ? '[W043]' : '[W045]');
                }
            }
        }));
        if (warnings.length > 0) {
            await warningDialog(warnings.sort()[0]); // sorteren omdat [W042] en [W043] voorrang hebben boven [W044] en [W045]

            // voortgangsformulier weergeven, in indeterminate modus
            progressCircle.setShowProgressValue(true, 'projectenoverzicht laden', false);

            // en naar het projectenoverzicht gaan
            location.assign('/Projects');
            return;
        }

        //igvv meerdere AFMELDINFO -> bij verschil velden leegmaken
        let eerdereMethodeDiffers = false; // wanneer de eerdere methode invoer verschilt voor de InfoDatas, dan niet automatisch invullen.
        const infoPropdatasToCheck = vm.mainInfo.PropertyDatas
            .map(propdata => ({ propId: propdata.PropertyId, mainEntdata: vm.mainInfo, entdatas: vm.infoDatas, }))
            .concat({ propId: vm.propTypeAfmelding.Id, mainEntdata: vm.mainAfmeldObject, entdatas: vm.afmeldObjecten, });
        if (!vm.isHidden(vm.propOpnameDatum, vm.mainAfmeldLocatie, 'AFMELDINFO')) {
            infoPropdatasToCheck.push({ propId: vm.propOpnameDatum.Id, mainEntdata: vm.mainAfmeldLocatie, entdatas: _afmeldLocaties, });
        }
        for (const { propId, mainEntdata, entdatas } of infoPropdatasToCheck) {
            const values = new Set();
            const emptyPropdatas = [];
            for (const entdata of entdatas) {
                const propdata = entdata.PropertyDatas[propId];
                if (!propdata) {
                    $log.warn(`Building ${entdata.BuildingId}: ${entdata.EntityId} ${entdata.EntityDataId} heeft niet de verwachte property ‘${propId}’!`);
                } else if (!propdata.Value) {
                    emptyPropdatas.push(propdata);
                } else {
                    values.add(propdata.Value);
                }
            }
            if (values.size > 1) {
                const mainPropdata = mainEntdata.PropertyDatas[propId];
                mainPropdata.Value = '';
                vm.setMelding('[D024]', mainPropdata, false);
                vm.saveValue(propId, mainEntdata, 'AFMELDINFO'); // bij saveValue krijgen alle andere entdatas de waarde van mainPropdata
            } else if (values.size === 1 && emptyPropdatas.length > 0) {
                const [firstValue] = values;
                ntaEntityData.saveprops(emptyPropdatas, firstValue);
            }

            if (!eerdereMethodeDiffers && propId === 'AFM_IDENT_METH_EERDER') {
                eerdereMethodeDiffers = values.size > 1;
            }
        }

        // Controleer aantallen
        if (usingNietZelfstandigeVBOs()) {
            //-> niet-zelfstandige VBO's wel aanwezig
            for (const unit of vm.units) {
                changeAfmeldlocaties(unit, true);
            }
        } else {
            //-> niet-zelfstandige VBO's niet aanwezig
            representativityChanged();
        }

        // Eerdere identificatiemethode automatisch invullen
        const propdataIdentMethodeEerder = vm.mainInfo.PropertyDatas['AFM_IDENT_METH_EERDER'];
        if (!propdataIdentMethodeEerder.Value && !eerdereMethodeDiffers && conditieBQ()) {
            const eerdereMethodes = ntaValidation.codedValues(propdataIdentMethodeEerder.PropertyId, conditieBD());
            let eerdereMethode;
            if (_afmeldLocaties.some(afmloc => afmloc.PropertyDatas['AFMLOC_BAG_ID'].Value)) {
                eerdereMethode = eerdereMethodes.find(cv => cv.Id === 'IDENTM_EERDER_BAG_REG_OORSPR' || cv.Id === 'IDENTM_EERDER_BAG_REG_OMGVERG');
            } else if (_afmeldLocaties.some(afmloc => afmloc.PropertyDatas['AFMLOC_PROVISIONAL_ID'].Value)) {
                eerdereMethode = eerdereMethodes.find(cv => cv.Id === 'IDENTM_EERDER_ZONDER_BAG_REG_OORSPR' || cv.Id === 'IDENTM_EERDER_ZONDER_BAG_REG_OMGVERG');
            }
            if (eerdereMethode && eerdereMethode.Id !== propdataIdentMethodeEerder.Value) {
                propdataIdentMethodeEerder.Value = eerdereMethode.Id;
                vm.saveValue(propdataIdentMethodeEerder.PropertyId, vm.mainInfo); // bij saveValue krijgen alle andere infoDatas de waarde van vm.mainInfo
            }
        }

        vm.startFormValidation();
        checkData();
    });



    vm.startFormValidation = function () {
        validateAll();
        vm.setPage(_pages[vm.currentPage]);
    } //-- end: startFormValidation ------------------------------------------------------------------------//

    window.onbeforeunload = function (event) { //verlaten pagina
        vm.endFormValidation();
    };

    vm.endFormValidation = function () {
        for (const entdata of getAllEntDatas()) {
            for (const propdata of entdata.PropertyDatas) {
                if (!propdata.Touched) {
                    propdata.Touched = true;
                    ntaEntityData.saveprop(propdata);
                }
            }
        }
        //vm.startFormValidation();
    } //-- end: endFormValidation ------------------------------------------------------------------------//

    function getAllEntDatas() { // Geeft alle entdatas in één array terug
        return vm.infoDatas
            .concat(vm.afmeldObjecten)
            .concat(_afmeldLocaties)
            .concat(vm.gebouwAfmeldLocatie)
            .filter(entdata => entdata); // filter alle nulls en undefineds eruit
    }



}]);
